import React, {CSSProperties, FormEvent, useEffect, useRef, useState} from 'react';
import {Button, Modal, Row, Spinner} from 'react-bootstrap';
import {InputRow} from '../form-components/InputRow';
import {Form, Formik} from 'formik';
import {Form as BSForm} from 'react-bootstrap';
import Input from '../form-components/formik-inputs/Input/Input';
import styles from './ProfilePicture.module.scss';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../redux/util/http';
import StandardErrorMessage from '../StandardErrorMessage';
import {getBase64} from '../../../util';
import {noProfilePicture} from '../../../appTheme';
import {WebState} from '../../../redux/core/types/WebState';
import {userStore} from '../../../redux/web/entities/user';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';

type Props = {
  userId: string;
  style?: CSSProperties;
  editable?: boolean;
  onSubmit?: (request: UpdateProfilePictureRequest) => void;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface UpdateProfilePictureRequest {
  userId: string;
  imageBase64: string | null;
  fileName: string;
}

function ProfilePicture(props: Props) {
  const {userId, style, editable = true, onSubmit, getUserById, currentUser,
    actions: {updateCurrentUserProfilePictureAction, updateUsersProfilePictureAction}} = props;
  const getPfp = () => currentUser?.id === userId ?
    `${process.env.REACT_APP_STORAGE_URL}Storage/Public/ProfilePictures/${userId}/${currentUser?.profilePicturePath}` :
    getUserById(userId)?.profilePicturePath ?
    `${process.env.REACT_APP_STORAGE_URL}Storage/Public/ProfilePictures/${userId}/${getUserById(userId)?.profilePicturePath}`
      : noProfilePicture();

  const [showEditorModal, setShowEditorModal] = useState(false);
  const [renderedImage, setRenderedImage] = useState(getPfp());
  const [updatedImageData, setUpdatedImageData] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!updatedImageData)

      setRenderedImage(getPfp());
  }, [getPfp, updatedImageData]);


  async function encodeImage() {
    if(updatedImageData !== '') {
      return await getBase64(updatedImageData as any as File);
    } else {
      return null;
    }
  }

  const getImageFileName = () => {
    const refFile = fileInputRef.current?.files ? fileInputRef.current.files[0] : null;
    return refFile?.name ?? '';
  };

  const resetModal = () => {
    setShowEditorModal(false);
    setUpdatedImageData('');
  };

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: UpdateProfilePictureRequest) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    try {
      const request: UpdateProfilePictureRequest = {imageBase64: await encodeImage(), fileName: getImageFileName(), userId: userId};
      if(userId === currentUser?.id) {
        await updateCurrentUserProfilePictureAction(request);
      } else {
        await updateUsersProfilePictureAction(request);
      }
      setRenderedImage(request.imageBase64 ?? '');
      if (onSubmit) await onSubmit(request);
      resetModal();
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));
    }
    setIsSubmitting(false);
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button
            onClick={(event) => {
            event.preventDefault();
            resetModal();
          }}
            variant={'danger'}
            className={styles['close-button']}
          >
            Cancel
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const editorModal = () => (
      <Modal show={showEditorModal} centered={true} style={{zIndex: '1050'}}>
        <Modal.Body>
          <Modal.Title>Edit Profile Picture</Modal.Title>
            <Formik<UpdateProfilePictureRequest> initialValues={{imageBase64: updatedImageData, fileName: '', userId: userId}} onSubmit={() => undefined}>
              {({values}) => (
                <Form onSubmit={(e) => processSubmit(e, values)}>
                  <InputRow label={'Image File'} labelSize={2.5} columnSize={9} style={{paddingTop: '10px', paddingLeft: '15px'}}>
                    <Input name={'data'} forwardedRef={fileInputRef} type={'file'} setFormDataState={setUpdatedImageData}/>
                  </InputRow>
                  {updatedImageData ?
                    <Row style={{paddingTop: '10px', paddingBottom: '10px', paddingRight: '15px', justifyContent: 'center'}}>
                      <img
                        style={{height: '6rem', width: '6rem', borderRadius: '50%'}}
                        src={URL.createObjectURL(updatedImageData as any as File)}
                        alt={'Profile picture'}
                      />
                    </Row> : null
                  }
                  <StandardErrorMessage errorMessage={errorMessage}/>
                  <Row style={{paddingTop: '10px'}}>
                    <BSForm.Group className={styles['form-buttons']}>
                      {renderButtons()}
                    </BSForm.Group>
                  </Row>
                </Form>
              )}
            </Formik>
        </Modal.Body>
      </Modal>
  );

  if (editable) return (
    <>
      <div>
        <a
          onClick={(event) => {
            event.preventDefault();
            setShowEditorModal(true);
          }}
          className={styles['editable-profile-picture']}
        >
          <img
            style={{height: '6rem', width: '6rem', borderRadius: '50%', ...style}}
            src={renderedImage}
            alt={'Profile picture'}
          />
        </a>
      </div>
      {editorModal()}
    </>
  );
  return (
    <div>
      <img
        style={{height: '6rem', width: '6rem', borderRadius: '50%', ...style}}
        src={renderedImage}
        alt={'Profile picture'}
      />
    </div>
  );
}

const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    updateCurrentUserProfilePictureAction: userStore.actions.updateProfilePicture,
    updateUsersProfilePictureAction: userStore.actions.updateUsersProfilePicture
}, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);

