import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import {getExportData} from '../../../api/exportApi';
import {AxiosError} from 'axios';
import React, {useEffect, useState} from 'react';
import {useMount} from '../../../hooks/useMount';
import {handleAxiosError} from '../../../redux/util/http';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import { Pivot } from 'react-flexmonster';
import SaveExportModal from './PivotTable/SaveReportModal/SaveExportModal';
import {ReportConfiguration, reportConfigurationStore} from '../../../redux/web/entities/reportConfiguration';
import {Toolbar, Report} from 'flexmonster';
import OpenReportConfigurationModal from './PivotTable/OpenReportConfiguration/OpenReportConfigurationModal';
import {loadReportConfigurations} from '../../../redux/web/stateResponses/reportConfiguration';
import {ConfirmationDialog} from '../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {userStore} from '../../../redux/web/entities/user';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function ReportBuilder(props: Props) {
  const {reportConfigurations, getConfigById, currentUser, actions: {loadConfigurations, deleteConfig, copyConfig}} = props;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [showSaveExportDialog, setShowSaveExportDialog] = useState<boolean>(false);
  const [openSelectConfiguration, setOpenSelectConfiguration] = useState<boolean>(false);
  const [deletingConfig, setDeletingConfig] = useState<string>('');
  const [existingConfiguration, setExistingConfiguration] = useState<ReportConfiguration>();
  const [currentConfigData, setCurrentConfigData] = useState<Report>({});
  const [currentDataSet, setCurrentDataSet] = useState<string>('CaseFile');
  const [pivotRef] = useState<React.RefObject<Pivot>>(React.createRef<Pivot>());

  useMount(async () => {
    try {
      await loadConfigurations();
      await loadCaseFileReportData();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load Data'}));
    }
    setLoading(false);
  });

  const loadCaseFileReportData = () => {
    getExportData('CaseFile').then((result) => {
      const defaultReport = {dataSource: {data: result}, ...caseFileDefaultSlice()} as Report;
      pivotRef.current?.flexmonster.clear();
      pivotRef.current?.flexmonster.updateData({ type: 'json', data: result });
      pivotRef.current?.flexmonster.setReport(defaultReport);
      setExistingConfiguration(undefined);
      setCurrentConfigData({});
      setCurrentDataSet('CaseFile');
    });
  };

  const loadServicesProvidedReportData = () => {
    getExportData('ServicesProvided').then((result) => {
      const defaultReport = {dataSource: {data: result}, ...servicesProvidedDefaultSlice()} as Report;
      pivotRef.current?.flexmonster.clear();
      pivotRef.current?.flexmonster.setReport(defaultReport);
      pivotRef.current?.flexmonster.updateData({type: 'json', data: result });
      setExistingConfiguration(undefined);
      setCurrentConfigData({});
      setCurrentDataSet('ServicesProvided');
    });
  };


  const saveReport = async () => {
    const config: string | Report | undefined = pivotRef.current?.flexmonster.getReport() as any as Report;
    // @ts-ignore
    config.dataSource.data = [];
    await setCurrentConfigData(config);
    setShowSaveExportDialog(true);
  };

  const openReport = (config: ReportConfiguration) => {
    const report = JSON.parse(config.data) as Report;
    getExportData(config.subset).then((result => {
      report.dataSource = {data: result};
      pivotRef.current?.flexmonster.clear();
      pivotRef.current?.flexmonster.updateData({type: 'json', data: result });
      pivotRef.current?.flexmonster.setReport(report);
      setExistingConfiguration(config);
      setCurrentDataSet(config.subset);
      setOpenSelectConfiguration(false);
    }));
  };


  const customizeToolbar = (toolbar: Toolbar) => {
    toolbar.showShareReportTab = false;
    // get all tabs
    let tabs = toolbar.getTabs();
    const saveTab = tabs.find((t: any) => t.id === 'fm-tab-save');

    saveTab!.handler = async () => await saveReport();
    toolbar.getTabs = () => {

      // remove the Connect tab using its id
      tabs = tabs.filter((tab: any) => tab.id !== 'fm-tab-connect' && tab.id !== 'fm-tab-open');
      // remove save tab
      if(currentUser?.role.roleName !== 'Administrator') {
        tabs = tabs.filter((tab: any) => tab.id !== 'fm-tab-save');
      }


      tabs.unshift({
        id: 'fm-tab-newtab',
        title: 'Open',
        handler: () => {
          setOpenSelectConfiguration(true);
        },
        icon: toolbar.icons.open
      });
      tabs.unshift({
        id: 'fm-tab-select-data',
        title: 'Datasets',
        menu: [{
            id: 'fm-tab-one',
            title: 'Case File',
            icon:  toolbar.icons.connect_json_remote,
            handler: () => loadCaseFileReportData()
          },
          {
            id: 'fm-tab-two',
            title: 'Services Provided',
            icon:  toolbar.icons.connect_json_remote,
            handler: () => loadServicesProvidedReportData()
          }
        ],
        icon: toolbar.icons.connect
      });
      return tabs;
    };
  };

  const processDelete = async (configId: string) => {
    setErrorMessage('');
    try {
      await deleteConfig(configId);
      setDeletingConfig('');
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e));
    }
  };

  const servicesProvidedDefaultSlice = () => {
    return {
      slice: {
        rows: [
          {
            uniqueName: 'Date.Year'
          },
          {
            uniqueName: '[Measures]'
          }
        ],
        measures: [
          {
            uniqueName: 'Case Management',
            aggregation: 'sum'
          }
        ]
      }
    };
  };

  const caseFileDefaultSlice = () => {
    return {
      slice: {
        rows: [
          {
            uniqueName: '[Measures]'
          },
          {
            uniqueName: 'First Name'
          }
        ],
        columns: [],
        measures: [
          {
            uniqueName: 'Create Date.Month',
            aggregation: 'count'
          }
        ]
      }
    };
  };

  const renderReportDialog = () => (
    <>
      <SaveExportModal
        onSubmit={(res: ReportConfiguration) => {
            setShowSaveExportDialog(false);
        }}
        onCancel={() => setShowSaveExportDialog(false)}
        existingConfiguration={existingConfiguration}
        currentData={currentConfigData}
        currentSubset={currentDataSet}
      />
    </>
  );

  const renderSelectReportModal = () => (
    <>
      <OpenReportConfigurationModal
        onSubmit={openReport}
        onCancel={() => setOpenSelectConfiguration(false)}
        reportConfigurations={reportConfigurations}
        onDelete={setDeletingConfig}
        onCopy={copyConfig}
      />
    </>
  );

  const renderDeleteConfirmationDialog = (configId: string) => {
    const config = getConfigById(configId);
    return (
      <>
        <ConfirmationDialog
          open={!!configId}
          positiveText={'Delete'}
          positiveVariant={'success'}
          onAccept={async () => {
            await processDelete(configId);
          }}
          negativeText={'Cancel'}
          negativeVariant={'danger'}
          onDecline={async () => setDeletingConfig('')}
          prompt={`Are you sure you want to delete the template "${config?.name}"?`}
        />
      </>
    );
  };


  const renderContent = () => (
    <>
      <div>
        <Pivot
          ref={pivotRef} toolbar={true}
          beforetoolbarcreated={customizeToolbar}
          licenseKey={`${process.env.REACT_APP_FLEXMONSTER_KEY}`}
        />
      </div>
      {showSaveExportDialog ? renderReportDialog() : null}
      {openSelectConfiguration ? renderSelectReportModal() : null}
      {renderDeleteConfirmationDialog(deletingConfig)}
    </>
  );

  return (
    <>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          renderContent()
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadConfigurations: loadReportConfigurations,
    deleteConfig: reportConfigurationStore.actions.deleteReportConfiguration,
    copyConfig: reportConfigurationStore.actions.copyReportConfiguration
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  reportConfigurations: reportConfigurationStore.selectors.getAsArray(state),
  getConfigById: reportConfigurationStore.selectors.getById(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportBuilder);
