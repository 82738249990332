import React, {useEffect, useState} from 'react';
import {BtcHistoryEvent} from '../../../types/BtcHistoryEvent';
import {AppTheme, noProfilePicture} from '../../../appTheme';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../redux/util/http';
import {makeBtcHistoryEventList} from '../../../redux/web/factory';
import {Alert, Card, Col, Row, Spinner} from 'react-bootstrap';
import IconButton from '../widgets/IconButton/IconButton';
import {animated, useSpring} from '@react-spring/web';
import useMeasure from 'react-use-measure';

import {useMount} from '../../../hooks/useMount';

interface Props {
  apiHistoryFunc: Promise<BtcHistoryEvent[] | null>;
  label: string;
  containerHeight?: number;
}

export function HistoryViewer({apiHistoryFunc, label, containerHeight}: Props) {
  const [btcHistoryEvents, setBtcHistoryEvents] = useState(makeBtcHistoryEventList());
  const [historyErrorMessage, setHistoryErrorMessage] = useState('');
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [showHistory, setShowHistory] = useState(false);

  const [headerRef, headerSize] = useMeasure();
  const [bodyRef, bodySize] = useMeasure();

  const spring = useSpring({height: showHistory ? `${containerHeight ?? headerSize.height + bodySize.height}px` : `${headerSize.height}px` });

  useMount(() => {
    async function loadHistory() {
      try {
        if(apiHistoryFunc !== null) {
          const historyResult = await apiHistoryFunc;
          await setBtcHistoryEvents(historyResult as BtcHistoryEvent[]);
        }
      } catch (e: AxiosError | any) {
        await setHistoryErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load history'}));
      }
      await setLoadingHistory(false);
    }
    loadHistory();
  });

  useEffect(() => {
    async function reload() {
      try {
        if(apiHistoryFunc !== null) {
          const historyResult = await apiHistoryFunc;
          await setBtcHistoryEvents(historyResult as BtcHistoryEvent[]);
        }
      } catch (e: AxiosError | any) {
        await setHistoryErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to reload history'}));
      }
    }
    reload();
  }, [apiHistoryFunc]);

  const formatChanges = (changes: string) => {
    if(changes) {
      return ` - ${changes}`;
    }
    return '';
  };

  const renderHistoryItem = (item: BtcHistoryEvent, key: number) => {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start', margin: '0.5rem 0'}} key={key}>
        <img
          style={{height: '2rem', width: '2rem', borderRadius: '50%', marginRight: '1rem'}}
          src={
          item.userProfileImageData !== '' ?
            `${process.env.REACT_APP_STORAGE_URL}Storage/Public/ProfilePictures/${item.userProfileImageData}`
            : noProfilePicture()
        }
          alt={'Profile picture'}
        />
        Updated on {item.updatedOn} by {item.name} {formatChanges(item.changes)}
      </div>
    );
  };

  // first row is just for a dummy element to take up the space of the header when closed so the header doesn't overlap other elements
  return (
    <>
      <Row style={{height: headerSize.height, width: '100%'}}/>
      <animated.div style={{
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: 'auto',
        minWidth: '50%',
        maxHeight: '100%',
        zIndex: 100,
        backgroundColor: 'rgb(250, 250, 250)',
        border: `1px solid ${AppTheme.colors.gray400}`,
        overflow: showHistory ? 'auto' : 'hidden',
        ...spring
      }}>
          <Row ref={headerRef} style={{alignItems: 'center', padding: '1rem 2rem', borderColor: `1px solid ${AppTheme.colors.gray400}`}}>
            <h5 style={{marginBottom: 0}}>{label}</h5>
            <div style={{marginLeft: 'auto', overflow: 'hidden', transition: 'transform 200ms ease', transform: showHistory ? 'rotate(180deg)' : ''}}>
              <IconButton
                icon={'chevron-up'}
                size={'1x'}
                onClick={() => setShowHistory(!showHistory)}
              />
            </div>
          </Row>
          <Card ref={bodyRef} style={{padding: '1rem 2rem', borderRadius: '0', overflow: 'hidden'}}>
              {loadingHistory ? <Spinner animation='border' role='status'/> :
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {btcHistoryEvents.map((h, i) => renderHistoryItem(h, i))}
                </div>
              }
              {historyErrorMessage !== '' ?
                <div style={{marginTop: '1rem'}}>
                  <Alert variant='danger'>{historyErrorMessage}</Alert>
                </div> : null
              }
          </Card>
      </animated.div>
    </>
  );
}
