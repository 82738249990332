import React, {useState} from 'react';
import Input from '../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {ArrayHelpers, FieldArray} from 'formik';
import {ListTable} from '../../../../../../components/util/lists/ListTable/ListTable';
import {DeletableListRow} from '../../../../../../components/util/lists/ListTable/ListTableRow';
import IconButton from '../../../../../../components/util/widgets/IconButton/IconButton';
import {Col, Row} from 'react-bootstrap';
import formStyles from '../ParticipantFormFieldArrayStyles.module.scss';
import {Goal} from '../../../../../../redux/web/entities/forms/goalSheet';
import {makeGoal} from '../../../../../../redux/web/factory/forms/goalSheet';

export type TGoalFormEntry = Omit<Goal, 'id'>;

interface Props {
  goals: Goal[];
  editable: boolean;
  fieldPrefix: string;
  headerLabel: string;
}

const getFieldName = (name: keyof Goal) => name;

export const GoalFieldArray = (props: Props) => {
  const {fieldPrefix, goals, editable} = props;
  const generateFieldName = (index: number) => `${fieldPrefix}.${index}`;

  const renderHeader = (helpers: ArrayHelpers) => (
    <Col style={{width: '100%', marginBottom: '10px'}}>
      <Row>
        <h5>{`${props.headerLabel} Goals`}</h5>
        <div style={{marginLeft: '0.5rem', marginTop: '1px', display: 'flex', flex: '1', textAlign: 'right'}}>
          <IconButton
            icon={'plus'}
            styles={{width: '100%', textAlign: 'right'}}
            size={'lg'}
            onClick={() => helpers.push(makeGoal())}
          />
        </div>
      </Row>
    </Col>
  );

  return (
    <FieldArray
      name={fieldPrefix}
      validateOnChange={false}
      render={helpers=> {
        return (
          <ListTable
            style={{marginBottom: '1rem'}}
            noItemsLabel={'No Goals Have Been Added'}
            items={goals}
            className={formStyles['list-row']}
            renderHeader={() => renderHeader(helpers)}
            renderItem={(item, index) => (
              <DeletableListRow
                iconSize={'2x'}
                key={generateFieldName(index)}
                deleteColumnSize={1}
                className={formStyles['list-row']}
                render={() => (
                  <GoalRow
                    entryPrefix={generateFieldName(index)}
                    editable={editable}
                    goal={item}
                  />
                )}
                editable={editable}
                onDelete={() => helpers.remove(index)}
              />
            )}
          />
        );
      }}
    />
  );
};

type CaseNoteRowProps = {
  editable: boolean;
  goal: Goal;
  entryPrefix: string;
};
function GoalRow({goal, entryPrefix, editable}: CaseNoteRowProps) {
  return  (
    <Row style={{display: 'flex', flexGrow: '1', overflowY: 'hidden', overflowX: 'hidden'}}>
        <Input name={`${entryPrefix}.${getFieldName('goal')}`} type={'textarea'}/>
    </Row>
  );
}
