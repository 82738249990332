import {api} from '../index';
import {
  TPrimaryIntakeUpsertForm
} from '../../pages/Configuration/UserManagement/components/UserGrid/PrimaryIntakeModal/PrimaryIntakeModal';
import {PrimaryIntakeSuccessState} from '../../redux/web/stateResponses/upsertPrimaryIntakeData';
import {PrimaryIntake} from '../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {
  TNewParticipantChecklistForm
} from '../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/NewParticipantChecklistModal/NewParticipantChecklistModal';
import {NewParticipantChecklist} from '../../redux/web/entities/forms/newParticipantChecklist';
import {
  TAdmissionCoverSheetForm
} from '../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/AdmissionCoverSheetModal/AdmissionCoverSheetModal';
import {
  TGoalSheetForm
} from '../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/GoalSheetModal/GoalSheetModal';
import {GoalSheet} from '../../redux/web/entities/forms/goalSheet';
import {AdmissionCoverSheet} from '../../redux/web/entities/forms/admissionCoverSheet';
import {UserFormsState} from '../../redux/web/stateResponses/userFormsData';
import {
  TWeeklyCaseNotesForm
} from '../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/WeeklyCaseManagementNotesModal';
import {
  CaseManagementContact, CaseManagementContactDto,
  WeeklyCaseManagementNotes
} from '../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {SecondaryIntake} from '../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {Dictionary} from '../../redux/util';
import {IntakesState} from '../../redux/web/stateResponses/stateManagement';

export async function getUserFormsData(userId: string) {
  return (await api.get<UserFormsState>(`Form/${userId}`)).data;
}

export async function getPrimaryIntake(id: string) {
  return (await api.get<PrimaryIntake>(`Form/primaryIntake/${id}`)).data;
}

export async function getPrimaryIntakes() {
  return (await api.get<IntakesState>(`Form/primaryIntake/all`)).data;
}


export async function upsertPrimaryIntake(form: TPrimaryIntakeUpsertForm) {
  return (await api.post<PrimaryIntakeSuccessState>('/User/primaryIntake', form)).data;
}

export async function getSecondaryIntake(id: string) {
  return (await api.get<SecondaryIntake>(`Form/secondaryIntake/${id}`)).data;
}

export async function upsertSecondaryIntake(form: SecondaryIntake) {
  return (await api.post<SecondaryIntake>('Form/secondaryIntake', form)).data;
}

export async function getWeeklyCaseManagementNotes(id: string) {
  return (await api.get<WeeklyCaseManagementNotes | null>(`Form/newParticipantChecklist/${id}`)).data;
}

export async function upsertWeeklyCaseManagementNotes(form: TWeeklyCaseNotesForm) {
  return (await api.post<WeeklyCaseManagementNotes>('/Form/weeklyCaseManagementNotes', form)).data;
}

export async function upsertCaseManagementContact(form: CaseManagementContactDto) {
  return (await api.post<WeeklyCaseManagementNotes>('/Form/CaseManagementContact', form)).data;
}


export async function getNewParticipantChecklist(id: string) {
  return (await api.get<NewParticipantChecklist | null>(`Form/newParticipantChecklist/${id}`)).data;
}

export async function upsertNewParticipantChecklist(form: TNewParticipantChecklistForm) {
  return (await api.post<NewParticipantChecklist>('/Form/newParticipantChecklist', form)).data;
}

export async function getAdmissionCoverSheet(id: string) {
  return (await api.get<AdmissionCoverSheet | null>(`Form/admissionCoverSheet/${id}`)).data;
}

export async function upsertAdmissionCoverSheet(form: TAdmissionCoverSheetForm) {
  return (await api.post<AdmissionCoverSheet>('/Form/admissionCoverSheet', form)).data;
}

export async function getGoalSheet(id: string) {
  return (await api.get<GoalSheet | null>(`Form/goalSheet/${id}`)).data;
}

export async function upsertGoalSheet(form: TGoalSheetForm) {
  return (await api.post<GoalSheet>('/Form/goalSheet', form)).data;
}
