import React, {useEffect} from 'react';
import {useField, useFormikContext} from 'formik';
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';
// tslint:disable-next-line:no-import-side-effect
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatepickerInput.module.scss';
import {Form} from 'react-bootstrap';
import {differenceInDays, format, isValid, parse, parseISO} from 'date-fns';
import {RedErrorMessage} from '../../RedErrorMessage/RedErrorMessage';
import {now} from '../../../../../util';

interface DatepickerInputProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  showTimeInput?: boolean;
  showTimeSelect?: boolean;
  timeIntervals?: number;
  timeFormat?: string;
  dateFormat?: string;
  placeholderDate?: Date;
  minDateTime?: Date;
  maxDateTime?: Date;
  rows?: number;
  placeHolderText?: string;
  onChangeUtcHour?: number;
  ignoreOverflow?: boolean;
  valueOnDisabled?: Date | string | null;
  allowFuture?: boolean;
}

const DatepickerInput = (props: DatepickerInputProps) => {
  const {name, showTimeInput, showTimeSelect, timeIntervals, timeFormat, ignoreOverflow, disabled, onChangeUtcHour,
    dateFormat, placeholderDate, minDateTime, maxDateTime, placeHolderText, valueOnDisabled, allowFuture = false, ...restProps} = props;

  const {setFieldValue} = useFormikContext<any>();
  const [field, {value, error, touched}] = useField(name);

  useEffect(() => {
    if (!!(valueOnDisabled !== undefined && disabled))
      setFieldValue(name, valueOnDisabled);
  }, [valueOnDisabled, disabled]);

  const getDateValue = (val: string | Date | null) => {
    if (!val)
      return val;
    const dateVal = val instanceof Date ? val : parseISO(val);
    // If utc time id desired, set time to noon utc
    if (onChangeUtcHour) {
      dateVal.setUTCHours(onChangeUtcHour, 0, 0, 0);
      return dateVal;
    }
    // else, return date
    return dateVal;
  };

  const combinedFormat = dateFormat || `MM/dd/yyyy${timeFormat ? ` ${timeFormat}` : ''}`;
  const placeHolder = placeholderDate ? format(placeholderDate, combinedFormat) : 'mm/dd/yyyy';

  return (
    <React.Fragment>
      <ReactDatePicker
        {...field}
        calendarClassName={styles['datepicker-calendar']}
        showTimeInput={showTimeInput || false}
        showTimeSelect={showTimeSelect || false}
        timeFormat={timeFormat || ''}
        timeIntervals={timeIntervals || 15}
        dateFormat={combinedFormat}
        placeholderText={placeHolderText ?? placeHolder}
        minDate={minDateTime}
        maxDate={maxDateTime ?? allowFuture ? undefined : now}
        selected={field.value && getDateValue(field.value)}
        autoComplete={'off'}
        disabled={disabled}
        isClearable={!!valueOnDisabled}
        popperProps={ignoreOverflow ? {strategy: 'fixed'} : undefined}
        value={field.value ? getDateValue(field.value) as any : null}

        {...restProps}
        onChange={(val: Date | null) => {
          setFieldValue(field.name, getDateValue(val));
        }}
        // @ts-ignore
        customInput={<Form.Control{...field} rows={props.rows}/>}
      />
      <RedErrorMessage name={props.name}/>
    </React.Fragment>
  );
};

export default DatepickerInput;
