import {AccountStatus, User, UserPreferences} from '../entities/user';
import {BtcDocument} from '../entities/btcDocument';
import {format, parseISO} from 'date-fns';
import {ParticipantClass} from '../entities/participantClass';
import {
  ParticipantClassModalInterface
} from '../../../pages/Configuration/ParticipantClassManagement/components/ParticipantClassModal/ParticipantClassModal';
import {now} from '../../../util';
import {CaseFileDocument, CaseFileDocumentStatus, DocumentSignatureLocation} from '../entities/caseFileDocument';
import {Role} from '../entities/role';
import {UserUpsert} from '../../../pages/Configuration/UserManagement/components/UserModal/UserModal';
import {
  MergeCaseFileDocumentsRequest
} from '../../../pages/CaseFile/Modals/CaseFileDocumentMergeModal/CaseFileDocumentMergeModal';
import {Chore, ChoreChartRow, ChoreChartWeek} from '../entities/choreChartWeek';
import {ChecklistItem, ChecklistRow, ChecklistWeek} from '../entities/checklistWeek';
import {BtcHistoryEvent} from '../../../types/BtcHistoryEvent';
import {ReportConfiguration} from '../entities/reportConfiguration';
import {
  DashboardMessage,
  StaffDashboardState,
  DashboardTask,
  EDashboardMessageStyle,
  ParticipantDashboardState, DashboardClass
} from '../../../types/DashboardTypes';

export function makeUser(role?: Role): User {
  return {
    id: '',
    email: '',
    createdAt: '',
    archivedAt: null,
    name: '',
    standardId: '',
    primaryPhone: '',
    secondaryPhone: '',
    role: role ?? {
      id: '',
      roleName: ''
    },
    roleId: '',
    address: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zip: '',
    birthDate: '',
    receiveTextNotifications: false,
    tosAccepted: false,
    accountStatus: AccountStatus.EmailValidationRequired,
    profilePicturePath: ''
  };
}

export function makeUserRegistration(role?: Role): UserUpsert {
  return {
    id: '',
    email: '',
    name: '',
    primaryPhone: '',
    secondaryPhone: '',
    roleId: role?.id ?? '',
    address: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zip: '',
    birthDate: '',
    standardId: ''
  };
}

export function makeUserPreferences(user: User): UserPreferences {
  return {
    name: user.name,
    email: user.email,
    city: user.city,
    state: user.state,
    zip: user.zip,
    address: user.address,
    addressLineTwo: user.addressLineTwo,
    primaryPhone: user.primaryPhone,
    secondaryPhone: user.secondaryPhone,
    birthDate: user.birthDate
  };
}


export function makeBtcDocument(document: BtcDocument | null): BtcDocument {
  return {
    id: document ? document.id : undefined as any as '',
    name: document ? document.name : '',
    data: document ? document.data : undefined as any,
    currentSnapshotId: document ? document.currentSnapshotId : undefined as any
  };
}

export function makeCaseFileDocument(document: CaseFileDocument | null): CaseFileDocument {
  return {
    id: document ? document.id : undefined as any as '',
    name: document ? document.name : '',
    data: document ? document.data : undefined as any,
    caseFileId: document ? document.caseFileId : undefined as any,
    status: document ? document.status : CaseFileDocumentStatus.None,
    envelopeId: document ? document.envelopeId : undefined as any,
    signatures: [],
    wetSignatureProvided: document ? document.wetSignatureProvided : false,
    disabled: false
  };
}

export function makeDocumentSignatureLocation(id: string, tabLabel: string, pageNumber: string,
                                              xPosition: string, yPosition: string): DocumentSignatureLocation {
  return {
    formFieldId: id,
    tabLabel: tabLabel,
    pageNumber: pageNumber,
    xPosition: xPosition,
    yPosition: yPosition
  };
}


export function makeCaseFileDocumentMergeRequest(documents?: CaseFileDocument[], name?: string): MergeCaseFileDocumentsRequest {
  return {
    orderedBtcDocumentIds: documents ? documents.map(doc => doc.id) : [],
    name: name ?? ''
  };
}

export function makeParticipantClass(pc?: ParticipantClass): ParticipantClass {
  return {
    id: pc?.id ?? '',
    name: pc?.name ?? '',
    startDateTime: pc?.startDateTime ?? '',
    endDateTime: pc?.endDateTime ?? '',
    description: pc?.description ?? '',
    participantClassAssignments: pc?.participantClassAssignments ?? []
  };
}

export function makeParticipantClassModal(pc?: ParticipantClass): ParticipantClassModalInterface {
  return {
    id: pc?.id ?? '',
    name: pc?.name ?? '',
    classDate: pc?.startDateTime ? parseISO(pc.startDateTime) : now,
    startTime: pc?.startDateTime ? format(parseISO(pc.startDateTime), 'HH:mm') : '',
    endTime: pc?.endDateTime ? format(parseISO(pc.endDateTime), 'HH:mm') : '',
    description: pc?.description ?? '',
    participantIds: pc?.participantClassAssignments.map((pca) => pca.participantId) ?? []
  };
}

export function makeChoreChartWeek(year: number, weekOfYear: number, existing?: ChoreChartWeek, previousWeek?: ChoreChartWeek): ChoreChartWeek {
  return existing ? {...existing} : previousWeek ? {...previousWeek} : {
    id: '',
    year: year,
    weekOfTheYear: weekOfYear,
    reminder: '',
    rows: []
  };
}

export function makeChoreChartRow(taskNumber: number, existing?: ChoreChartRow): ChoreChartRow {
  return existing ? {...existing} : {
    id: '',
    taskNumber,
    choreChartWeekId: '',
    task: '',
    chores: makeRowOfChores()
  };
}

export function makeChore(dayOfTheWeek: number, existing?: Chore): Chore {
  return existing ? {...existing} : {
    id: '',
    userId: '',
    choreChartRowId: '',
    done: false,
    dayOfTheWeek: dayOfTheWeek
  };
}

export function makeChecklistWeek(year: number, weekOfYear: number, existing?: ChecklistWeek, previousWeek?: ChecklistWeek): ChecklistWeek {
  return existing ? {...existing} : previousWeek ? {...previousWeek} : {
    id: '',
    year: year,
    weekOfTheYear: weekOfYear,
    reminder: '',
    rows: []
  };
}

export function makeChecklistRow(taskNumber: number, existing?: ChecklistRow): ChecklistRow {
  return existing ? {...existing} : {
    id: '',
    taskNumber,
    checklistWeekId: '',
    task: '',
    items: makeRowOfItems()
  };
}

export function makeChecklistItem(dayOfTheWeek: number, existing?: ChecklistItem): ChecklistItem {
  return existing ? {...existing} : {
    id: '',
    userId: '',
    checklistRowId: '',
    done: false,
    dayOfTheWeek: dayOfTheWeek,
    updatedAt: '',
    updatedBy: ''
  };
}


export function makeReportConfiguration(document: ReportConfiguration | undefined): ReportConfiguration {
  return {
    id: document ? document.id : undefined as any as '',
    name: document ? document.name : '',
    data: document ? document.data : undefined as any,
    subset: document ? document.subset : 'CaseFile'
  };
}


export function makeBtcHistoryEventList() {
  return [] as BtcHistoryEvent[];
}

function makeRowOfChores() {
  const chores = [];
  for (let i = 0; i < 7; i++)
    chores.push(makeChore(i));
  return chores;
}

function makeRowOfItems() {
  const items = [];
  for (let i = 0; i < 7; i++)
    items.push(makeChecklistItem(i));
  return items;
}

export function makeDashboardMessage(): DashboardMessage {
  return {
    id: '',
    text: '',
    dashboardMessageStyle: EDashboardMessageStyle.info,
    roleId: ''
  };
}

export function makeDashboardTask(): DashboardTask {
  return {
    id: '',
    userId: '',
    name: 'New task',
    complete: false,
    description: ''
  };
}

export function makeDashboardClass(): DashboardClass {
  return {
    id: '',
    name: '',
    date: new Date(),
    description: ''
  };
}

export function makeDashboardState(): StaffDashboardState {
  return {
    participantsThisWeek: [],
    participantsThisMonth: [],
    participantsThisYear: [],
    participants: [],
    documentsAwaitingSignature: [],
    message: makeDashboardMessage(),
    tasks: []
  };
}

export function makeParticipantDashboardState(): ParticipantDashboardState {
  return {
    message: makeDashboardMessage(),
    tasks: [],
    documentsAwaitingSignature: [],
    classes: []
  };
}
