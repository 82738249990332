import {Dictionary} from '../../util';
import {User, userStore} from '../entities/user';
import {Dispatch} from 'redux';
import {batchActions} from 'redux-batched-actions';
import {ChoreChartWeek, choreChartWeekStore} from '../entities/choreChartWeek';
import {getChoreChartState} from '../../../api/choreChartChecklistApi';

export interface ChoreChartState {
  participants: Dictionary<User>;
  choreChartWeeks: Dictionary<ChoreChartWeek>;
}

export const loadChoreChartData = (isoDate: string) => async (dispatch: Dispatch) => {
  const response: ChoreChartState = await getChoreChartState(isoDate);
  await dispatch(batchActions([
    await dispatch(userStore.actions.setIncludedData(response.participants)),
    await dispatch(choreChartWeekStore.actions.setIncludedData(response.choreChartWeeks))
  ], 'LOAD_CHORE_CHART_STATE'));
};
