import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../redux/core/types/WebState';
import {userStore} from '../../redux/web/entities/user';
import {ParticipantDashboardState, StaffDashboardState} from '../../types/DashboardTypes';
import {useMount} from '../../hooks/useMount';
import {getDashboardState, getParticipantDashboardState} from '../../api/dashboardApi';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../redux/util/http';
import {makeDashboardState, makeParticipantDashboardState} from '../../redux/web/factory';
import {loadUserManagementData} from '../../redux/web/stateResponses/stateManagement';
import ParticipantDashboard from './ParticipantDashboard';
import StaffDashboard from './StaffDashboard';
import {SetStateFunctional} from '../../util';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function Dashboard(props: Props) {
  const {isAdministrator, isParticipant, actions: {loadUsers, loadParticipants}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [dashboardState, setDashboardState] =
    useState<StaffDashboardState | ParticipantDashboardState>(isParticipant ? makeParticipantDashboardState() : makeDashboardState());

  useMount(async () => {
    try {
      await setDashboardState(isParticipant ? await getParticipantDashboardState() : await getDashboardState());
      if (isAdministrator) {
        await loadUsers();
      }
      else if (!isParticipant)
        await loadParticipants();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load users'}));
    }
    setLoading(false);
  });

  // not the cleanest way to do this but works so w/e
  return (
    <div>
      {
      errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
        (isParticipant ?
          <ParticipantDashboard
            dashboardState={dashboardState as ParticipantDashboardState}
            setDashboardState={setDashboardState as SetStateFunctional<ParticipantDashboardState>}
          /> :
          <StaffDashboard
            loading={loading}
            dashboardState={dashboardState as StaffDashboardState}
            setDashboardState={setDashboardState as SetStateFunctional<StaffDashboardState>}
          />
        )
      }
  </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadUsers: loadUserManagementData,
    loadParticipants: userStore.actions.getParticipants
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  isAdministrator: userStore.selectors.isAdministrator(state),
  isParticipant: userStore.selectors.isParticipant(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
