import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import { Row, Col} from 'react-bootstrap';
import {RoutePaths} from '../../router/RoutePaths';
import {useStandardEditor} from '../../components/util/form-components/EditorForm/hooks';
import Input from '../../components/util/form-components/formik-inputs/Input/Input';
import {EditorForm} from '../../components/util/form-components/EditorForm/EditorForm';
import {AppTheme} from '../../appTheme';
import {UserPreferences, userStore} from '../../redux/web/entities/user';
import {makeUser, makeUserPreferences} from '../../redux/web/factory';
import {WebState} from '../../redux/core/types/WebState';
import ProfilePicture from '../../components/util/ProfilePicture/ProfilePicture';
import DatepickerInput from '../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {StandardInputColumn} from '../../components/util/form-components/standardLayout';
import PhoneInputField from '../../components/util/form-components/formik-inputs/PhoneNumberInput/PhoneNumberInput';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function Profile(props: Props) {
  const {currentUser} = props;

  const getFieldName = (name: keyof UserPreferences) => name;
  const standardEditor = useStandardEditor<UserPreferences>(
    'Profile',
    makeUser(),
    props,
    s => ({
      getEditUrl: () => RoutePaths.profilePaths.Edit,
      getCancelUrl: () => RoutePaths.profilePaths.View,
      onLoadForm: async () => makeUserPreferences(currentUser!),
      onNewForm: async () => makeUserPreferences(currentUser!),
      saveAndRedirect: async (form) => {
        await props.actions.updateUserPreferences(form);
        return RoutePaths.profilePaths.View;
      },
      footer: true,
      editButtonText: 'Edit Profile',
      saveButtonText: 'Save Profile',
      submitButtonColor: AppTheme.colors.primary
    }));
  const {editable} = standardEditor;

  const renderContent = () => {
    return (
      <Col xl={5} style={{margin: 'auto'}}>
        <Row style={{justifyContent: 'center'}}>
          <ProfilePicture userId={currentUser?.id ?? ''}/>
        </Row>
        <EditorForm standardEditor={standardEditor}>
          {({values, setFieldValue}) => {
            return (
              <Row>
                <Col style={{padding: '0'}}>
                <StandardInputColumn label='Name'>
                  <Input name={getFieldName('name')} disabled={!editable}/>
                </StandardInputColumn>
                <StandardInputColumn label='Primary Phone'>
                  <PhoneInputField name={getFieldName('primaryPhone')} disabled={!editable}/>
                </StandardInputColumn>
                <StandardInputColumn label='Secondary Phone'>
                  <PhoneInputField name={getFieldName('secondaryPhone')} disabled={!editable}/>
                </StandardInputColumn>
                <StandardInputColumn label='Address'>
                  <Input name={getFieldName('address')} disabled={!editable}/>
                </StandardInputColumn>
                <StandardInputColumn label='Address Line Two'>
                  <Input name={getFieldName('addressLineTwo')} disabled={!editable}/>
                </StandardInputColumn>
                </Col>
                <Col>
                <StandardInputColumn label='Email'>
                  <Input name={getFieldName('email')} disabled={!editable}/>
                </StandardInputColumn>
                <StandardInputColumn label='City'>
                  <Input name={getFieldName('city')} disabled={!editable}/>
                </StandardInputColumn>
                <StandardInputColumn label='State'>
                  <Input name={getFieldName('state')} disabled={!editable}/>
                </StandardInputColumn>
                <StandardInputColumn label='Zip Code'>
                  <Input name={getFieldName('zip')} disabled={!editable}/>
                </StandardInputColumn>
                <StandardInputColumn label='Birth Date'>
                  <DatepickerInput
                    name={getFieldName('birthDate')}
                    disabled={!editable}
                    showYearDropdown={true}
                    onChangeUtcHour={12}
                  />
                </StandardInputColumn>
                </Col>
              </Row>
            );
          }}
        </EditorForm>
      </Col>

    );
  };

  return renderContent();
}

const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    updateUserPreferences: userStore.actions.updateUserPreferences
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
