import {
  DashboardMessage,
  EDashboardMessageStyle,
  ParticipantDashboardState,
  StaffDashboardState
} from '../../../../types/DashboardTypes';
import {SetStateFunctional} from '../../../../util';
import React, {useState} from 'react';
import AdministratorComponent from '../../../../components/util/AdministratorComponent/AdministratorComponent';
import IconButton from '../../../../components/util/widgets/IconButton/IconButton';
import {Alert, Row} from 'react-bootstrap';
import DashboardCard from '../DashboardCard/DashboardCard';
import {WebState} from '../../../../redux/core/types/WebState';
import {userStore} from '../../../../redux/web/entities/user';
import {connect} from 'react-redux';
import DashboardMessageModal from './DashboardMessageModal/DashboardMessageModal';
import {upsertDashboardMessage} from '../../../../api/dashboardApi';

type Props = {
  message?: DashboardMessage;
  setDashboardState: SetStateFunctional<StaffDashboardState> | SetStateFunctional<ParticipantDashboardState>;
} & ReturnType<typeof mapStateToProps>;

const DashboardMessageCard = (props: Props)  => {
  const {message, setDashboardState, isAdministrator} = props;
  const [editingMessage, setEditingMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const renderDashboardMessageModal = () => editingMessage &&
    <DashboardMessageModal
      messageData={message}
      onSubmit={async (data: DashboardMessage) => {
        const response = await upsertDashboardMessage(data);
        setDashboardState((prev: any) => ({...prev, message: response}));
        setEditingMessage(false);
      }}
      onCancel={() => setEditingMessage(false)}
    />;

  const renderTitle = () => (
    <>
      <div>Notification</div>
      <AdministratorComponent>
        <div style={{position: 'absolute', right: '10px'}}>
          <IconButton icon={'pen'} size={'1x'} color={'#005A9C'} onClick={() => setEditingMessage(true)}/>
        </div>
      </AdministratorComponent>
    </>
  );

  if (message?.text === '' && !isAdministrator) return null;
  return (
    <>
      <DashboardCard title={renderTitle()} titleStyle={{marginBottom: '1rem'}}>
        <Row>
          {message?.text ?
            <Alert
              style={{display: 'flex', flex: '1'}}
              variant={EDashboardMessageStyle[message.dashboardMessageStyle]}
            >
              {message.text ?? 'No message'}
            </Alert>
            : 'No Dashboard Message Created.'
          }
        </Row>
      </DashboardCard>
      {renderDashboardMessageModal()}
    </>
  );
};

const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  isAdministrator: userStore.selectors.isAdministrator(state)
});

export default connect(mapStateToProps)(DashboardMessageCard);
