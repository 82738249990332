import React, {useState} from 'react';
import Input from '../../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {ArrayHelpers, FieldArray, FormikValues} from 'formik';
import {
  StandardCheckboxInput,
  StandardInputColumn,
  StandardInputRow
} from '../../../../../../../../../components/util/form-components/standardLayout';
import {ListTable} from '../../../../../../../../../components/util/lists/ListTable/ListTable';
import {DeletableListRow} from '../../../../../../../../../components/util/lists/ListTable/ListTableRow';
import {ServicesProvided} from '../../../../../../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import IconButton from '../../../../../../../../../components/util/widgets/IconButton/IconButton';
import {makeServicesProvided} from '../../../../../../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import DatepickerInput
  from '../../../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {Accordion, Col, Row} from 'react-bootstrap';
import fieldArrayStyles from '../../../../ParticipantFormFieldArrayStyles.module.scss';
import {FieldArrayRow} from '../../../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import {makeFieldArrayFieldName} from '../../../../../../../../../util';
import {InputRow} from '../../../../../../../../../components/util/form-components/InputRow';
import {InputColumn} from '../../../../../../../../../components/util/form-components/InputColumn';
import {CaseNotesFieldArray} from '../CaseNotesForm/CaseNotesFieldArray';
import {format} from 'date-fns-tz';

export type TCaseManagementServicesProvidedEntry = Omit<ServicesProvided, 'id' | 'date'> & {date: Date};

interface Props {
  servicesProvided: ServicesProvided[];
  editable: boolean;
  fieldPrefix: string;
}

const getFieldName = (name: keyof ServicesProvided) => name;

export const ServicesProvidedFieldArray = (props: Props) => {
  const {fieldPrefix, servicesProvided, editable} = props;
  const generateFieldName = (index: number) => `${fieldPrefix}.${index}`;
  const renderHeader = (helpers: ArrayHelpers) => (
    <Col style={{width: '100%', marginBottom: '10px'}}>
      <Row>
        <h5>Services Provided</h5>
        <div style={{marginLeft: '0.5rem', marginTop: '1px', display: 'flex', flex: '1', textAlign: 'right'}}>
          <IconButton
            icon={'plus'}
            styles={{width: '100%', textAlign: 'right'}}
            size={'lg'}
            onClick={() => {
              helpers.push(makeServicesProvided());
              const elements = document.getElementsByClassName('services-provided');
              if (elements.length === 0) return;
              elements[elements.length - 1].scrollIntoView({ behavior: 'smooth', block: 'end' });
            }}
          />
        </div>
      </Row>
    </Col>
  );

  return (
    <FieldArray
      name={fieldPrefix}
      validateOnChange={false}
      render={(helpers) => {
        return (
          <ListTable
            style={{marginBottom: '1rem'}}
            noItemsLabel={'No Services Provided entries added'}
            items={servicesProvided}
            className={fieldArrayStyles['list-row']}
            renderHeader={() => renderHeader(helpers)}
            renderItem={(item, index) => (
              <Accordion>
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header>{new Date(item.date).toLocaleDateString()}</Accordion.Header>
                  <Accordion.Body>
                    <DeletableListRow
                      iconSize={'2x'}
                      key={generateFieldName(index)}
                      className={'services-provided'}
                      deleteColumnSize={1}
                      style={{flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'visible'}}
                      render={() => (
                        <ServicesProvidedRow
                          entryPrefix={generateFieldName(index)}
                          editable={editable}
                          servicesProvided={item}
                        />
                      )}
                      editable={editable}
                      onDelete={() => helpers.remove(index)}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          />
        );
      }}
    />
  );
};

type ServicesProvidedRowProps = {
  editable: boolean;
  servicesProvided: ServicesProvided;
  entryPrefix: string;
};
function ServicesProvidedRow({servicesProvided, entryPrefix, editable}: ServicesProvidedRowProps) {
  return  (
    <FieldArrayRow style={{flex: 'auto', minWidth: '13rem', marginRight: '1.5rem'}}>
      <StandardInputColumn label={'Date'} columnSize={2} className='pl-2'>
        <DatepickerInput
          name={`${entryPrefix}.${getFieldName('date')}`}
          showYearDropdown={true}
          ignoreOverflow={true}
          allowFuture={true}
          onChangeUtcHour={12}
        />
      </StandardInputColumn>
      <StandardInputColumn label={'Services Provided'} labelSize={18} style={{marginTop: '1rem'}}>
        <div style={{display: 'flex'}}>
          <StandardInputColumn label={'OES Services'} columnSize={3} labelSize={16}>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('oesOutreach')}`} label={'Outreach'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('oesFood')}`} label={'Food'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('oesTemporarySafeShelter')}`} label={'Temporary Safe Shelter'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('oesInPersonCounseling')}`} label={'In Person Counseling'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('oesReferralService')}`} label={'Referral Service'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('oesOther')}`} label={'Other'}/>
            <Input placeholder={'Other Name'} name={makeFieldArrayFieldName(entryPrefix, getFieldName('oesOtherName'))} type={'text'}/>
          </StandardInputColumn>

          <StandardInputColumn label={'1 Unit = 15 Minutes'} columnSize={8} style={{marginLeft: '1rem'}}>
            <div style={{display:'flex'}}>
              <div>
                <InputColumn label={'Crisis Counseling'}>
                  <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('oesCrisisCounselingUnits'))} type={'number'}/>
                </InputColumn>
                <InputColumn label={'In Person Counseling'}>
                  <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('oesInPersonCounselingUnits'))} type={'number'}/>
                </InputColumn>
                <InputColumn label={'Group Counseling/Discussion'}>
                  <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('oesGroupCounselingOrDiscussionUnits'))} type={'number'}/>
                </InputColumn>
                <InputColumn label={'Case Management'}>
                  <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('oesCaseManagementUnits'))} type={'number'}/>
                </InputColumn>
              </div>
              <div>
                <InputColumn label={'Shelter Services'}>
                  <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('oesShelterServicesUnits'))} type={'number'}/>
                </InputColumn>
                <InputColumn label={'Transportation'}>
                  <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('oesTransportationUnits'))} type={'number'}/>
                </InputColumn>
                <InputColumn label={'Legal Assistance'}>
                  <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('oesLegalAssistanceUnits'))} type={'number'}/>
                </InputColumn>
                <InputColumn label={'Referrals'}>
                  <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('oesReferralsUnits'))} type={'number'}/>
                </InputColumn>
              </div>
            </div>
          </StandardInputColumn>
        </div>


        <div style={{display: 'flex', marginTop: '2rem'}}>
          <StandardInputColumn label={'DSS Services - Single Incident'} columnSize={4} labelSize={16}>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('dssCrisisIntervention')}`} label={'Crisis Intervention'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('dssAdvocacy')}`} label={'Advocacy'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('dssEducationalSupport')}`} label={'Educational Support'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('dssLifeSkillsTraining')}`} label={'Life Skills Training'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('dssHtWorkShops')}`} label={'Ht Work Shops'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('dssForensicSpecialtyServices')}`} label={'Forensic Specialty Services'}
                                   type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('dssSupportiveCounseling')}`} label={'Supportive Counseling'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('dssEmergencyServices')}`} label={'Emergency Services'} type={'checkbox'}/>
          </StandardInputColumn>

          <StandardInputColumn label={'Referral Responses - Single Incident'} columnSize={6} labelSize={16}>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('referralResponseImmediateResponse')}`} label={'Immediate Response'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('referralResponseThreeDayResponse')}`} label={'Three Day Response'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('referralResponseTenDayResponse')}`} label={'Ten Day Response'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('referralResponseReReferralToService')}`} label={'Re-Referral To Service'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('referralResponseYouthAcceptedServices')}`} label={'Youth Accepted Services'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('referralResponseSuccessfulCompletion')}`} label={'Successful Completion'}
                                   type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('referralResponseProgressNoCompletion')}`} label={'Progress, No Completion'} type={'checkbox'}/>
            <StandardCheckboxInput name={`${entryPrefix}.${getFieldName('referralResponseNoProgressNoCompletion')}`} label={'No Progress, No Completion'} type={'checkbox'}/>
          </StandardInputColumn>
        </div>



        <div style={{display: 'flex', marginTop: '2rem'}}>
          <StandardInputColumn label={'OVC Services - 1 Unit = 1 Incident'} columnSize={5} labelSize={16}>
            <InputColumn label={'Child Care'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcChildCare'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Dental (Er/Long Term)'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcDentalErLongTerm'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Education'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcEducation'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Financial Assistance ($1 = 1 Unit)'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcFinancialAssistance'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Housing/Rental Assistance'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcHousingRentalAssistance'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Interpreter/Translator'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcInterpreterTranslator'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Medical (Er/Long Term)'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcMedicalErLongTerm'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Mental Health/Treatment (Er/Long Term)'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcMentalHealthTreatmentErLongTerm'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Personal Items (1 Unit = 1 Item)'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcPersonalItems'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Substance Abuse Treatment'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcSubstanceAbuseTreatment'))} type={'number'}/>
            </InputColumn>

            <InputColumn label={'Transportation'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcTransportation'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Other'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcOther'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={''} style={{marginTop: '1rem'}}>
              <Input placeholder={'Other Name'} name={makeFieldArrayFieldName(entryPrefix, getFieldName('ovcOtherName'))} type={'text'}/>
            </InputColumn>
          </StandardInputColumn>

          <StandardInputColumn label={'1 Unit = 15 Minutes'} columnSize={8} style={{marginLeft: '1rem'}}>
            <InputColumn label={'Client Coordination'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('clientCoordination'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Client Orientation'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('clientOrientation'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Criminal Justice Based Advocacy'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('criminalJusticeBasedAdvocacy'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Crisis Intervention/24 Hour Hotline'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('crisisIntervention24HrHotline'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Emotional/Moral Support'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('emotionalMoralSupport'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Employment Assistance'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('employmentAssistance'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Family Reunification'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('familyReunification'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Housing/Shelter Advocacy'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('housingShelterAdvocacy'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Ongoing Case Management'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('ongoingCaseManagement'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Protection/Safety Planning'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('protectionSafetyPlanning'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Repatriation'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('repatriation'))} type={'number'}/>
            </InputColumn>
            <InputColumn label={'Social Service Advocacy and Explanation of Benefits/Entitlements/Availability'}>
              <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('socialServiceAdvocacyExplanationBenefitsEntitlementsAvailability'))} type={'number'}/>
            </InputColumn>
          </StandardInputColumn>
        </div>






      </StandardInputColumn>
    </FieldArrayRow>
  );
}
