import {WebState} from '../../../../redux/core/types/WebState';
import {User} from '../../../../redux/web/entities/user';
import {CaseFileDocumentStatus, caseFileDocumentStore} from '../../../../redux/web/entities/caseFileDocument';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {Card, Col, Row} from 'react-bootstrap';
import InformationLabel, {
  InformationLabelProps
} from '../../../../components/util/form-components/InformationLabel/InformationLabel';
import {parseISO} from 'date-fns';
import React, {useMemo, useState} from 'react';
import {primaryIntakeStore} from '../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {secondaryIntakeStore} from '../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  CaseManagementContact, CaseManagementContactType, CaseManagementMedication,
  weeklyCaseManagementNotesStore
} from '../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {newParticipantChecklistStore} from '../../../../redux/web/entities/forms/newParticipantChecklist';
import {goalSheetStore} from '../../../../redux/web/entities/forms/goalSheet';
import {admissionCoverSheetStore} from '../../../../redux/web/entities/forms/admissionCoverSheet';
import styles from './CaseFileSummary.module.scss';
import {PreMadeFormModalType} from '../CaseFilePreMadeFormsGrid';
import {calculateAge, whitespaceIfNullOrNotApplicable} from '../../../../util';
import { getObjectValues} from '../../../../redux/util';
import {convertPascalToSentence, isNullOrWhitespace} from '../../../../redux/util/string';
import {ParticipantClass, participantClassStore} from '../../../../redux/web/entities/participantClass';
import ContactsFormModal
  from '../CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/ContactsForm/ContactsFormModal';
import {
  makeCaseManagementContactModal
} from '../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'react-phone-number-input';

type Props = {
  user: User;
  onFormSelect: (form: PreMadeFormModalType) => void;
  reloadCaseFileHistory: () => void;
} & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const CaseFileSummary = (props: Props) => {
  const {
    user, onFormSelect, caseFileDocuments,getPrimaryIntakeByUserId,
  getSecondaryIntakeByUserId, getWeeklyCaseManagementNotesByUserId, getParticipantClasses,
    getNewParticipantChecklistByUserId, getGoalSheetByUserId, getAdmissionCoverSheetByUserId
  } = props;

  const [editingContact, setEditingContact] = useState<CaseManagementContact>();

  const userForms = useMemo(() => {
    const forms = {
      primary: getPrimaryIntakeByUserId(user.id),
      secondary: getSecondaryIntakeByUserId(user.id),
      caseNotes: getWeeklyCaseManagementNotesByUserId(user.id),
      participantChecklist: getNewParticipantChecklistByUserId(user.id),
      coverSheet: getAdmissionCoverSheetByUserId(user.id),
      goalSheet: getGoalSheetByUserId(user.id)
    };
    return {
      ...forms,
      length: (
        Number(!!forms.primary) + Number(!!forms.secondary) + Number(!!forms.caseNotes) +
        Number(!!forms.participantChecklist) + Number(!!forms.coverSheet) + Number(!!forms.goalSheet))
    };
  }, [getPrimaryIntakeByUserId, getSecondaryIntakeByUserId, getWeeklyCaseManagementNotesByUserId,
      getNewParticipantChecklistByUserId, getAdmissionCoverSheetByUserId, getGoalSheetByUserId]
  );

  const phoneNumberFormatter = (phoneNumber: string) => {
    if(phoneNumber === '+1' || isNullOrWhitespace(phoneNumber)) return '';
    if (isValidPhoneNumber(phoneNumber)) return formatPhoneNumber(phoneNumber);
    if (isPossiblePhoneNumber(phoneNumber)) return formatPhoneNumberIntl(phoneNumber);
    return phoneNumber;
  };

  const mapResourceContacts = (contact: CaseManagementContact) => {
    const enumValues = getObjectValues(CaseManagementContactType);
    if(!contact.active) {
      return null;
    }
    if(contact.relationship === 0 ||
      contact.relationship === 1 ||
      contact.relationship === 2 ||
      contact.relationship === 3 ||
      contact.relationship === 4) {
      return (
        <tr key={contact.id}>
          <td style={{textTransform: 'capitalize', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setEditingContact(contact)}>
            {convertPascalToSentence(enumValues[contact.relationship as number].toString())}
          </td>
          <td>{contact.fullName}</td>
          <td>{phoneNumberFormatter(contact.phoneNumber)}</td>
          <td>{contact.address} {contact.city} {contact.state}, {contact.zipCode}</td>
          <td>{contact.email}</td>
          <td>{contact.information}</td>
        </tr>
      );
    }
  };

  const mapSafeContacts = (contact: CaseManagementContact) => {
    const enumValues = getObjectValues(CaseManagementContactType);
    if(!contact.active || !contact.safeContact) {
      return null;
    }

    return (
      <tr key={contact.id}>
        <td style={{textTransform: 'capitalize', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setEditingContact(contact)}>
          {convertPascalToSentence(enumValues[contact.relationship as number].toString())}
        </td>
        <td>{contact.fullName}</td>
        <td>{phoneNumberFormatter(contact.phoneNumber)}</td>
        <td>{contact.address} {contact.city} {contact.state}, {contact.zipCode}</td>
        <td>{contact.email}</td>
        <td>{contact.information}</td>
      </tr>
    );
  };

  const renderContacts = () => (
    <div>
      <table style={{marginTop: '2rem'}} className={styles['case-management-summary-table']} key={'resource-contacts'}>
        <thead>
          <tr>
            <th>Resource Contacts</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Email</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody style={{gap: '2rem'}}>
          {getWeeklyCaseManagementNotesByUserId(user.id)?.contacts.map((contact) => {
            return mapResourceContacts(contact);
          })}
          <tr>
            <td style={{textDecoration: 'underline', cursor: 'pointer'}}
                onClick={() => setEditingContact(makeCaseManagementContactModal())}>Add Contact</td>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
          </tr>
        </tbody>
      </table>

      <table style={{marginTop: '2rem'}} className={styles['case-management-summary-table']} key={'safe-contacts'}>
        <thead>
          <tr>
            <th>Safe Contacts</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Address</th>
            <th>Email</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody style={{gap: '2rem'}}>
          {getWeeklyCaseManagementNotesByUserId(user.id)?.contacts.map((contact) => {
            return mapSafeContacts(contact);
          })}
          <tr>
            <td style={{textDecoration: 'underline', cursor: 'pointer'}}
                onClick={() => setEditingContact(makeCaseManagementContactModal(true))}>Add Contact</td>
            <td/>
            <td/>
            <td/>
            <td/>
            <td/>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const checkAttendance = (date: Date, attendance: boolean) => {
    const now = new Date();
    if(now < date) {
      return 'Upcoming';
    } else {
      if(attendance) {
        return 'Attended';
      }else {
        return 'Absent';
      }
    }
  };


  const mapClasses = (participantClass: ParticipantClass) => {
    const classAttendance = participantClass.participantClassAssignments.find(pca => pca.participantId === user.id);
    if(classAttendance !== undefined) {
      return (
        <tr key={participantClass.id}>
          <td>{participantClass.name}</td>
          <td>{parseISO(participantClass.startDateTime).toLocaleDateString()}</td>
          <td>{checkAttendance(parseISO(participantClass.startDateTime), classAttendance.attendance)}</td>
        </tr>
      );
    }
    return null;
  };

  const renderClasses = () => (
    <Card className={styles['info-card']} style={{marginTop: '2rem'}}>
      <Card.Body className={styles['info-card-body']}>
        <h5>Classes</h5>
        <table className={styles['case-management-summary-table']}>
          <thead>
            <tr>
              <th>Class</th>
              <th>Date</th>
              <th>Attended</th>
            </tr>
          </thead>
          <tbody style={{gap: '2rem'}}>
            {getParticipantClasses?.map((participantClass) => {
              return mapClasses(participantClass);
            })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );


  const mapMedications = (medication: CaseManagementMedication) => {
    return (
      <tr key={medication.id}>
        <td>{medication.name}</td>
        <td>{medication.strength}</td>
        <td>{medication.dosage}</td>
        <td>{medication.amount}</td>
        <td>{medication.use}</td>
        <td>{parseISO(medication.datePrescribed).toLocaleDateString()}</td>
        <td>{parseISO(medication.expirationDate).toLocaleDateString()}</td>
        <td>{parseISO(medication.refillDate).toLocaleDateString()}</td>
      </tr>
    );
  };

  const renderMedications = () => (
    <Card className={styles['info-card']} style={{marginTop: '2rem'}}>
      <Card.Body className={styles['info-card-body']}>
        <h5>Medications</h5>
        <table className={styles['case-management-summary-table']}>
          <thead>
          <tr>
            <th>Name</th>
            <th>Strength</th>
            <th>Dosage</th>
            <th>Amount</th>
            <th>Usage</th>
            <th>Date Prescribed</th>
            <th>Amount Prescribed</th>
            <th>Refill Date</th>
          </tr>
          </thead>
          <tbody style={{gap: '2rem'}}>
            {getWeeklyCaseManagementNotesByUserId(user.id)?.medications.map((medication) => {
              return mapMedications(medication);
            })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );

  const renderDocumentsInfo = () => (
    <Col>
      <StyledInformationLabel label={'Documents'} data={caseFileDocuments.length}/>
      <StyledInformationLabel
        label={'Awaiting signature'}
        data={caseFileDocuments.filter(d => d.status === CaseFileDocumentStatus.AwaitingSignature).length}
      />
      <StyledInformationLabel label={'Completed Forms'} data={userForms.length}/>
      <Row>
        <Col style={{padding: '0'}}>
          <CompletedFormLabel
            label={'Primary Intake'}
            complete={!!userForms.primary}
            onSelectForm={() => onFormSelect('primaryIntake')}
            completeText={userForms.primary?.parProgramQuestions?.arrestedForSex ? 'Complete, positive PAR' : 'Complete, negative PAR'}
          />
          <CompletedFormLabel
            label={'Secondary Intake'}
            complete={!!userForms.secondary}
            onSelectForm={() => onFormSelect('secondaryIntake')}
          />
          <CompletedFormLabel
            label={'Case Management Notes'}
            complete={!!userForms.caseNotes}
            completeText={'Edited'}
            onSelectForm={() => onFormSelect('caseNotes')}
          />
          <CompletedFormLabel
            label={'New Participant Checklist'}
            complete={!!userForms.participantChecklist}
            onSelectForm={() => onFormSelect('newParticipantChecklist')}
          />
          <CompletedFormLabel
            label={'Goal Sheet'}
            complete={!!userForms.goalSheet}
            completeText={'Edited'}
            onSelectForm={() => onFormSelect('goalSheet')}
          />
        </Col>
      </Row>
    </Col>
  );

  const renderPersonalInfo = () => {

    const getAge = () => {
      const age = calculateAge(parseISO(user.birthDate));
      return (isNaN(age) || age < 0) ? '' : age;
    };
    const getFullAddress = () => {
      const address = whitespaceIfNullOrNotApplicable(user.address);
      const addressLineTwo = whitespaceIfNullOrNotApplicable(user.addressLineTwo);
      let city = whitespaceIfNullOrNotApplicable(user.city);
      let state = whitespaceIfNullOrNotApplicable(user.state);
      const zip = whitespaceIfNullOrNotApplicable(user.zip);
      if (!(isNullOrWhitespace(city) || isNullOrWhitespace(state)))
        city = `${city},`;
      if (!(isNullOrWhitespace(state) || isNullOrWhitespace(zip)))
        state = `${state},`;
      return `${address} ${addressLineTwo} ${city} ${state} ${zip}`;
    };

    return (
      <Card className={styles['info-card']}>
        <Card.Body className={styles['info-card-body']}>
          <h5>Personal</h5>
          <Row>
            <Col>
              <StyledInformationLabel label={'Full Name'} data={user.name} labelSize={4}/>
              <StyledInformationLabel label={'Address'} data={getFullAddress()} labelSize={4}/>
              <StyledInformationLabel label={'Primary Phone'} data={phoneNumberFormatter(user.primaryPhone)} labelSize={4} style={{flexWrap: 'nowrap'}}/>
              <StyledInformationLabel label={'Secondary Phone'} data={phoneNumberFormatter(user.secondaryPhone)} labelSize={4}/>
              <StyledInformationLabel
                label={'DOB'}
                data={user.birthDate ? parseISO(user.birthDate).toLocaleDateString() : user.birthDate}
                labelSize={4}
              />
              <StyledInformationLabel
                label={'Age'}
                data={getAge()}
                labelSize={4}
              />
              <StyledInformationLabel
                label={'Client Since'}
                data={user.createdAt ? parseISO(user.createdAt).toLocaleDateString() : user.createdAt}
                labelSize={4}
              />
              <StyledInformationLabel
                label={'Email'}
                data={user.email}
                labelSize={4}
              />
            </Col>
            {renderDocumentsInfo()}
          </Row>

          {renderContacts()}
        </Card.Body>
      </Card>
    );
  };



  const renderContactModal = () => (
    <ContactsFormModal
      contact={editingContact}
      editable={true}
      onCancel={() => setEditingContact(undefined)}
      userId={user.id}
      onSubmit={() => {
        setEditingContact(undefined);
        props.reloadCaseFileHistory();
      }}
    />
  );


  return (
    <div className={styles['container']}>
      {renderPersonalInfo()}
      {renderClasses()}
      {renderMedications()}
      {editingContact ? renderContactModal() : null}
    </div>
  );
};

const StyledInformationLabel = (props: InformationLabelProps) => <InformationLabel {...props} style={{flexWrap: 'nowrap'}}/>;

const CompletedFormLabel =
  (props: {label: string; complete: boolean; onSelectForm: (form: PreMadeFormModalType) => void; completeText?: string; incompleteText?: string}) =>
    <StyledInformationLabel
      label={props.label}
      data={props.complete ? props.completeText ?? 'Complete' : props.incompleteText ?? 'Incomplete'}
      style={{fontSize: '0.9rem'}}
      linkProps={{label: true, data: false, onClick: props.onSelectForm}}
    />;

const mapStateToProps = (state: WebState) => ({
  caseFileDocuments: caseFileDocumentStore.selectors.getAsArray(state),
  getPrimaryIntakeByUserId: primaryIntakeStore.selectors.getByUserId(state),
  getSecondaryIntakeByUserId: secondaryIntakeStore.selectors.getByUserId(state),
  getWeeklyCaseManagementNotesByUserId: weeklyCaseManagementNotesStore.selectors.getByUserId(state),
  getParticipantClasses: participantClassStore.selectors.getAsArray(state),
  getNewParticipantChecklistByUserId: newParticipantChecklistStore.selectors.getByUserId(state),
  getAdmissionCoverSheetByUserId: admissionCoverSheetStore.selectors.getByUserId(state),
  getGoalSheetByUserId: goalSheetStore.selectors.getByUserId(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(CaseFileSummary);
