import React, {useState} from 'react';
import styles from './CaseFilePreMadeFormsGrid.module.scss';
import gridStyles from '../../../GridStyles.module.scss';
import {Navigate, useSearchParams} from 'react-router-dom';
import IconButton from '../../../../components/util/widgets/IconButton/IconButton';
import BootstrapTable, {BootstrapTableProps, ColumnDescription} from 'react-bootstrap-table-next';
import {Button} from 'react-bootstrap';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../redux/core/types/WebState';
import {connect} from 'react-redux';
// @ts-ignore
import ToolkitProvider, {ColumnToggle} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {ToggleListProps} from 'react-bootstrap-table2-toolkit';
import {
  PrimaryIntake,
  primaryIntakeStore
} from '../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {
  admissionCoverSheetTableData,
  goalSheetTableData,
  newParticipantChecklistTableData,
  PreMadeFormModalType,
  PreMadeFormTableData,
  primaryIntakeTableData, secondaryIntakeTableData, weeklyCaseNotesTableData
} from './index';
import {makePrimaryIntakeForm, makeSecondaryIntake} from '../../../../redux/web/factory/forms/intakeForms';
import {
  NewParticipantChecklist,
  newParticipantChecklistStore
} from '../../../../redux/web/entities/forms/newParticipantChecklist';
import {makeNewParticipantChecklist} from '../../../../redux/web/factory/forms/newParticipantChecklist';
import WeeklyCaseManagementNotesModal
  from './forms/WeeklyCaseManagementNotesModal/WeeklyCaseManagementNotesModal';
import NewParticipantChecklistModal
  from './forms/NewParticipantChecklistModal/NewParticipantChecklistModal';
import AdmissionCoverSheetModal
  from './forms/AdmissionCoverSheetModal/AdmissionCoverSheetModal';
import GoalSheetModal
  from './forms/GoalSheetModal/GoalSheetModal';
import PrimaryIntakeModal
  from '../../../Configuration/UserManagement/components/UserGrid/PrimaryIntakeModal/PrimaryIntakeModal';
import {
  WeeklyCaseManagementNotes,
  weeklyCaseManagementNotesStore
} from '../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {AdmissionCoverSheet, admissionCoverSheetStore} from '../../../../redux/web/entities/forms/admissionCoverSheet';
import {GoalSheet, goalSheetStore} from '../../../../redux/web/entities/forms/goalSheet';
import {makeWeeklyCaseManagementNotes} from '../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import {makeGoalSheet} from '../../../../redux/web/factory/forms/goalSheet';
import {makeAdmissionCoverSheet} from '../../../../redux/web/factory/forms/admissionCoverSheet';
import SecondaryIntakeModal from './forms/SecondaryIntakeModal/SecondaryIntakeModal';
import {secondaryIntakeStore} from '../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {mapRoleToProps} from '../../../../redux/web/entities/user';
import {localTz, localTzFriendlyFormat, SetStateFunctional} from '../../../../util';
import {format, utcToZonedTime} from 'date-fns-tz';
import {parseISO} from 'date-fns';

type Props = {
  userId: string;
  currentModal: PreMadeFormModalType;
  setCurrentModal: SetStateFunctional<PreMadeFormModalType>;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function CaseFilePreMadeFormsGrid(props: Props) {
  const {userId, currentModal, setCurrentModal, getPrimaryIntakeByUserId, getSecondaryIntakeByUserId, getWeeklyCaseManagementNotesByUserId,
    getAdmissionCoverSheetByUserId, getGoalSheetByUserId, getNewParticipantChecklistByUserId, userRole} = props;

  const [showColumnToggleFilters, setShowColumnToggleFilters] = useState(false);
  const getFieldName = (name: keyof PreMadeFormTableData) => name;

  const tableData = (): PreMadeFormTableData[] => {
    return [
      primaryIntakeTableData(getPrimaryIntakeByUserId(userId) ?? makePrimaryIntakeForm() as PrimaryIntake),
      secondaryIntakeTableData(getSecondaryIntakeByUserId(userId) ?? makeSecondaryIntake(userId)),
      weeklyCaseNotesTableData(getWeeklyCaseManagementNotesByUserId(userId) ?? makeWeeklyCaseManagementNotes(userId) as WeeklyCaseManagementNotes),
      newParticipantChecklistTableData(getNewParticipantChecklistByUserId(userId) ?? makeNewParticipantChecklist(userId) as NewParticipantChecklist),
      admissionCoverSheetTableData(getAdmissionCoverSheetByUserId(userId) ?? makeAdmissionCoverSheet(userId) as AdmissionCoverSheet),
      goalSheetTableData(getGoalSheetByUserId(userId) ?? makeGoalSheet(userId) as GoalSheet)
    ];
  };

  const disableForms = userRole !== 'Administrator';

  const renderCurrentModal = () => {
    if (currentModal === 'primaryIntake')
      return (
        <PrimaryIntakeModal
          onSubmit={() => setCurrentModal('none')}
          onCancel={() => setCurrentModal('none')}
          existingIntake={getPrimaryIntakeByUserId(userId)}
          disabled={disableForms}
          userId={userId}
        />
      );
    if (currentModal === 'caseNotes')
      return (
        <WeeklyCaseManagementNotesModal
          userId={userId}
          editable={true}
          existingCaseNotes={getWeeklyCaseManagementNotesByUserId(userId)}
          onSubmit={() => setCurrentModal('none')}
          onCancel={() => {
            setCurrentModal('none');
          }}
          disabled={disableForms}
        />
      );
    if (currentModal === 'newParticipantChecklist')
      return (
        <NewParticipantChecklistModal
          userId={userId}
          editable={true}
          existingChecklist={getNewParticipantChecklistByUserId(userId)}
          existingPrimary={getPrimaryIntakeByUserId(userId)}
          onSubmit={() => setCurrentModal('none')}
          onCancel={() => {
            setCurrentModal('none');
          }}
          disabled={disableForms}
        />
      );
    if (currentModal === 'admissionCoverSheet')
      return (
        <AdmissionCoverSheetModal
          userId={userId}
          editable={true}
          existingCoverSheet={getAdmissionCoverSheetByUserId(userId)}
          existingPrimary={getPrimaryIntakeByUserId(userId)}
          existingSecondary={getSecondaryIntakeByUserId(userId)}
          onSubmit={() => setCurrentModal('none')}
          onCancel={() => {
            setCurrentModal('none');
          }}
          disabled={disableForms}
        />
      );
    if (currentModal === 'goalSheet')
      return (
        <GoalSheetModal
          userId={userId}
          editable={true}
          existingGoalSheet={getGoalSheetByUserId(userId)}
          onSubmit={() => setCurrentModal('none')}
          onCancel={() => {
            setCurrentModal('none');
          }}
          disabled={disableForms}
        />
      );
    if (currentModal === 'secondaryIntake')
      return (
        <SecondaryIntakeModal
          userId={userId}
          existingSecondaryIntake={getSecondaryIntakeByUserId(userId)}
          primaryIntake={getPrimaryIntakeByUserId(userId)}
          existingAdmissionCoverSheet={getAdmissionCoverSheetByUserId(userId)}
          onSubmit={() => setCurrentModal('none')}
          onCancel={() => setCurrentModal('none')}
          disabled={disableForms}
        />
      );
  };

  const actionsFormatter = (cell: any, form: PreMadeFormTableData) => {
    return (
      <div className={gridStyles['table-actions-wrapper']}>
        <div>
          <IconButton
            icon='edit'
            size={'2x'}
            styles={{marginLeft: '.5rem'}}
            iconToolTipText={disableForms ? 'Eye' : 'Edit'}
            onClick={() => {
              setCurrentModal(form.formType);
            }}
            color={'#005A9C'}
          />
        </div>
      </div>
    );
  };

  const onColumnToggle = (col: ColumnDescription<any>) => {
    const updatedTableColumns = columns.map((value) => value.dataField === col.dataField ? {...value, hidden: !col.hidden} : value);
    const visibleRecordInfo = updatedTableColumns.filter((value) => (!value.hidden || false) && !col.isDummyField).length > 1;
    setColumns(() => visibleRecordInfo ? updatedTableColumns : columns);
  };

  const updatedOnFormatter = (cell: any, form: PreMadeFormTableData) => {
    const formatTime = (isoDate: string) => format(utcToZonedTime(parseISO(isoDate), localTz as any as string), 'p', {timeZone: localTz as any as string});
    const formatDate = (isoDate: string) => format(parseISO(isoDate), 'MM/dd/yyyy');
    return form.updatedOn ? `${formatDate(form.updatedOn)} ${formatTime(form.updatedOn)}` : '';
  };

  const FilteredToggleList = ({toggles}: {toggles: boolean[]}) => (
    <div className={gridStyles['table-actions-wrapper']}>
      {
        columns
          .filter(col => !(col.isDummyField || col.dataField === (getFieldName('id'))))
          .map(column => ({...column, toggle: toggles[column.dataField]}))
          .map(column => (
            <Button
              variant={column.toggle ? 'success' : 'secondary'}
              key={ column.dataField }
              onClick={ () => onColumnToggle(column) }
            >
              {!column.text.includes('Updated On') ? column.text : 'Updated On'}
            </Button>
          ))
      }
    </div>
  );

  const [columns, setColumns] = useState<ColumnDescription[]>([
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'updatedOn',
      text: `Updated On (${localTzFriendlyFormat})`,
      sort: true,
      formatter: updatedOnFormatter
    },
    {
      dataField: 'actionsColumn',
      text: 'Actions',
      isDummyField: true,
      headerStyle: () => {
        return { width: '3rem'};
      },
      style: () => {
        return { width: '3rem'};
      },
      formatter: actionsFormatter
    }
  ]);

  return (
    <>
      <ToolkitProvider
        keyField='id'
        data={tableData()}
        columns={columns}
        search={{
          searchFormatted: true
        }}
        columnToggle={true}
      >
        {(tableProps:
            { baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<PreMadeFormTableData, number>> & Readonly<BootstrapTableProps>;
              columnToggleProps: ToggleListProps;}) => (
          <>
            <div className={styles['button-groups-container']}>
              <div className={styles['button-group']}>
                <Button style={{backgroundColor: '#005A9C'}} onClick={() => setShowColumnToggleFilters(!showColumnToggleFilters)}>
                  {showColumnToggleFilters ? 'Hide' : 'Show'} Column Filters
                </Button>
              </div>
              {showColumnToggleFilters ?
                <div className={styles['button-group']}>
                  <FilteredToggleList {...tableProps.columnToggleProps}/>
                </div>
                : null
              }
            </div>
            <BootstrapTable
              wrapperClasses={styles['responsive-table-wrapper']}
              classes={styles['table-auto']}
              rowStyle={{height: '100%'}}
              {...tableProps.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
      {renderCurrentModal()}
    </>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getPrimaryIntakeByUserId: primaryIntakeStore.selectors.getByUserId(state),
  getSecondaryIntakeByUserId: secondaryIntakeStore.selectors.getByUserId(state),
  getWeeklyCaseManagementNotesByUserId: weeklyCaseManagementNotesStore.selectors.getByUserId(state),
  getNewParticipantChecklistByUserId: newParticipantChecklistStore.selectors.getByUserId(state),
  getAdmissionCoverSheetByUserId: admissionCoverSheetStore.selectors.getByUserId(state),
  getGoalSheetByUserId: goalSheetStore.selectors.getByUserId(state),
  userRole: mapRoleToProps(state).roleName
});
export default connect(mapStateToProps, mapDispatchToProps)(CaseFilePreMadeFormsGrid);
