import React, {CSSProperties, PropsWithChildren, ReactNode} from 'react';
import {Card, Col} from 'react-bootstrap';
import styles from './DashboardCard.module.scss';

type DashboardCardProps = PropsWithChildren<{
  title: string | ReactNode;
  cardStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
}>;

const DashboardCard = (props: DashboardCardProps) => (
  <Col>
    <Card className={styles['dashboard-card']} style={props.cardStyle}>
      <Card.Title className={styles['dashboard-card-title']} style={props.titleStyle}>
        {props.title}
      </Card.Title>
      <Card.Body className={styles['dashboard-card-body']} style={props.bodyStyle}>
        {props.children}
      </Card.Body>
    </Card>
  </Col>
);

export default DashboardCard;
