import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {WebState} from '../../../redux/core/types/WebState';
import {bindActionCreators, Dispatch} from 'redux';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {handleAxiosError} from '../../../redux/util/http';
import {loadDocumentManagementData} from '../../../redux/web/stateResponses/stateManagement';
import UserGrid from './components/DocumentGrid/DocumentGrid';
import {AxiosError} from 'axios';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function DocumentManagement(props: Props) {
  const {actions: {loadDocumentData}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    async function loadData() {
      try {
        await loadDocumentData();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load users'}));
      }
      setLoading(false);
    }
    loadData();
  });

  return (
    <div>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          <UserGrid/>
      )}
    </div>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({actions: bindActionCreators({loadDocumentData: loadDocumentManagementData}, dispatch)});
const mapStateToProps = (state: WebState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentManagement);
