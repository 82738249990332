import {CSSProperties} from 'react';
import {nodeListToArray} from './util';
import WhiteLogo from './assets/images/Breaking-the-Chains-White.png';
import BlackLogo from './assets/images/Breaking-the-Chains-Black.png';
import NoProfilePicture from './assets/images/no-profile-picture.png';
import RbswLogo from './assets/images/rbsw-logo.png';
const darkMode =  process.env?.REACT_APP_DARK_MODE === 'true';

const choreChartDayColors = {
  sunday: '#9b5fe0',
  monday: '#16a4d8',
  tuesday: '#60dbe8',
  wednesday: '#8bd346',
  thursday: '#efdf48',
  friday: '#f9a52c',
  saturday: '#d64e12'
};

const colors = {
  primaryTextColor: darkMode ? '#fff' : 'black',
  primary: '#005A9C',
  secondary: '#6c757d',
  gray400: '#ced4da',
  darkGray: '#6c757d',
  background: darkMode ? '#222222' : '#fff',
  offsetBackground: darkMode ? '#202020' : '#f9f9f9',
  largerOffsetBackground: darkMode ? '#353535' : '#f1f1f1',
  warning: '#f8c839',
  success: '#5fb173',
  lighterGreen: '#defade',
  danger: '#c45b65',
  ...choreChartDayColors
} as const;

export const AppTheme = {
  colors: colors,
  styles: {
    offsetBackground: !darkMode ? { background: colors.offsetBackground} : {} as CSSProperties,
    background: !darkMode ? {background: colors.background} : {} as CSSProperties,
    largerOffsetBackground: {background: colors.largerOffsetBackground} as CSSProperties,
    primaryTextColor: {color: colors.primaryTextColor} as CSSProperties
  },
  darkMode: darkMode
};

export const getWhiteLogoLink = () => WhiteLogo;
export const getBlackLogoLink = () => BlackLogo;
export const noProfilePicture = () => NoProfilePicture;
export const rbswLogo = () => RbswLogo;

function createLink(href: string) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  return link;
}

export function configureTheme() {
  if (!darkMode) {
    return null;
  }
  document.body.classList.add('dark-mode');
  document.body.dataset['theme'] = 'dark';

  const link = createLink(process.env.PUBLIC_URL + '/bootstrap/css/dark-mode-bootstrap.min.css');
  document.head.appendChild(link);
  const stdLink = (nodeListToArray(document.head.children) as HTMLLinkElement[]).filter(c => (c.href ?? '').indexOf('css/bootstrap.min.css') !== -1).pop()!;
  document.head.removeChild(stdLink);
}
