import {api} from './index';
import {ChoreChartState} from '../redux/web/stateResponses/choreChartData';
import {ChoreChartWeek} from '../redux/web/entities/choreChartWeek';
import {ChecklistWeek} from '../redux/web/entities/checklistWeek';
import {ChecklistState} from '../redux/web/stateResponses/staffChecklistData';

export async function getChoreChartState(isoDate: string) {
  return (await api.get<ChoreChartState>(`/ChoreChart/${isoDate}`)).data;
}

export async function upsertChoreChartWeek(choreChartWeek: ChoreChartWeek){
  return (await api.post<ChoreChartWeek>(`/ChoreChart`, choreChartWeek)).data;
}

export async function updateChoreCompletion(id: string) {
  return (await api.post<ChoreChartWeek>(`/ChoreChart/chore/${id}`)).data;
}

export async function getChecklistState(isoDate: string) {
  return (await api.get<ChecklistState>(`/Checklist/${isoDate}`)).data;
}

export async function upsertChecklistWeek(checklistWeek: ChecklistWeek){
  return (await api.post<ChecklistWeek>(`/Checklist`, checklistWeek)).data;
}

export async function updateItemCompletion(id: string) {
  return (await api.post<ChecklistWeek>(`/Checklist/item/${id}`)).data;
}
