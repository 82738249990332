import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {CommonDispatch} from '../index';
import {deleteDocument, getDocumentDataByid, upsertDocument} from '../../../api/documentManagementApi';
import {createAction} from 'typesafe-actions';

export interface BtcDocument {
  id: string;
  name: string;
  data: string;
  currentSnapshotId: number;
}

const actions = createStandardActions(placeholder<BtcDocument>(), 'BTC_DOCUMENT/SET', 'BTC_DOCUMENT/SAVE');
const deleteDocumentAction = createAction('BTC_DOCUMENT/DELETE')<string>();
const selectors = createStandardSelectors(placeholder<BtcDocument>(), s => getEntities(s).btcDocuments);
export type BtcDocumentActions = GetActions<typeof actions> | ReturnType<typeof deleteDocumentAction>;
export const btcDocuments = combineReducers({
  items: standardItemsReducer<BtcDocument, BtcDocumentActions>(actions)
    .handleAction(deleteDocumentAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload as any];
      return newState;
    })
});
export const btcDocumentStore = readonly({
  selectors: {
    ...selectors
  },
  actions: {
    ...actions,
    upsert: (document: BtcDocument) => async (dispatch: CommonDispatch) => {
      const response = await upsertDocument(document);
      dispatch(btcDocumentStore.actions.save(response));
      return response;
    },
    getDocumentDataById: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await getDocumentDataByid(id);
      dispatch(btcDocumentStore.actions.save(response));
      return response;
    },
    delete: (document: BtcDocument) => async (dispatch: CommonDispatch) => {
      const response = await deleteDocument(document);
      dispatch(deleteDocumentAction(response));
    }
  }
});
