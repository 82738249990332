import {bindActionCreators, Dispatch} from 'redux';
import {Chore, ChoreChartRow, ChoreChartWeek, choreChartWeekStore} from '../../../redux/web/entities/choreChartWeek';
import {WebState} from '../../../redux/core/types/WebState';
import {userStore} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import styles from './ChoreChartCalendar.module.scss';
import {AppTheme} from '../../../appTheme';
import Select from 'react-select';
import {convertObjectToOption, convertToDropDownOptions} from '../../../redux/util';
import Input from '../../../components/util/form-components/formik-inputs/Input/Input';
import {makeChoreChartRow} from '../../../redux/web/factory';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import {ArrayHelpers} from 'formik';
import React, {forwardRef, useMemo, useState} from 'react';
import {ConfirmationDialog} from '../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../redux/util/http';
import {addDays, getDate, getDay, getWeek, getYear, startOfWeek} from 'date-fns';
import {now} from '../../../util';
import {ChecklistDay} from '../../StaffChecklist/components/StaffChecklistCalendarController';
import {format} from 'date-fns-tz';

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface EditProps {
  setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void;
  helpers: ArrayHelpers;
}

type Props = {
  currentDate: Date;
  editProps?: EditProps;
  showParticipantProps?: boolean;
  renderedValues: ChoreChartWeek;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ChoreChartCalendar = forwardRef((props: Props, ref) => {
  const {currentDate, editProps, showParticipantProps = false, renderedValues, setErrorMessage,
    getUserById, participants, currentUser, actions: {completeChore}} = props;

  const [participantDialogueProps, setParticipantDialogueProps] = useState<{choreId: string; rowId: string} | null>(null);
  const getWeekFieldName = (name: keyof ChoreChartWeek) => name;
  const getRowFieldName = (name: keyof ChoreChartRow) => name;
  const getChoreFieldName = (name: keyof Chore) => name;
  const generateRowFieldName = (index: number) => `${getWeekFieldName('rows')}[${index}]`;
  const generateChoreFieldName = (rowFieldName: string, index: number) => `${rowFieldName}.${getRowFieldName('chores')}[${index}]`;
  const nowYear = getYear(now);
  const nowWeek = getWeek(now);
  const nowDay = getDay(now);

  const daysOfWeek = useMemo(() => {
    const days: ChecklistDay[] = [];
    const startDate = startOfWeek(currentDate);
    for (let i = 0; i < 7; i++) {
      const day = addDays(startDate, i);
      days.push({date: day, formattedDate: format(day, 'EEE'), dayOfTheWeek: getDay(day), dayOfTheMonth: getDate(day)});
    }
    return days;
  }, [currentDate]);

  const renderDateHeader = () => {
    return (
      <Row style={{fontSize: '1.4rem', flexWrap: 'nowrap'}}>
        <div className={styles['chore-chart-day']} style={{backgroundColor: '#bfbccb', border: 'none'}}>
          <Row>
            For the week of:
          </Row>
          <Row style={{fontWeight: 'bold'}}>
            {format(startOfWeek(currentDate), 'MM/dd/yyyy')}
          </Row>
        </div>
        {daysOfWeek.map((day) =>
          <Col
            key={`header_${day.dayOfTheMonth}`}
            className={styles['chore-chart-day']}
            style={{backgroundColor: '#bbe5ed', border: 'none'}}
          >
            <Row style={{fontWeight: 'bold'}}>
              {day.formattedDate}
            </Row>
            <Row style={{paddingTop: '5px'}}>
              {day.dayOfTheMonth}
            </Row>
          </Col>
        )}
        {editProps ? <div className={styles['chore-chart-action']}/> : null}
      </Row>
    );
  };

  const renderParticipantConfirmationDialogue = () => {
    if (participantDialogueProps !== null) {
      const row = renderedValues.rows.find(r => r.id === participantDialogueProps.rowId);
      const chore = row?.chores.find(c => c.id === participantDialogueProps.choreId);
      return (
        <ConfirmationDialog
          open={true}
          onAccept={async () => {
            try {
              completeChore(participantDialogueProps.choreId);
              setErrorMessage('');
            } catch (e: AxiosError | any) {
              setErrorMessage(handleAxiosError(e));
            }
            setParticipantDialogueProps(null);
          }}
          positiveVariant={'success'}
          positiveText={'Yes'}
          onDecline={async () => setParticipantDialogueProps(null)}
          negativeVariant={'danger'}
          negativeText={'No'}
          prompt={
          `Are you sure you would like to indicate the task "${row?.task}" has ${chore?.done ?  'not been completed' : 'been completed'} on
          ${chore ? weekdays[chore.dayOfTheWeek] : ''}?`}
        />
      );
    }
  };

  const renderChoreParticipant = (chore: Chore) => {
    const isCurrentUserChore = chore.userId === currentUser?.id;
    return (
      <Row style={{alignItems: 'center', flexWrap: 'nowrap', fontWeight: isCurrentUserChore ? 'bold' : 'inherit'}}>
        {getUserById(chore.userId)?.name}
        {isCurrentUserChore && renderedValues.year === nowYear && renderedValues.weekOfTheYear === nowWeek && chore.dayOfTheWeek === nowDay ?
          <div style={{marginLeft: '10px', marginRight: '5px'}}>
            <IconButton
              icon={chore.done ? 'cancel' : 'check'}
              color={chore.done ? 'indianred' : 'green'}
              size={'2x'}
              onClick={() => setParticipantDialogueProps({choreId: chore.id, rowId: chore.choreChartRowId})}
            />
          </div>
          : null}
      </Row>
    );
  };

  const renderChore = (chore: Chore, rowIndex: number, index: number) => {
    const fieldName = `${generateChoreFieldName(generateRowFieldName(rowIndex), index)}.${getChoreFieldName('userId')}`;
    return (
      <Col
        key={index}
        className={styles['chore-chart-day']}
        style={chore && chore.done ? {backgroundColor: AppTheme.colors.lighterGreen} : {}}
      >
        {editProps ?
          <Select
            name={fieldName}
            options={convertToDropDownOptions(participants)}
            defaultValue={convertObjectToOption(getUserById(chore.userId) ?? {value: '', label: ''})}
            onChange={(option) => editProps.setFieldValue(fieldName, option?.value ?? '')}
            isClearable={!!chore.userId}
            className={styles['chore-chart-user']}
            styles={{ menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible' }) }}
            menuPortalTarget={document.querySelector('body')}
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
          />
          : showParticipantProps ? renderChoreParticipant(chore) : getUserById(chore.userId)?.name}
      </Col>
    );
  };

  const renderChoreRow = (row: ChoreChartRow, rowIndex: number) => (
    <Row key={row.taskNumber} style={{flexWrap: 'nowrap'}}>
      <>
        <div className={styles['chore-chart-day']} style={{fontSize: '1.4rem'}}>
          {editProps ? <Input name={`${generateRowFieldName(rowIndex)}.${getRowFieldName('task')}`} type={'textarea'}/> : row.task}
        </div>
        {[...row.chores]
          .sort((a, b) => a.dayOfTheWeek > b.dayOfTheWeek ? 1 : -1)
          .map((chore) => renderChore(chore, rowIndex, row.chores.indexOf(chore)))}
        {editProps ?
          <div className={styles['chore-chart-action']}>
            <IconButton
              icon={'trash-alt'}
              size={'2x'}
              iconToolTipText={'Delete row'}
              color={'red'}
              onClick={() => editProps.helpers.remove(rowIndex)}
            />
          </div>
          : null}
      </>
    </Row>
  );

  const renderBottomRow = () => {
    if (editProps)
      return (
        <Row style={{display: 'flex', flex: '1', justifyContent: 'center', paddingTop: '10px', alignItems: 'center'}}>
          <div style={{marginBottom: '5px', marginRight: '10px'}}>
            Add a task
          </div>
          <IconButton icon={'plus'} size={'2x'} onClick={() =>
            // Get one higher than the max task number, task no. ensures they stay in order for rendering
            editProps.helpers.push(makeChoreChartRow(Math.max(...renderedValues.rows.map(r => r.taskNumber), 1) + 1))}
          />
        </Row>
      );
    if (!renderedValues.rows.length)
      return (
        <Row style={{display: 'flex', flex: '1', justifyContent: 'center', paddingTop: '10px', alignItems: 'center'}}>
          No tasks available for this week.
        </Row>
      );
  };

  const renderFooter = () => {
    if (editProps)
      return (
        <Row style={{display: 'flex', flex: '1', justifyContent: 'center', paddingTop: '10px', alignItems: 'center'}}>
          <Input
            name={getWeekFieldName('reminder')}
            type={'textarea'}
            placeholder={'Place a custom message to be shown to participants when viewing this week'}
          />
        </Row>
      );
    return (
      <Row style={{display: 'flex', flex: '1', justifyContent: 'center', paddingTop: '10px', alignItems: 'center', fontWeight: 'bold'}}>
        {renderedValues.reminder}
      </Row>
    );
  };

  // sort row rendering by task number to keep order consistent when response is sent from backend
  return (
    <Col ref={ref} style={{padding: '0'}}>
      {renderDateHeader()}
      {[...renderedValues.rows]
        .sort((a, b) => a.taskNumber > b.taskNumber ? 1 : -1)
        .map((row, rowIndex) => renderChoreRow(row, rowIndex))}
      {renderBottomRow()}
      {renderParticipantConfirmationDialogue()}
      {renderFooter()}
    </Col>
  );
});

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    completeChore: choreChartWeekStore.actions.updateChoreCompletion
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state),
  participants: userStore.selectors.getParticipants(state),
  getChoreChartRow: choreChartWeekStore.selectors.getWeek(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ChoreChartCalendar);
