import React, {FormEvent, useState} from 'react';
import {Form, Formik, FormikErrors} from 'formik';
import {AxiosError} from 'axios';
import {getErrorResponseMessage, isBadRequest, isServerError, isUnknownError} from '../../../../../../redux/util/http';
import {ConfirmationDialog} from '../../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {Alert, Button, Col, Form as BSForm, Modal, Row, Spinner, Tab, Tabs} from 'react-bootstrap';
import styles from './SecondaryIntakeModal.module.scss';
import {
  makePrefilledSecondaryIntakeForm,
  makeSecondaryIntake
} from '../../../../../../redux/web/factory/forms/intakeForms';
import {StandardTopPaddedRow} from '../../../../../../components/util/form-components/standardLayout';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../../redux/core/types/WebState';
import {userStore} from '../../../../../../redux/web/entities/user';
import {connect} from 'react-redux';
import {
  TPrimaryIntakeUpsertForm
} from '../../../../../Configuration/UserManagement/components/UserGrid/PrimaryIntakeModal/PrimaryIntakeModal';
import {
  SecondaryIntake,
  secondaryIntakeStore
} from '../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  PersonalEmergencyInformationLifeIssuesForm
} from './sections/PersonalEmergencyInformationLifeIssuesForm';
import {CommentsAndConcernsForm} from './sections/CommentsAndConcernsForm';
import {CitizenshipAndIncomeInformationForm} from './sections/CitizenshipAndIncomeInformationForm';
import {SubstanceAbuseAndTraffickingInformationForm} from './sections/SubstanceAbuseAndTraffickingInformationForm';
import {FamilyAndMedicalHistoryForm} from './sections/FamilyAndMedicalHistoryForm';
import {EducationAndEmploymentForm} from './sections/EducationAndEmploymentForm';
import {CriminalAndHousingForm} from './sections/CriminalAndHousingForm';
import {SecondaryIntakeModalSchema} from './SecondaryIntakeModalSchema';
import {
  ESecondaryIntakeHomelessStatus
} from '../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntakeHelperModels';
import {PrimaryIntake} from '../../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {
  CompletionStateIndicator
} from '../../../../../../components/util/widgets/CompletionStateIndicator/CompletionStateIndicator';
import {AdmissionCoverSheet} from '../../../../../../redux/web/entities/forms/admissionCoverSheet';

type Props = {
  userId: string;
  existingSecondaryIntake?: SecondaryIntake;
  primaryIntake?: PrimaryIntake;
  existingAdmissionCoverSheet?: AdmissionCoverSheet;
  onSubmit: () => void;
  onCancel: () => void;
  disabled?: boolean;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function SecondaryIntakeModal(props: Props) {
  const {onSubmit, onCancel, userId, existingSecondaryIntake, primaryIntake, existingAdmissionCoverSheet, getUserById, disabled = true,
    actions: {upsertSecondaryIntake}} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentModal, setCurrentModal] = useState<'intake' | 'cancel'>('intake');
  const [errorMessage, setErrorMessage] = useState('');
  const [validateOnChangeAfterSubmit, setValidateOnChangeAfterSubmit] = useState(false);
  const username = getUserById(userId)?.name ?? 'undefined name';
  const getFieldName = (name: keyof SecondaryIntake) => name;
  const [tabKey, setTabKey] = useState<any>(getFieldName('personalEmergencyInformationAndLifeIssues'));
  const newFormMessage = existingAdmissionCoverSheet ? 'New (Values Imported from Primary Intake & Admission Cover Sheet Forms)' :
    'New (Values Imported from Primary Intake Form)';

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    values: SecondaryIntake,
    validate: (values: SecondaryIntake) => Promise<FormikErrors<SecondaryIntake>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    setValidateOnChangeAfterSubmit(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(values);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
      setErrorMessage('Errors were found in the form.');
    } else {
      try {
        const personalVals = values.personalEmergencyInformationAndLifeIssues;
        const familyMedVals = values.familyAndMedicalHistory;
        const criminalHouseVals = values.criminalAndHousing;
        // Remove lists from submission if they are disabled
        const submittedValues: SecondaryIntake = {
          ...values,
          personalEmergencyInformationAndLifeIssues: {
            ...personalVals,
            childrenNamesAndAges: personalVals.hasChildren ? personalVals.childrenNamesAndAges : [],
            arrestDatesAndDescriptions: personalVals.hasBeenArrested ? personalVals.arrestDatesAndDescriptions : []
          },
          familyAndMedicalHistory: {...familyMedVals, medications: familyMedVals.isOnMedications ? familyMedVals.medications : []},
          criminalAndHousing: {...criminalHouseVals, householdResidents:
              criminalHouseVals.homelessStatus === ESecondaryIntakeHomelessStatus.notHomeless ? criminalHouseVals.householdResidents : []}
        };
        await upsertSecondaryIntake(submittedValues);
        onSubmit();
      } catch (e: AxiosError | any) {
        if (isBadRequest(e)) {
          setErrorMessage(getErrorResponseMessage(e));
        } else if (isUnknownError(e) || isServerError(e)) {
          setErrorMessage(isUnknownError(e) ? 'Could not make a connection!' : 'A server error has occurred');
        }
      }
      setIsSubmitting(false);
    }
  };

  const renderCancelConfirmationDialog = () => (
    <ConfirmationDialog
      open={currentModal === 'cancel'}
      onAccept={async () => onCancel()}
      onDecline={async () => setCurrentModal('intake')}
      positiveVariant={'success'}
      negativeVariant={'danger'}
      positiveText={'Yes'}
      negativeText={'No'}
      prompt={`Are you sure you would like to cancel your edits to ${username}'s secondary intake form?`}
    />
  );

  const renderButtons = (setValues: (val: SecondaryIntake) => any) => {
    return (
      <>
        {process.env.NODE_ENV === 'development' &&
          <Button className={styles['close-button']} onClick={() => setValues(makePrefilledSecondaryIntakeForm(userId))}>Autofill values (dev only)</Button>}
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={() => disabled ? onCancel() : setCurrentModal('cancel')} variant={'danger'} className={styles['close-button']}>
            {disabled ? 'Close' : 'Cancel'}
          </Button>
        }
        {!disabled && !isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const tabTitleFormatter = (title: string, form: (keyof SecondaryIntake)[], errors: FormikErrors<TPrimaryIntakeUpsertForm>) => {
    const originalFormat = {tabClassName: styles['tab'], title: title};
    const errorFormat = {tabClassName: styles['tab-error'], title: `${title}*`};
    return Object.values(form).find(k => k in errors) && validateOnChangeAfterSubmit ? errorFormat : originalFormat;
  };

  // TODO possibly change disabled fields resetting value to onsubmitcapture so long descriptions are not lost if disabled accidentally / momentarily
  return (
    <>
      <Formik
        initialValues={makeSecondaryIntake(userId, existingSecondaryIntake, primaryIntake, existingAdmissionCoverSheet)}
        validationSchema={SecondaryIntakeModalSchema}
        onSubmit={() => undefined}>
        {({values, validateForm, handleSubmit, errors, setValues}) => (
          <Modal show={currentModal === 'intake'} centered={true} size={'xl'}>
            <Modal.Body>
              <Modal.Title>
                <Row>
                  Secondary Intake Form
                  <CompletionStateIndicator
                    existing={!!existingSecondaryIntake}
                    newText={newFormMessage}
                    existingText={'Editing Completed Form'}
                    styles={{alignSelf: 'flex-end', marginLeft: 'auto', marginRight: '15px'}}
                  />
                </Row>
              </Modal.Title>
              <Col style={{paddingTop: '10px'}}>
                <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                  <Tabs activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                    <Tab
                      eventKey={getFieldName('personalEmergencyInformationAndLifeIssues')}
                      {...tabTitleFormatter('Personal info', [getFieldName('personalEmergencyInformationAndLifeIssues')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <PersonalEmergencyInformationLifeIssuesForm/>
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('citizenshipAndIncomeInformation')}
                      {...tabTitleFormatter('Citizenship & Income', [getFieldName('citizenshipAndIncomeInformation')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CitizenshipAndIncomeInformationForm/>
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('substanceAbuseAndTraffickingInformation')}
                      {...tabTitleFormatter('Substance & Trafficking', [getFieldName('substanceAbuseAndTraffickingInformation')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <SubstanceAbuseAndTraffickingInformationForm/>
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('familyAndMedicalHistory')}
                      {...tabTitleFormatter('Family & Medical', [getFieldName('familyAndMedicalHistory')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <FamilyAndMedicalHistoryForm/>
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('educationAndEmployment')}
                      {...tabTitleFormatter('Education & Employment', [getFieldName('educationAndEmployment')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <EducationAndEmploymentForm/>
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('criminalAndHousing')}
                      {...tabTitleFormatter('Criminal & Housing', [getFieldName('criminalAndHousing')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CriminalAndHousingForm/>
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('commentsAndConcerns')}
                      {...tabTitleFormatter('Closing info', [getFieldName('commentsAndConcerns')], errors)}
                      className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <CommentsAndConcernsForm fieldPrefix={getFieldName('commentsAndConcerns')}/>
                      </fieldset>
                    </Tab>
                  </Tabs>
                  {errorMessage !== '' ?
                    <div style={{marginTop: '1rem'}}>
                      <Alert variant='danger'>{errorMessage}</Alert>
                    </div>
                    : null}
                  <StandardTopPaddedRow style={{paddingTop: '10px'}}>
                    <BSForm.Group className={styles['form-buttons']}>
                      {renderButtons(setValues)}
                    </BSForm.Group>
                  </StandardTopPaddedRow>
                </Form>
              </Col>
            </Modal.Body>
          </Modal>
        )}
      </Formik>
      {renderCancelConfirmationDialog()}
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertSecondaryIntake: secondaryIntakeStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(SecondaryIntakeModal);
