import styles from './UserInfo.module.scss';
import React from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/core/types/WebState';
import {userStore} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';
import IconButton from '../../util/widgets/IconButton/IconButton';
import ProfilePicture from '../../util/ProfilePicture/ProfilePicture';
type Props = {} & ReturnType<typeof mapStateToProps>;

const UserInfo = (props: Props) => {
  const {currentUser} = props;
  return (
    <div className={styles['links-profile-wrapper']}>
      <div className={styles['profile-logout']}>
        <ProfilePicture userId={currentUser?.id ?? ''} editable={false} style={{height: '3rem', width: '3rem', marginRight: '20px'}}/>
        <div style={{display: 'flex', flexDirection: 'column', marginRight: '1rem', alignItems: 'flex-end'}}>
          <Link to={RoutePaths.profilePaths.View}>
            <h5 style={{margin: 0}}>
              {currentUser?.name}
            </h5>
          </Link>
          <div style={{fontWeight: 'bold'}}>
            {currentUser?.role.roleName}
          </div>
        </div>
        <IconButton styles={{marginLeft: '1rem'}} icon='sign-out-alt' link={RoutePaths.logout} size={'2x'} color={'#005A9C'}/>
      </div>
    </div>
  );

};


const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
