import React from 'react';
import {
  SecondaryIntake as SI,
  SecondaryIntakeCitizenshipAndIncomeInformation as SICitizenshipIncome
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {useFormikContext} from 'formik';
import Input from '../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {
  StandardCheckboxInput,
  StandardInputColumn,
  StandardParentInputRow,
  StandardTopPaddedRow
} from '../../../../../../../components/util/form-components/standardLayout';
import {Row} from 'react-bootstrap';

// TODO add ethnicity
export const CitizenshipAndIncomeInformationForm = () => {
  const {values, setFieldValue} = useFormikContext<SI>();
  const formValues = values.citizenshipAndIncomeInformation;
  // return type not really needed, but it's cool you can do this
  const getFieldName = (name: keyof SICitizenshipIncome): `${keyof SI}.${keyof SICitizenshipIncome}` =>
    `citizenshipAndIncomeInformation.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Citizenship Status'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Where were you born?'} columnSize={8}>
            <Input name={getFieldName('bornAt')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Are you a U.S. citizen?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('usCitizen')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If no, please list place of citizenship:'} columnSize={8}>
            <Input name={getFieldName('placeOfCitizenship')} disabled={!formValues.usCitizen} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'How long have you been in the U.S.?'} columnSize={4}>
            <Input name={getFieldName('lengthOfUsLocation')} disabled={!formValues.usCitizen} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever used any other names?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasUsedOtherNames')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, please list:'} columnSize={8}>
            <Input name={getFieldName('otherNames')} type={'textarea'} disabled={!formValues.hasUsedOtherNames} valueOnDisabled={'N/A'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Income Information'}>
        <StandardTopPaddedRow>
        <StandardInputColumn label={'What source of income do you have?'} columnSize={12}>
          <Input name={getFieldName('sourceOfIncome')}/>
        </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Amount you gross annually?'} columnSize={6}>
            <Input name={getFieldName('grossAnnual')}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Amount you gross monthly?'} columnSize={6}>
            <Input name={getFieldName('grossMonth')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'What types of income do you have?'}>
            <Row>
              <StandardCheckboxInput name={getFieldName('afdc')} label={'AFDC'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('calFresh')} label={'Cal Fresh'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('generalRelief')} label={'General Relief'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('disability')} label={'Disability'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('ssi')} label={'SSI'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('ssa')} label={'SSA'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('ss')} label={'SS'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('veteransBenefits')} label={'Veterans Benefits'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('nativeAmerican')} label={'Native American'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('medicaid')} label={'Medicaid'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('medical')} label={'Medical'} style={{marginRight: '1.5rem'}}/>
              <StandardCheckboxInput name={getFieldName('earnedEmployment')} label={'Earned Employment'}/>
            </Row>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'What type of income do you have?'}>
            <Input
              name={getFieldName('employerName')}
              disabled={!formValues.earnedEmployment}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Please explain other forms of income:'}>
            <Input name={getFieldName('otherExplanation')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
            <StandardInputColumn label={`Social Worker's Name`} columnSize={6}>
              <Input name={getFieldName('socialWorkerName')}/>
            </StandardInputColumn>
            <StandardInputColumn label={`Phone Number`} columnSize={6}>
              <Input name={getFieldName('socialWorkerPhone')}/>
            </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Do you have a checking or savings account?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('checkingOrSavingsAccount')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Is there anything that would prevent you from opening a bank account?'} columnSize={8}>
            <StandardCheckboxInput name={getFieldName('isAnythingWouldPreventAccount')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If so, please explain:'}>
            <Input name={getFieldName('isAnythingWouldPreventAccountDescription')} type={'textarea'}
                   disabled={!formValues.isAnythingWouldPreventAccount} valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>

    </>
  );
};
