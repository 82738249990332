import {Card, Col, Container, Nav, Row, Tab} from 'react-bootstrap';
import styles from './CaseFile.module.scss';
import PageHeader from '../../components/layout/PageHeader/PageHeader';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import React, {useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../redux/util/http';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {useMount} from '../../hooks/useMount';
import CaseFileDocumentsGrid from './components/CaseFileDocumentsGrid/CaseFileDocumentsGrid';
import {loadCaseFileDocumentState, loadCaseFileState} from '../../redux/web/stateResponses/stateManagement';
import {useLocation} from 'react-router-dom';
import CaseFilePreMadeFormsGrid from './components/CaseFilePreMadeFormsGrid/CaseFilePreMadeFormsGrid';
import {loadUserFormData} from '../../redux/web/stateResponses/userFormsData';
import {userStore} from '../../redux/web/entities/user';
import {getCaseFileHistoryByUserId} from '../../api/caseFileManagementApi';
import {HistoryViewer} from '../../components/util/HistoryViewer/HistoryViewer';
import useMeasure from 'react-use-measure';
import ProfilePicture from '../../components/util/ProfilePicture/ProfilePicture';
import {WebState} from '../../redux/core/types/WebState';
import {toTitleCase} from '../../redux/util/string';
import {caseFileDocumentStore} from '../../redux/web/entities/caseFileDocument';
import {makeUser} from '../../redux/web/factory';
import CaseFileSummary from './components/CaseFileSummary/CaseFileSummary';
import {PreMadeFormModalType} from './components/CaseFilePreMadeFormsGrid';
import {loadParticipantClassManagementData} from '../../redux/web/stateResponses/participantClassManagement';
import {useCaseFileCache} from '../../hooks/useCaseFileCache';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function CaseFile(props: Props) {
  const {getUserById, currentUser, actions: {loadCaseFileStateForUser, loadPreMadeForms, loadParticipantClasses, loadUser, loadDocuments}} = props;
  const [caseFileErrorMessage, setCaseFileErrorMessage] = useState<string | undefined>('');
  const [preMadeFormsErrorMessage, setPreMadeFormsErrorMessage] = useState<string | undefined>('');
  const [loadingCaseFile, setLoadingCaseFile] = useState(true);
  const [loadingPreMadeForms, setLoadingPreMadeForms] = useState(true);
  const [preMadeFormsModal, setPreMadeFormsModal] = useState<PreMadeFormModalType>('none');
  const [tabKey, setTabKey] = useState<'summary' | 'documents' | 'preMadeForms'>('summary');
  const location = useLocation();
  const userId = location.pathname.split('/')[2];
  useCaseFileCache(currentUser?.id ?? '', userId);

  // container ref used for history size on show
  const [containerRef, containerSize] = useMeasure();
  const [caseFileHistoryLoadEvent, triggerReloadHistoryEvent] = useState(() => getCaseFileHistoryByUserId(userId));

  useMount(async () => {
    await loadUser(userId);
  });

  const user = getUserById(userId) ?? makeUser();

  useMount(async () => {
      try {
        await loadCaseFileStateForUser(userId);
        await loadParticipantClasses();
      } catch (e: AxiosError | any) {
        setCaseFileErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load Case File'}));
      }
      setLoadingCaseFile(false);
  });

  useMount(async () => {
    try {
      await loadPreMadeForms(userId);
    } catch (e: AxiosError | any) {
      setPreMadeFormsErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load user pre-made forms'}));
    }
    setLoadingPreMadeForms(false);
  });

  const reloadHistory = () => {
    triggerReloadHistoryEvent(getCaseFileHistoryByUserId(userId));
  };

  const handleSummaryFormSelect = (form: PreMadeFormModalType) => {
    setPreMadeFormsModal(form);
    setTabKey('preMadeForms');
  };

  const renderContent = () => {
    return (
      <div>
        <Tab.Container activeKey={tabKey}>
          <Row className={styles['header-row']}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', margin: '0 auto 5px 0'}}>
              <h4 style={{marginRight: '1rem'}}>{toTitleCase(user.name)}'s Case File</h4>
              <ProfilePicture userId={userId} style={{height: '3rem', width: '3rem'}}/>
            </div>
            <Nav variant={'tabs'}>
              <Nav.Link onClick={() => setTabKey('summary')} active={tabKey === 'summary'} className={styles['form-tab']}>
                General
              </Nav.Link>
              <Nav.Link onClick={() => setTabKey('documents')} active={tabKey === 'documents'} className={styles['form-tab']}>
                Documents
              </Nav.Link>
              <Nav.Link onClick={() => setTabKey('preMadeForms')} active={tabKey === 'preMadeForms'} className={styles['form-tab']}>
                Forms
              </Nav.Link>
            </Nav>
          </Row>
          <Tab.Content className={styles['tab-content-container']}>
            <Tab.Pane eventKey={'summary'} className={styles['tab-pane']}>
              {loadingCaseFile ? <CenteredSpinner/> : (
                caseFileErrorMessage ? <CenteredErrorMessage message={caseFileErrorMessage} /> :
                  <CaseFileSummary user={user} onFormSelect={handleSummaryFormSelect} reloadCaseFileHistory={() => reloadHistory()}/>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey={'documents'} className={styles['tab-pane']}>
            {loadingCaseFile ?  <CenteredSpinner/> : (
              caseFileErrorMessage ? <CenteredErrorMessage message={caseFileErrorMessage} /> :
                <CaseFileDocumentsGrid reloadCaseFileHistory={() => reloadHistory()}/>
            )}
            </Tab.Pane>
            <Tab.Pane eventKey={'preMadeForms'} className={styles['tab-pane']}>
              {loadingPreMadeForms ?  <CenteredSpinner/> : (
                preMadeFormsErrorMessage ? <CenteredErrorMessage style={{position: 'relative'}} message={preMadeFormsErrorMessage} /> :
                  <CaseFilePreMadeFormsGrid userId={userId} currentModal={preMadeFormsModal} setCurrentModal={setPreMadeFormsModal}/>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    );
  };

  return (
      <div ref={containerRef}>
        {renderContent()}
        <HistoryViewer apiHistoryFunc={caseFileHistoryLoadEvent} label={'Case File history'} containerHeight={containerSize.height}/>
      </div>
  );
}

const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state),
  caseFileDocuments: caseFileDocumentStore.selectors.getAsArray(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadDocuments: loadCaseFileDocumentState,
    loadUser: userStore.actions.getUser,
    loadCaseFileStateForUser: loadCaseFileState,
    loadParticipantClasses: loadParticipantClassManagementData,
    loadPreMadeForms: loadUserFormData
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(CaseFile);
