import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../../utils';
import {createStandardSelectors, getEntities, selector} from '../../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../../index';
import {upsertCaseManagementContact, upsertWeeklyCaseManagementNotes} from '../../../../api/forms/formApi';
import {
  TWeeklyCaseNotesForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/WeeklyCaseManagementNotesModal';
import {DropdownOption} from '../../../../components/util/form-components/SearchableDropdown/SearchableDropdown';

export interface CaseManagementNote {
  id: string;
  date: string;
  createdAt?: string;
  updatedAt?: string;
  shortDescription: string;
  longDescription: string;
}

export interface ServicesProvided {
  id: string;
  date: string;
  oesOutreach: boolean;
  oesFood: boolean;
  oesTemporarySafeShelter: boolean;
  oesInPersonCounseling: boolean;
  oesGroupCounselingOrDiscussion: boolean;
  oesReferralService: boolean;
  oesOther: boolean;
  oesOtherName: string;
  oesCrisisCounselingUnits: number;
  oesInPersonCounselingUnits: number;
  oesGroupCounselingOrDiscussionUnits: number;
  oesCaseManagementUnits: number;
  oesShelterServicesUnits: number;
  oesTransportationUnits: number;
  oesLegalAssistanceUnits: number;
  oesReferralsUnits: number;
  dssCrisisIntervention: boolean;
  dssAdvocacy: boolean;
  dssEducationalSupport: boolean;
  dssLifeSkillsTraining: boolean;
  dssHtWorkShops: boolean;
  dssForensicSpecialtyServices: boolean;
  dssSupportiveCounseling: boolean;
  dssEmergencyServices: boolean;
  referralResponseImmediateResponse: boolean;
  referralResponseThreeDayResponse: boolean;
  referralResponseTenDayResponse: boolean;
  referralResponseReReferralToService: boolean;
  referralResponseYouthAcceptedServices: boolean;
  referralResponseSuccessfulCompletion: boolean;
  referralResponseProgressNoCompletion: boolean;
  referralResponseNoProgressNoCompletion: boolean;
  ovcChildCare: number;
  ovcDentalErLongTerm: number;
  ovcEducation : number;
  ovcFinancialAssistance: number;
  ovcHousingRentalAssistance: number;
  ovcInterpreterTranslator: number;
  ovcMedicalErLongTerm: number;
  ovcMentalHealthTreatmentErLongTerm: number;
  ovcPersonalItems: number;
  ovcSubstanceAbuseTreatment: number;
  ovcTransportation: number;
  ovcOther: number;
  ovcOtherName: string;
  clientCoordination: number;
  clientOrientation: number;
  criminalJusticeBasedAdvocacy: number;
  crisisIntervention24HrHotline: number;
  emotionalMoralSupport: number;
  employmentAssistance: number;
  familyReunification: number;
  housingShelterAdvocacy: number;
  ongoingCaseManagement: number;
  protectionSafetyPlanning: number;
  repatriation: number;
  socialServiceAdvocacyExplanationBenefitsEntitlementsAvailability: number;
}

export interface CaseManagementStepEvaluation {
  id: string;
  timeFrame: string;
  description: string;
  comment: string;
}

export interface CaseManagementMedication {
  id: string;
  name: string;
  doctor: string;
  strength: string;
  dosage: string;
  amount: string;
  datePrescribed: string;
  expirationDate: string;
  refillDate: string;
  use: string;
}

export interface CaseManagementDrugTest {
  id: string;
  date: string;
  drugTest: string;
  amp: boolean;
  bar: boolean;
  bzo: boolean;
  mtd: boolean;
  opi: boolean;
  met: boolean;
  thc: boolean;
  pcp: boolean;
  mdma: boolean;
  coc: boolean;
  tobacco: boolean;
}

export interface CaseManagementFinancialAssistance {
  id: string;
  date: string;
  amount: string;
  descriptionOfExpense: string;
  voucher: string;
}

export interface CaseManagementCounseling {
  id: string;
  date: string;
  provider: string;
  lengthOfVisit: string;
  notes: string;
}

export interface CaseManagementContactDto extends CaseManagementContact{
  userId: string;
}

export interface CaseManagementContact {
  id: string;
  fullName: string;
  nickname: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  mailingAddress: string;
  phoneNumber: string;
  relationship: CaseManagementContactType | null;
  active: boolean;
  safeContact: boolean;
  email: string;
  information: string;
}

export enum CaseManagementContactType {
  emergency = 0,
  lawEnforcement = 1,
  socialWorker = 2,
  careProvider = 3,
  medicalDoctor = 4,
  friend = 5,
  sister = 6,
  brother = 7,
  parent = 8,
  pastor = 9,
  mentor = 10,
  dentist = 11,
  other = 12,
  family = 13
}

export const CaseManagementContactTypeDropDown: DropdownOption[] = [
  {
    value: 0,
    label: 'Emergency'
  },
  {
    value: 1,
    label: 'Law Enforcement'
  },
  {
    value: 2,
    label: 'Social Worker'
  },
  {
    value: 3,
    label: 'Care Provider'
  },
  {
    value: 4,
    label: 'Medical Doctor'
  },
  {
    value: 5,
    label: 'Friend'
  },
  {
    value: 6,
    label: 'Sister'
  },
  {
    value: 7,
    label: 'Brother'
  },
  {
    value: 8,
    label: 'Parent'
  },
  {
    value: 9,
    label: 'Pastor'
  },
  {
    value: 10,
    label: 'Mentor'
  },
  {
    value: 11,
    label: 'Dentist'
  },
  {
    value: 13,
    label: 'Family'
  },
  {
    value: 12,
    label: 'Other'
  }
];


export interface WeeklyCaseManagementNotes {
  id: string;
  userId: string;
  caseNotes: CaseManagementNote[];
  servicesProvided: ServicesProvided[];
  stepEvaluations: CaseManagementStepEvaluation[];
  medications: CaseManagementMedication[];
  drugTests: CaseManagementDrugTest[];
  financialAssistances: CaseManagementFinancialAssistance[];
  counselings: CaseManagementCounseling[];
  contacts: CaseManagementContact[];
  updatedOn: string;
}

const actions = createStandardActions(placeholder<WeeklyCaseManagementNotes>(), 'WEEKLY_CASE_MANAGEMENT_NOTES/SET', 'WEEKLY_CASE_MANAGEMENT_NOTES/SAVE');
const selectors = createStandardSelectors(placeholder<WeeklyCaseManagementNotes>(), s => getEntities(s).weeklyCaseManagementNotes);
export type WeeklyCaseManagementNotesActions = GetActions<typeof actions>;
export const weeklyCaseManagementNotes = combineReducers({items: standardItemsReducer<WeeklyCaseManagementNotes, WeeklyCaseManagementNotesActions>(actions)});
export const weeklyCaseManagementNotesStore = readonly({
  selectors: {
    ...selectors,
    getByUserId: selector(s => (userId: string) => selectors.getAsArray(s).find(caseNotes => caseNotes.userId === userId))
  },
  actions: {
    ...actions,
    upsert: (form: TWeeklyCaseNotesForm) => async (dispatch: CommonDispatch) => {
      const response = await upsertWeeklyCaseManagementNotes(form);
      await dispatch(actions.save(response));
    },
    upsertCaseManagementContact: (form: CaseManagementContactDto) => async (dispatch: CommonDispatch) => {
      const response = await upsertCaseManagementContact(form);
      await dispatch(actions.save(response));
    }
  }
});
