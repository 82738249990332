import {DashboardTask, ParticipantDashboardState, StaffDashboardState} from '../../../../types/DashboardTypes';
import IconButton from '../../../../components/util/widgets/IconButton/IconButton';
import {Accordion, Alert, Col, Row} from 'react-bootstrap';
import {AppTheme} from '../../../../appTheme';
import DashboardCard from '../DashboardCard/DashboardCard';
import React, {useState} from 'react';
import DashboardTaskModal from './DashboardTaskModal/DashboardTaskModal';
import {completeDashboardTask, upsertDashboardTasks} from '../../../../api/dashboardApi';
import {SetStateFunctional} from '../../../../util';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../redux/util/http';
import {ConfirmationDialog} from '../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {WebState} from "../../../../redux/core/types/WebState";
import {userStore} from "../../../../redux/web/entities/user";
import {connect} from "react-redux";
import {string} from "yup";

type Props = {
  tasks: DashboardTask[];
  setDashboardState: SetStateFunctional<StaffDashboardState> | SetStateFunctional<ParticipantDashboardState>;
} & ReturnType<typeof mapStateToProps>;

const DashboardTasks = (props: Props) => {
  const {tasks, setDashboardState} = props;
  const [editingTasks, setEditingTasks] = useState(false);
  const [completingTaskData, setCompletingTaskData] = useState<null | DashboardTask>(null);
  const [showCompleted, setShowCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const myTasks = () =>  tasks.filter(t => !t.complete && t.userId === props.currentUser?.id);
  const tasksAssignedToUsers = () => tasks
    .filter(t => t.assignedBy === props.currentUser?.id && t.userId !== props.currentUser?.id)
    // remove the "(assigned by CURRENT USER)" from the name
    .map(t => ({...t, name: t.name.replace(/\([^()]*\)/g, '')}))

  const currentlyViewedTasks = () => myTasks().filter(t => t.complete === showCompleted);

  const completeTask = async () => {
    try {
      await completeDashboardTask(completingTaskData!.id);
      setDashboardState((prev: any) => ({...prev, tasks: tasks!.filter(t => t.id !== completingTaskData!.id)}));
    } catch (e: AxiosError | any) {
      setErrorMessage(getErrorResponseMessage(e));
    }
    setCompletingTaskData(null);
  };

  const renderDashboardTaskModal = () => editingTasks &&
    <DashboardTaskModal
      myTasks={myTasks()}
      tasksAssignedToOtherUsers={tasksAssignedToUsers()}
      onSubmit={async (data?: DashboardTask[]) => {
        if (data) {
          const response = await upsertDashboardTasks(data);
          setDashboardState((prev: any) => ({...prev, tasks: response}));
        }
        setEditingTasks(false);
      }}
      onCancel={() => setEditingTasks(false)}
      setDashboardState={props.setDashboardState}
    />;

  const renderTitle = () => (
    <>
      <div>My Tasks</div>
      <div style={{position: 'absolute', right: '10px'}}>
        <IconButton icon={'pen'} size={'1x'} color={'#005A9C'} onClick={() => setEditingTasks(true)}/>
      </div>
    </>
  );

  const renderBody = () => {
    if (currentlyViewedTasks().length) return (
        currentlyViewedTasks().map((task, index) =>
            <Row
              key={task.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 0',
                boxShadow: index !== 0 ? 'rgb(0 0 0 / 50%) 0px -3px 3px -3px' : undefined
              }}
            >
              <div style={{display: 'flex', flexDirection: 'column', flex: '1', paddingRight: '1rem'}}>{task.name}</div>
              <div style={{marginLeft: 'auto'}}>
                <IconButton
                  linkStyle={{display: 'inline-flex', alignItems: 'center'}} icon={task.complete ? 'cancel' : 'check'}
                  size={'2x'}
                  color={AppTheme.colors.success}
                  iconToolTipText={'Mark as complete'}
                  onClick={(e) => setCompletingTaskData(task)}
                />
              </div>
            </Row>
        )
    );
    return (
      <Row>
        No tasks are awaiting completion.
      </Row>
    );
  };

  if (!tasks) return null;
  return (
    <>
      <DashboardCard title={renderTitle()} bodyStyle={{maxHeight: '20rem', overflow: 'auto'}}>
        <Col>
          {renderBody()}
          {errorMessage !== '' ?
            <div style={{marginTop: '1rem'}}>
              <Alert variant='danger'>{errorMessage}</Alert>
            </div>
            : null}
        </Col>
      </DashboardCard>
      <ConfirmationDialog
        open={!!completingTaskData}
        onAccept={async () => await completeTask()}
        onDecline={async () => setCompletingTaskData(null)}
        positiveVariant={'success'}
        negativeVariant={'danger'}
        positiveText={'Confirm'}
        prompt={`Are you sure you would like to complete the task "${completingTaskData?.name}?"`}
      />
      {renderDashboardTaskModal()}
    </>
  );
};

const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps)(DashboardTasks);
