export interface RoutePath {
  path: string;
  component: any;
}
const notFound = '/not-found';

// TODO remove unused paths
const RootPath = {
  all: '*',
  root: '*',
  dashboard: '/'
};

enum MiscellaneousPaths {
  storage = '/storage',
  images = '/images',
  termsOfService = '/terms-of-service',
  privacyPolicy = '/privacy-policy'
}

enum AuthenticationPaths {
  login = '/login',
  logout = '/logout',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  register = '/register',
  verifyEmail = '/verify'
}

enum ParticipantClassPaths {
  participantClasses = '/classes'
}

enum DocumentPaths {
  documents = '/documents'
}

enum ChoreChartPaths {
  View = '/chore-chart/view/',
  ViewParams = '/chore-chart/view/:date',
  Edit = '/chore-chart/edit/',
  EditParams = '/chore-chart/edit/:date'
}

enum ChecklistPaths {
  View = '/checklist/view/',
  ViewParams = '/checklist/view/:date',
  Edit = '/checklist/edit/',
  EditParams = '/checklist/edit/:date'
}

enum ConfigurationPaths {
  participantClassManagement = '/configuration/classes',
  userManagement = '/configuration/users',
  documentManagement = '/configuration/documents',
  caseFileManagement = '/configuration/case-file'
}

const PublicPath = '/public';

const PublicPaths = {
  activate: PublicPath + '/activate',
  watch: PublicPath + '/watch'
};

const ProfilePaths = {
  View: '/profile/view',
  Edit: '/profile/edit'
};

const CaseFilePaths = {
  CaseFile: '/casefile/:id'
};

const ExportPath = '/export';
const RavenBridgeLink = 'https://www.ravenbridgesoftware.com/';





export function applyBasePath(basePath: string, pathFn: (basePath: string) => string) {
  return pathFn(basePath);
}

export function applyBasePaths(basePath: string, ...pathsFns: ((basePath: string) => string)[]) {
  return pathsFns.map(fn => applyBasePath(basePath, fn));
}

export interface ComponentPath {
  component: any;
  paths: string[];
}

export function convertComponentPaths(...editorPaths: ComponentPath[]): RoutePath[] {
  return editorPaths.map(editorPath => editorPath.paths.map(path => ({
    path: path,
    component: editorPath.component
  }))).flat(1);
}

export type UrlParam = number|string;

export const RoutePaths = {
  ...RootPath,
  ...MiscellaneousPaths,
  ...AuthenticationPaths,
  ...ConfigurationPaths,
  ...ParticipantClassPaths,
  ...DocumentPaths,
  ...ChoreChartPaths,
  notFound: notFound,
  publicPath: PublicPath,
  publicPaths: PublicPaths,
  profilePaths: ProfilePaths,
  caseFilePaths: CaseFilePaths,
  choreChartPaths: ChoreChartPaths,
  checklistPaths: ChecklistPaths,
  exportPath: ExportPath,
  rbswLink: RavenBridgeLink
};
