import {
  CaseManagementCounseling,
  CaseManagementFinancialAssistance
} from '../../../../../../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {ArrayHelpers, FieldArray} from 'formik';
import {Col, Row} from 'react-bootstrap';
import IconButton from '../../../../../../../../../components/util/widgets/IconButton/IconButton';
import {ListTable} from '../../../../../../../../../components/util/lists/ListTable/ListTable';
import {DeletableListRow} from '../../../../../../../../../components/util/lists/ListTable/ListTableRow';
import React from 'react';
import {FieldArrayRow} from '../../../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import {InputColumn} from '../../../../../../../../../components/util/form-components/InputColumn';
import {
  StandardTopPaddedRow,
  TopPaddedRowChildrenDivider
} from '../../../../../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import DatepickerInput
  from '../../../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {
  makeCaseManagementCounseling
} from '../../../../../../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import {makeFieldArrayFieldName} from '../../../../../../../../../util';
import UnpaddedCol from '../../../../../../../../../components/util/UnpaddedCol';

export type TCaseManagementCounselingEntry = Omit<CaseManagementCounseling, 'id' | 'date'> & {date: Date};

interface Props {
  counselings: CaseManagementCounseling[];
  editable: boolean;
  fieldPrefix: string;
}

const getFieldName = (name: keyof CaseManagementCounseling) => name;

export const CaseManagementCounselingsFieldArray = (props: Props) => {
  const {fieldPrefix, counselings, editable} = props;
  const generateFieldName = (index: number) => `${fieldPrefix}.${index}`;

  const renderHeader = (helpers: ArrayHelpers) => (
    <Col style={{width: '100%', marginBottom: '10px'}}>
      <Row>
        <h5>Case Management Counseling</h5>
        <div style={{marginLeft: '0.5rem', marginTop: '1px', display: 'flex', flex: '1', textAlign: 'right'}}>
          <IconButton
            icon={'plus'}
            styles={{width: '100%', textAlign: 'right'}}
            size={'lg'}
            onClick={() => {
              helpers.push(makeCaseManagementCounseling());
              const elements = document.getElementsByClassName('counseling-note');
             if (elements.length === 0) return;
              elements[elements.length - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }}
          />
        </div>
      </Row>
    </Col>
  );

  return (
    <FieldArray
      name={fieldPrefix}
      validateOnChange={false}
      render={helpers=> {
        return (
          <ListTable
            style={{marginBottom: '1rem'}}
            noItemsLabel={'No counseling entries Added'}
            items={counselings}
            renderHeader={() => renderHeader(helpers)}
            renderItem={(item, index) => (
              <DeletableListRow
                iconSize={'2x'}
                key={generateFieldName(index)}
                className={'counseling-note'}
                deleteColumnSize={1}
                style={{flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'visible'}}
                render={() => (
                  <CounselingRow
                    entryPrefix={generateFieldName(index)}
                    editable={editable}
                    counseling={item}
                  />
                )}
                editable={editable}
                onDelete={() => helpers.remove(index)}
              />
            )}
          />
        );
      }}
    />
  );
};

type CounselingRowProps = {
  editable: boolean;
  counseling: CaseManagementCounseling;
  entryPrefix: string;
};
function CounselingRow({counseling, entryPrefix, editable}: CounselingRowProps) {
  return  (
    <FieldArrayRow style={{flex: '1', minWidth: '13rem', marginRight: '15px'}}>
      <UnpaddedCol>
        <StandardTopPaddedRow>
          <UnpaddedCol lg={3}>
            <TopPaddedRowChildrenDivider>
              <InputColumn label={'Date'}>
                <DatepickerInput
                  name={makeFieldArrayFieldName(entryPrefix, getFieldName('date'))}
                  disabled={false}
                  showYearDropdown={true}
                  ignoreOverflow={true}
                  allowFuture={true}
                  onChangeUtcHour={12}
                />
              </InputColumn>
              <InputColumn label={'Provider'}>
                <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('provider'))}/>
              </InputColumn>
              <InputColumn label={'Length of Visit'}>
                <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('lengthOfVisit'))}/>
              </InputColumn>
            </TopPaddedRowChildrenDivider>
          </UnpaddedCol>
          <Col style={{padding: 0}} lg={9}>
          <InputColumn label={'Notes'}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('notes'))} type={'textarea'} rows={9}/>
          </InputColumn>
          </Col>
        </StandardTopPaddedRow>
      </UnpaddedCol>
    </FieldArrayRow>
  );
}
