import * as React from 'react';
import SidebarPanel from '../components/layout/Sidebar/SidebarPanel';
import SidebarPanelMobile from '../components/layout/Sidebar/SidebarPanelMobile';
import UserInfo from '../components/layout/UserInfo/UserInfo';
import {Card} from 'react-bootstrap';
import {PageFooter} from '../components/layout/PageFooter/PageFooter';
import {Tooltip} from 'react-tooltip';


type Props = {
  children?: JSX.Element | null;
};

const AppLayout = (props: Props) => {

  return (
    <div>
      <Tooltip id='tooltip-id-anchor' place='top' closeOnScroll={true} closeOnEsc={true} closeOnResize={true} style={{zIndex: 2000}}/>
      <div style={{display: 'flex', minHeight: '100vh'}}>
        <SidebarPanel/>
        <main style={{display: 'flex', flexDirection: 'column', flex: '1'}}>
          <div style={{display: 'flex'}}>
            <SidebarPanelMobile/>
            <UserInfo/>
          </div>
          <div style={{display: 'flex', flex: '1'}}>
            <Card style={{display: 'flex', flexDirection: 'column', flex: '1', backgroundColor: '#f3f6f8', border: 'none'}}>
              <Card.Body>
                {props.children}
              </Card.Body>
            </Card>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AppLayout;
