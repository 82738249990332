import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../index';
import {createAction} from 'typesafe-actions';
import {Dictionary} from '../../util';
import {updateChoreCompletion, upsertChoreChartWeek} from '../../../api/choreChartChecklistApi';

export interface Chore {
  id: string;
  choreChartRowId: string;
  dayOfTheWeek: number;
  userId: string;
  done: boolean;
}

// renderId only used for map key, can be ignored
export interface ChoreChartRow {
  id: string;
  taskNumber: number;
  choreChartWeekId: string;
  task: string;
  chores: Chore[];
}

export interface ChoreChartWeek {
  id: string;
  weekOfTheYear: number;
  year: number;
  rows: ChoreChartRow[];
  reminder: string;
}

const partialDataActions = {
  setIncludedData: createAction('CHORE_CHART/SET_INCLUDED_DATA')<Dictionary<ChoreChartWeek>>()
};

const actions = createStandardActions(placeholder<ChoreChartWeek>(), 'CHORE_CHART_WEEK/SET', 'CHORE_CHART_WEEK/SAVE');
const selectors = createStandardSelectors(placeholder<ChoreChartWeek>(), s => getEntities(s).choreCharts);
export type ChoreChartWeekActions = GetActions<typeof actions> | GetActions<typeof partialDataActions>;
export const choreChartWeeks = combineReducers({
  items: standardItemsReducer<ChoreChartWeek, ChoreChartWeekActions>(actions)
    .handleAction(partialDataActions.setIncludedData, (state, action) => {
      let returnedState = {...state};
      Object.entries(action.payload).forEach(([key, value]) => {
        const previousEntryState = returnedState[key as any];
        returnedState =  {...returnedState, [key as any]: {...previousEntryState, ...value}};
      });
      return returnedState;
    })
});
export const choreChartWeekStore = readonly({
  selectors: {
    ...selectors,
    getWeek: selector(s => (year: number, weekOfTheYear: number) => selectors.getAsArray(s)
      .find(week => week.year === year && week.weekOfTheYear === weekOfTheYear))
  },
  actions: {
    ...actions,
    ...partialDataActions,
    // tslint:disable-next-line:no-empty
    upsertWeek: (request: ChoreChartWeek) => async (dispatch: CommonDispatch) => {
      const response = await upsertChoreChartWeek(request);
      dispatch(actions.save(response));
    },
    updateChoreCompletion: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await updateChoreCompletion(id);
      dispatch(actions.save(response));
    }
  }
});
