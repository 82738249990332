import React from 'react';
import {useFormikContext} from 'formik';
import Input from '../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {
  SecondaryIntake,
  SecondaryIntakeCommentsAndConcerns
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  StandardInputColumn,
  StandardParentInputRow, StandardTopPaddedRow
} from '../../../../../../../components/util/form-components/standardLayout';

interface Props {
  fieldPrefix: string;
}

export const CommentsAndConcernsForm = (props: Props) => {
  const values = useFormikContext<SecondaryIntake>().values.commentsAndConcerns;
  const getFieldName = (name: keyof SecondaryIntakeCommentsAndConcerns) => `${props.fieldPrefix}.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Closing Information'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Comments and Concerns'}>
            <Input name={getFieldName('commentsOrConcerns')} type={'textarea'} rows={10}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
