import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities} from '../selectors';
import {CommonDispatch} from '../index';
import {createAction} from 'typesafe-actions';
import {copyConfiguration, deleteConfiguration, upsertReportConfiguration} from '../../../api/exportApi';

export interface ReportConfiguration {
  id: string;
  name: string;
  data: string;
  subset: string;
}

export const deleteReportConfigurationAction = createAction('REPORT_CONFIGURATION/DELETE')<string>();
const actions = createStandardActions(placeholder<ReportConfiguration>(), 'REPORT_CONFIGURATION/SET', 'REPORT_CONFIGURATION/SAVE');
const selectors = createStandardSelectors(placeholder<ReportConfiguration>(), s => getEntities(s).reportConfigurations);
export type ReportConfigurationActions = GetActions<typeof actions> | ReturnType<typeof deleteReportConfigurationAction>;
export const reportConfigurations = combineReducers({
  items: standardItemsReducer<ReportConfiguration, ReportConfigurationActions>(actions)
    .handleAction(deleteReportConfigurationAction, (state, action) => {
    const updatedState = {...state};
    delete updatedState[action.payload as any];
    return updatedState;
  })});
export const reportConfigurationStore = readonly({
  selectors: {
    ...selectors
  },
  actions: {
    ...actions,
    upsert: (config: ReportConfiguration) => async (dispatch: CommonDispatch) => {
      const response = await upsertReportConfiguration(config);
      await dispatch(reportConfigurationStore.actions.save(response));
      return response;
    },
    deleteReportConfiguration: (reportConfigurationId: string) => async (dispatch: CommonDispatch) => {
      await deleteConfiguration(reportConfigurationId);
      dispatch(deleteReportConfigurationAction(reportConfigurationId));
    },
    copyReportConfiguration: (reportConfigurationId: string) => async (dispatch: CommonDispatch) => {
      const response = await copyConfiguration(reportConfigurationId);
      dispatch(reportConfigurationStore.actions.save(response));
    }
  }
});
