import {
  CaseManagementMedication,
  CaseManagementStepEvaluation
} from '../../../../../../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {ArrayHelpers, FieldArray} from 'formik';
import {Col, Row} from 'react-bootstrap';
import IconButton from '../../../../../../../../../components/util/widgets/IconButton/IconButton';
import {
  makeCaseManagementDrugTest, makeCaseManagementMedication, makeCaseManagementStepEvaluation
} from '../../../../../../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import {ListTable} from '../../../../../../../../../components/util/lists/ListTable/ListTable';
import {DeletableListRow} from '../../../../../../../../../components/util/lists/ListTable/ListTableRow';
import React from 'react';
import {FieldArrayRow} from '../../../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';
import {InputColumn} from '../../../../../../../../../components/util/form-components/InputColumn';
import {StandardTopPaddedRow} from '../../../../../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import DatepickerInput
  from '../../../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {makeFieldArrayFieldName} from '../../../../../../../../../util';
import UnpaddedCol from '../../../../../../../../../components/util/UnpaddedCol';

export type TCaseManagementMedicationEntry = Omit<CaseManagementMedication, 'id'>;

interface Props {
  medications: CaseManagementMedication[];
  editable: boolean;
  fieldPrefix: string;
}

const getFieldName = (name: keyof CaseManagementMedication) => name;

export const CaseManagementMedicationsFieldArray = (props: Props) => {
  const {fieldPrefix, medications, editable} = props;
  const generateFieldName = (index: number) => `${fieldPrefix}.${index}`;

  const renderHeader = (helpers: ArrayHelpers) => (
    <Col style={{width: '100%', marginBottom: '10px'}}>
      <Row>
        <h5>Case Management Medications</h5>
        <div style={{marginLeft: '0.5rem', marginTop: '1px', display: 'flex', flex: '1', textAlign: 'right'}}>
          <IconButton
            icon={'plus'}
            styles={{width: '100%', textAlign: 'right'}}
            size={'lg'}
            onClick={() => {
              helpers.push(makeCaseManagementMedication());
              const elements = document.getElementsByClassName('medication-note');
             if (elements.length === 0) return;
              elements[elements.length - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }}
          />
        </div>
      </Row>
    </Col>
  );

  return (
    <FieldArray
      name={fieldPrefix}
      validateOnChange={false}
      render={helpers=> {
        return (
          <ListTable
            style={{marginBottom: '1rem'}}
            noItemsLabel={'No medication entries added'}
            items={medications}
            renderHeader={() => renderHeader(helpers)}
            renderItem={(item, index) => (
              <DeletableListRow
                iconSize={'2x'}
                key={generateFieldName(index)}
                className={'medication-note'}
                deleteColumnSize={1}
                style={{flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'visible'}}
                render={() => (
                  <MedicationRow
                    entryPrefix={generateFieldName(index)}
                    editable={editable}
                    medication={item}
                  />
                )}
                editable={editable}
                onDelete={() => helpers.remove(index)}
              />
            )}
          />
        );
      }}
    />
  );
};

type MedicationRowProps = {
  editable: boolean;
  medication: CaseManagementMedication;
  entryPrefix: string;
};
function MedicationRow({medication, entryPrefix, editable}: MedicationRowProps) {
  return  (
    <FieldArrayRow style={{flex: '1', minWidth: '13rem', marginRight: '1.5rem'}}>
      <UnpaddedCol>
        <StandardTopPaddedRow>
          <InputColumn label={'Medication Name'} columnSize={6}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('name'))}/>
          </InputColumn>
          <InputColumn label={'Doctor'} columnSize={6}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('doctor'))}/>
          </InputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <InputColumn label={'Strength'} columnSize={3}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('strength'))}/>
          </InputColumn>
          <InputColumn label={'Dosage'} columnSize={6}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('dosage'))}/>
          </InputColumn>
          <InputColumn label={'Amount'} columnSize={6}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('amount'))}/>
          </InputColumn>
          <InputColumn label={'USE'} columnSize={3}>
            <Input name={makeFieldArrayFieldName(entryPrefix, getFieldName('use'))} type={'textarea'}/>
          </InputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <InputColumn label={'Date Prescribed'} columnSize={4}>
            <DatepickerInput
              name={makeFieldArrayFieldName(entryPrefix, getFieldName('datePrescribed'))}
              onChangeUtcHour={12}
              showYearDropdown={true}
              ignoreOverflow={true}
              allowFuture={true}
            />
          </InputColumn>
          <InputColumn label={'Expiration Date'} columnSize={4}>
            <DatepickerInput
              name={makeFieldArrayFieldName(entryPrefix, getFieldName('expirationDate'))}
              onChangeUtcHour={12}
              showYearDropdown={true}
              ignoreOverflow={true}
              allowFuture={true}
            />
          </InputColumn>
          <InputColumn label={'Refill Date'} columnSize={4}>
            <DatepickerInput
              name={makeFieldArrayFieldName(entryPrefix, getFieldName('refillDate'))}
              onChangeUtcHour={12}
              showYearDropdown={true}
              ignoreOverflow={true}
              allowFuture={true}
            />
          </InputColumn>
        </StandardTopPaddedRow>
      </UnpaddedCol>
    </FieldArrayRow>
  );
}
