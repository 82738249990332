import React, {ReactNode} from 'react';
import {Card, Col} from 'react-bootstrap';
import styles from './AuthenticationBody.module.scss';
import {PageFooter} from '../PageFooter/PageFooter';
import LoginImage from '../../../assets/images/Login.jpg';
import {getBlackLogoLink, getWhiteLogoLink} from '../../../appTheme';

export function AuthenticationBody(props: {children: ReactNode}) {

  return (
    <div className={styles['container']}>
      <Col className={styles['page-body']}>
        <div className={styles['auth-content']}>
          <Card className={styles['fill-col']} style={{borderRadius: 0}}>
            <Card.Body style={{display: 'flex'}}>
              <Col className={styles['fill-col']} style={{alignItems: 'center', justifyContent: 'center', paddingTop: '2rem'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', transform: 'translate(0, -5rem)'}}>
                <img src={getBlackLogoLink()} className={styles['logo']}/>
                {props.children}
                </div>
              </Col>
            </Card.Body>
          </Card>
        </div>
        <div className={styles['image-container']}>
          <img src={LoginImage} className={styles['background-image']}/>
        </div>
      </Col>
    </div>
  );
}
