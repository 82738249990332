import {mapIsNotParticipantToProps, mapIsNotParticipantToPropsType,} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';

type NotParticipantRouteProps = mapIsNotParticipantToPropsType & {
  children: JSX.Element;
};

// TODO once manager role is implemented, create prop for whether managers can access
function NotParticipantComponent({children, notParticipant}: NotParticipantRouteProps) {
  return notParticipant ? children : null;
}

export default connect(mapIsNotParticipantToProps)(NotParticipantComponent);
