import React, {FormEvent, useState} from 'react';
import {Alert, Button, Col, Modal, Row, Spinner} from 'react-bootstrap';
import {Form, Formik, FormikErrors} from 'formik';
import styles from '../ParticipantFormModalStyles.module.scss';
import {Form as BSForm} from 'react-bootstrap';
import {
  StandardCheckboxInput,
  StandardInputColumn,
  StandardParentInputRow, StandardRadioInput,
  StandardTopPaddedRow,
} from '../../../../../../components/util/form-components/standardLayout';
import {ConfirmationDialog} from '../../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {AxiosError} from 'axios';
import {getErrorResponseMessage, handleAxiosError} from '../../../../../../redux/util/http';
import {bindActionCreators, Dispatch} from 'redux';
import {userStore} from '../../../../../../redux/web/entities/user';
import {WebState} from '../../../../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import {
  ENewParticipantChecklistEducation,
  NewParticipantChecklist, newParticipantChecklistStore
} from '../../../../../../redux/web/entities/forms/newParticipantChecklist';
import {makeNewParticipantChecklist} from '../../../../../../redux/web/factory/forms/newParticipantChecklist';
import DatepickerInput
  from '../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import Input from '../../../../../../components/util/form-components/formik-inputs/Input/Input';
import PaddedRowsListFormatter
  from '../../../../../../components/util/lists/PaddedRowsListFormatter/PaddedRowsListFormatter';
import {NewParticipantChecklistModalSchema} from './NewParticipantChecklistModalSchema';
import {useMount} from '../../../../../../hooks/useMount';
import {
  CompletionStateIndicator
} from '../../../../../../components/util/widgets/CompletionStateIndicator/CompletionStateIndicator';
import {PrimaryIntake} from '../../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';

export type TNewParticipantChecklistForm = Omit<NewParticipantChecklist, 'id'> & {
  id?: string;
};

type Props = {
  userId: string;
  editable: boolean;
  existingChecklist?: NewParticipantChecklist;
  existingPrimary?: PrimaryIntake;
  onSubmit: () => void;
  onCancel: () => void;
  disabled?: boolean;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function NewParticipantChecklistModal(props: Props) {
  const {userId, editable, onSubmit, onCancel, getUserById, existingChecklist, existingPrimary, disabled = true,
    actions: {upsertNewParticipantChecklist, loadChecklistForUserId}} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentModal, setCurrentModal] = useState<'checklist' | 'cancel'>('checklist');
  const [errorMessage, setErrorMessage] = useState('');
  const [validateOnChangeAfterSubmit, setValidateOnChangeAfterSubmit] = useState(false);
  const username = getUserById(userId)?.name ?? 'undefined name';
  const getFieldName = (name: keyof TNewParticipantChecklistForm) => name;

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    values: TNewParticipantChecklistForm,
    validate: (values: TNewParticipantChecklistForm) => Promise<FormikErrors<TNewParticipantChecklistForm>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    setValidateOnChangeAfterSubmit(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(values);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
      setErrorMessage('Errors were found in the form.');
    } else {
      try {
        await upsertNewParticipantChecklist(values);
        await onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(getErrorResponseMessage(e));
      }
      setIsSubmitting(false);
    }
  };

  const renderCancelConfirmationDialog = () => {
    return (
      <ConfirmationDialog
        open={currentModal === 'cancel'}
        onAccept={async () => onCancel()}
        onDecline={async () => setCurrentModal('checklist')}
        positiveVariant={'success'}
        negativeVariant={'danger'}
        positiveText={'Yes'}
        negativeText={'No'}
        prompt={`Are you sure you would like to cancel your edits to ${username}'s participant checklist?`}
      />
    );
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={() => disabled ? onCancel() : setCurrentModal('cancel')} variant={'danger'} className={styles['close-button']}>
            {disabled ? 'Close' : 'Cancel'}
          </Button>
        }
        {!disabled && !isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  return (
    <>
      <Formik
        initialValues={makeNewParticipantChecklist(userId, existingChecklist, existingPrimary)}
        validationSchema={NewParticipantChecklistModalSchema}
        onSubmit={() => undefined}>
        {({values, validateForm, handleSubmit, errors, setValues}) => (
          <Modal show={currentModal === 'checklist'} centered={true} size={'xl'}>
            <Modal.Body>
              <Modal.Title>
                <Row>
                  {`${username}'s New Participant Checklist Form`}
                  <CompletionStateIndicator
                    existing={!!existingChecklist}
                    newText={'New (Values Imported from Primary Intake Form)'}
                    existingText={'Editing Completed Form'}
                    styles={{alignSelf: 'flex-end', marginLeft: 'auto', marginRight: '15px'}}
                  />
                </Row>
              </Modal.Title>
                <Col style={{paddingTop: '10px'}}>
                  <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                    <fieldset disabled={disabled}>
                      <StandardParentInputRow label={'Participant Information'}>
                        <StandardTopPaddedRow>
                          <StandardInputColumn label={'Name'} columnSize={4}>
                            <Input name={getFieldName('name')}/>
                          </StandardInputColumn>
                          <StandardInputColumn label={'Start Date'} columnSize={4}>
                            <DatepickerInput name={getFieldName('startDate')} onChangeUtcHour={12} showYearDropdown={true} allowFuture={true}/>
                          </StandardInputColumn>
                          <StandardInputColumn label={'Date of Birth'} columnSize={4}>
                            <DatepickerInput name={getFieldName('dateOfBirth')} onChangeUtcHour={12} showYearDropdown={true}/>
                          </StandardInputColumn>
                        </StandardTopPaddedRow>
                      </StandardParentInputRow>
                      <StandardParentInputRow label={'First Day'}>
                        <PaddedRowsListFormatter type={'unordered'}>
                          <div>
                            Provide participant with the program manual.
                          </div>
                          <div>
                            Assign "time" to answer general questions.
                          </div>
                        </PaddedRowsListFormatter>
                      </StandardParentInputRow>
                      <StandardParentInputRow label={'Initial Needs Within the First 30 Days...'}
                                              style={{flexWrap: 'nowrap'}}>
                        <Row>
                          <Col>
                            <StandardTopPaddedRow>
                              <StandardCheckboxInput label={'CA ID'} name={getFieldName('caId')}/>
                            </StandardTopPaddedRow>
                            <StandardTopPaddedRow>
                              <StandardCheckboxInput label={'SS Card'} name={getFieldName('ssCard')}/>
                            </StandardTopPaddedRow>
                            <StandardTopPaddedRow>
                              <StandardCheckboxInput label={'Birth Certificate'} name={getFieldName('birthCertificate')}/>
                            </StandardTopPaddedRow>
                            <StandardTopPaddedRow>
                              <StandardCheckboxInput label={'MediCal'} name={getFieldName('mediCal')}/>
                            </StandardTopPaddedRow>
                            <StandardTopPaddedRow>
                              <StandardCheckboxInput label={'CalFresh'} name={getFieldName('calFresh')}/>
                            </StandardTopPaddedRow>
                            <StandardTopPaddedRow>
                              <StandardCheckboxInput label={'Probation / Parole'}
                                                     name={getFieldName('probationOrParole')}/>
                            </StandardTopPaddedRow>
                            <StandardTopPaddedRow>
                              <StandardCheckboxInput label={'Diploma / GED'} name={getFieldName('diplomaOrGed')}/>
                            </StandardTopPaddedRow>
                            <StandardTopPaddedRow>
                              <StandardCheckboxInput label={'CPS'} name={getFieldName('cps')}/>
                            </StandardTopPaddedRow>
                            <StandardTopPaddedRow>
                              <StandardCheckboxInput label={'Victim Comp App'} name={getFieldName('victimCompApp')}/>
                            </StandardTopPaddedRow>
                          </Col>
                          <Col>
                            <StandardTopPaddedRow>
                              <PaddedRowsListFormatter type={'unordered'}>
                                <div>
                                  Treatment Plan
                                </div>
                                <div>
                                  Individual Counseling
                                </div>
                                <div>
                                  Parent Planning
                                </div>
                                <div>
                                  Medication Sheet
                                </div>
                                <div>
                                  Class Schedule
                                </div>
                                <div>
                                  Discuss Protection Period
                                </div>
                                <div>
                                  Discuss Step Reviews
                                </div>
                                <div>
                                  Dress Code
                                </div>
                              </PaddedRowsListFormatter>
                            </StandardTopPaddedRow>
                          </Col>
                          <Col>
                            <StandardTopPaddedRow>
                              <PaddedRowsListFormatter type={'unordered'}>
                                <div>
                                  Personal Conduct Standards
                                </div>
                                <div>
                                  Progressive Disciplinary Actions
                                </div>
                                <div>
                                  Security
                                </div>
                                <div>
                                  Confidentiality
                                </div>
                                <div>
                                  Safety
                                </div>
                                <div>
                                  Emergency Procedures
                                </div>
                                <div>
                                  Visitors
                                </div>
                                <div>
                                  Mail and Outside Conduct
                                </div>
                              </PaddedRowsListFormatter>
                            </StandardTopPaddedRow>
                          </Col>
                        </Row>
                      </StandardParentInputRow>
                      <StandardParentInputRow label={'Client Needs'}>
                        <StandardTopPaddedRow>
                          <Col>
                            <PaddedRowsListFormatter type={'unordered'}>
                              <div>
                                Review general participant policies and procedures
                              </div>
                              <div>
                                Discuss safety plan
                              </div>
                            </PaddedRowsListFormatter>
                          </Col>
                          <Col>
                            <PaddedRowsListFormatter type={'unordered'}>
                              <div>
                                Clothing / hygiene
                              </div>
                              <div>
                                Bed assignment
                              </div>
                              <div>
                                Mail (incoming and outgoing)
                              </div>
                              <div>
                                Food cabinet assignment
                              </div>
                            </PaddedRowsListFormatter>
                          </Col>
                          <Col>
                            <PaddedRowsListFormatter type={'unordered'}>
                              <div>
                                Telephone (after 30 days)
                              </div>
                              <div>
                                Pictures taken
                              </div>
                              <div>
                                Clothes washed
                              </div>
                              <div>
                                School supplies
                              </div>
                            </PaddedRowsListFormatter>
                          </Col>
                        </StandardTopPaddedRow>
                      </StandardParentInputRow>
                      <StandardParentInputRow label={'Step One'}>
                        <StandardTopPaddedRow>
                          Introduction to BTC expectations and overall expectations of the client are discussed.
                        </StandardTopPaddedRow>
                        <StandardTopPaddedRow>
                          <Col style={{padding: '0'}}>
                            Tour of the facility, including:
                            <PaddedRowsListFormatter type={'unordered'}>
                              <div>
                                Restrooms
                              </div>
                              <div>
                                Reception
                              </div>
                              <div>
                                Classrooms
                              </div>
                              <div>
                                Boundaries
                              </div>
                              <div>
                                Bulletin boards
                              </div>
                            </PaddedRowsListFormatter>
                          </Col>
                        </StandardTopPaddedRow>
                      </StandardParentInputRow>
                      <StandardParentInputRow label={'Outcome of Initial Needs'}>
                        <StandardTopPaddedRow>
                          <Input name={getFieldName('outcomeOfInitialNeeds')} type={'textarea'}/>
                        </StandardTopPaddedRow>
                      </StandardParentInputRow>
                      <StandardParentInputRow label={'Education'}>
                        <StandardTopPaddedRow>
                          <StandardRadioInput
                            name={getFieldName('education')}
                            label={'Needs High School Diploma / GED'}
                            value={ENewParticipantChecklistEducation.needsDiplomaOrGed}
                            checked={values.education === ENewParticipantChecklistEducation.needsDiplomaOrGed}
                          />
                          <StandardRadioInput
                            name={getFieldName('education')}
                            label={'College'}
                            value={ENewParticipantChecklistEducation.college}
                            checked={values.education === ENewParticipantChecklistEducation.college}
                          />
                          <StandardRadioInput
                            name={getFieldName('education')}
                            label={'Trade School'}
                            value={ENewParticipantChecklistEducation.tradeSchool}
                            checked={values.education === ENewParticipantChecklistEducation.tradeSchool}
                          />
                          <StandardRadioInput
                            name={getFieldName('education')}
                            label={'Other'}
                            value={ENewParticipantChecklistEducation.other}
                            checked={values.education === ENewParticipantChecklistEducation.other}
                          />
                        </StandardTopPaddedRow>
                      </StandardParentInputRow>
                    </fieldset>
                    {errorMessage !== '' ?
                      <div style={{marginTop: '1rem'}}>
                        <Alert variant='danger'>{errorMessage}</Alert>
                      </div>
                      : null}
                    <StandardTopPaddedRow style={{paddingTop: '10px'}}>
                      <BSForm.Group className={styles['form-buttons']}>
                        {renderButtons()}
                      </BSForm.Group>
                    </StandardTopPaddedRow>
                  </Form>
                </Col>
            </Modal.Body>
          </Modal>
        )}
      </Formik>
      {renderCancelConfirmationDialog()}
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertNewParticipantChecklist: newParticipantChecklistStore.actions.upsert,
    loadChecklistForUserId: newParticipantChecklistStore.actions.getByUserId
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(NewParticipantChecklistModal);

