import DashboardCard from '../DashboardCard/DashboardCard';
import React from 'react';
import {DashboardClass} from '../../../../types/DashboardTypes';
import {Accordion} from 'react-bootstrap';
import {now} from '../../../../util';

interface Props {
  classes: DashboardClass[];
}

const DashboardClasses = (props: Props) => {
  const {classes} = props;

  const groupedUpcomingClassesByDate = (): {dateString: string; classes: DashboardClass[]}[] => {
    const dateClassGroups: {dateString: string; classes: DashboardClass[]}[] = [];
    classes.filter(c => c.date > now).forEach((c) => {
      const classDateString = c.date.toLocaleDateString();
      const existingGroup = dateClassGroups.find(g => g.dateString === classDateString);
      if (existingGroup)
        existingGroup.classes.push(c);
      else
        dateClassGroups.push({dateString: classDateString, classes: [c]});
    });
    return dateClassGroups;
  };


  return (
    <DashboardCard title={'Upcoming Classes'}>
      <Accordion>
      {groupedUpcomingClassesByDate().map((g) => (
        <Accordion.Item eventKey={g.dateString} key={g.dateString}>
          <Accordion.Header>
            <div style={{fontWeight: 'bold'}}>{g.dateString} - {g.classes.length} {g.classes.length === 1 ? 'class' : 'classes'}</div>
          </Accordion.Header>
          <Accordion.Body>
            <Accordion>
              {g.classes.map((c) => (
                <Accordion.Item key={c.id} eventKey={c.id}>
                  <Accordion.Header>
                    <div style={{fontWeight: 'bold'}}>{c.name}</div>
                  </Accordion.Header>
                  <Accordion.Body>{c.description}</Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>
      ))}
      </Accordion>
    </DashboardCard>
  );
};

export default DashboardClasses;
