import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/core/types/WebState';
import {userStore} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import styles from './StaffChecklistCalendar.module.scss';
import {AppTheme} from '../../../appTheme';
import Select from 'react-select';
import {convertObjectToOption, convertToDropDownOptions} from '../../../redux/util';
import Input from '../../../components/util/form-components/formik-inputs/Input/Input';
import {makeChecklistRow} from '../../../redux/web/factory';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import {ArrayHelpers} from 'formik';
import React, {forwardRef, useMemo, useState} from 'react';
import {ConfirmationDialog} from '../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../../redux/util/http';
import {
  ChecklistItem,
  ChecklistRow,
  ChecklistWeek,
  checklistWeekStore
} from '../../../redux/web/entities/checklistWeek';
import {addDays, getDate, getDay, getWeek, getYear, parseISO, startOfWeek} from 'date-fns';
import {format, utcToZonedTime} from 'date-fns-tz';
import {localTz} from '../../../util';
import {ChecklistDay} from './StaffChecklistCalendarController';

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

interface EditProps {
  setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void;
  helpers: ArrayHelpers;
}

export type Props = {
  currentDate: Date;
  editProps?: EditProps;
  showShelterStaffProps?: boolean;
  showUpdatedAt?: boolean;
  renderedValues: ChecklistWeek;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const StaffChecklistCalendar = forwardRef((props: Props, ref) => {
  const {currentDate, editProps, showUpdatedAt = false, showShelterStaffProps = false, renderedValues, setErrorMessage, isAdministrator,
    getUserById, shelterStaff, currentUser, actions: {completeItem}} = props;

  const [participantDialogueProps, setParticipantDialogueProps] = useState<{checklistItemId: string; rowId: string} | null>(null);
  const getWeekFieldName = (name: keyof ChecklistWeek) => name;
  const getRowFieldName = (name: keyof ChecklistRow) => name;
  const getChoreFieldName = (name: keyof ChecklistItem) => name;
  const generateRowFieldName = (index: number) => `${getWeekFieldName('rows')}[${index}]`;
  const generateChoreFieldName = (rowFieldName: string, index: number) => `${rowFieldName}.${getRowFieldName('items')}[${index}]`;
  const now = new Date();
  const nowYear = getYear(now);
  const nowWeek = getWeek(now);
  const nowDay = getDay(now);

  const daysOfWeek = useMemo(() => {
    const days: ChecklistDay[] = [];
    const startDate = startOfWeek(currentDate);
    for (let i = 0; i < 7; i++) {
      const day = addDays(startDate, i);
      days.push({date: day, formattedDate: format(day, 'EEE'), dayOfTheWeek: getDay(day), dayOfTheMonth: getDate(day)});
    }
    return days;
  }, [currentDate]);

  const renderDateHeader = () => {
    return (
      <Row style={{fontSize: '1.4rem', flexWrap: 'nowrap'}}>
        <div className={styles['chore-chart-day']} style={{backgroundColor: '#bfbccb', border: 'none'}}>
          <Row>
            For the week of:
          </Row>
          <Row style={{fontWeight: 'bold'}}>
            {format(startOfWeek(currentDate), 'MM/dd/yyyy')}
          </Row>
        </div>
        {daysOfWeek.map((day) =>
          <Col
            key={`header_${day.dayOfTheMonth}`}
            className={styles['chore-chart-day']}
            style={{backgroundColor: '#bbe5ed', border: 'none'}}
          >
            <Row style={{fontWeight: 'bold'}}>
              {day.formattedDate}
            </Row>
            <Row style={{paddingTop: '5px'}}>
              {day.dayOfTheMonth}
            </Row>
          </Col>
        )}
        {editProps ? <div className={styles['chore-chart-action']}/> : null}
      </Row>
    );
  };

  const renderParticipantConfirmationDialogue = () => {
    if (participantDialogueProps !== null) {
      const row = renderedValues.rows.find(r => r.id === participantDialogueProps.rowId);
      const item = row?.items.find(c => c.id === participantDialogueProps.checklistItemId);
      return (
        <ConfirmationDialog
          open={true}
          onAccept={async () => {
            try {
              completeItem(participantDialogueProps.checklistItemId);
              setErrorMessage('');
            } catch (e: AxiosError | any) {
              setErrorMessage(handleAxiosError(e));
            }
            setParticipantDialogueProps(null);
          }}
          positiveVariant={'success'}
          positiveText={'Yes'}
          onDecline={async () => setParticipantDialogueProps(null)}
          negativeVariant={'danger'}
          negativeText={'No'}
          prompt={
          `Are you sure you would like to indicate the task "${row?.task}" has ${item?.done ?  'not been completed' : 'been completed'} on
          ${item ? weekdays[item.dayOfTheWeek] : ''}?`}
        />
      );
    }
  };

  const renderChoreParticipant = (item: ChecklistItem) => {
    const isCurrentUserChore = item.userId === currentUser?.id;
    return (
      <Row style={{alignItems: 'center', flexWrap: 'nowrap', fontWeight: isCurrentUserChore ? 'bold' : 'inherit'}}>
        {getUserById(item.userId)?.name}
        {isCurrentUserChore && renderedValues.year === nowYear && renderedValues.weekOfTheYear === nowWeek && item.dayOfTheWeek === nowDay ?
          <div style={{marginLeft: '10px', marginRight: '5px'}}>
            <IconButton
              icon={item.done ? 'cancel' : 'check'}
              color={item.done ? 'indianred' : 'green'}
              size={'2x'}
              onClick={() => setParticipantDialogueProps({checklistItemId: item.id, rowId: item.checklistRowId})}
            />
          </div>
          : null}
      </Row>
    );
  };

  const renderItemUpdatedBy = (updatedBy: string) => {
    const user = getUserById(updatedBy);
    if (user) return (
      <>
        by <span style={{fontStyle: 'italic'}}>{user.name}</span>
      </>
    );
  };

  const renderItemUpdatedAt = (item: ChecklistItem) => {
    if (!editProps && item.updatedAt && item.userId && isAdministrator)
    return (
      <div>
        Updated <span style={{fontStyle: 'italic'}}>
              {format(utcToZonedTime(parseISO(item.updatedAt), localTz as any as string), 'MM/dd/yyyy hh:mm:ss a', {timeZone: localTz as any as string})}
        </span> {renderItemUpdatedBy(item.updatedBy)}
      </div>
    );
  };

  const renderItem = (item: ChecklistItem, rowIndex: number, index: number) => {
    const fieldName = `${generateChoreFieldName(generateRowFieldName(rowIndex), index)}.${getChoreFieldName('userId')}`;
    return (
      <Col
        key={index}
        className={styles['chore-chart-day']}
        style={item && item.done ? {backgroundColor: AppTheme.colors.lighterGreen} : {}}
      >
        {editProps && !item.done ?
          <Select
            name={fieldName}
            options={convertToDropDownOptions(shelterStaff)}
            defaultValue={convertObjectToOption(getUserById(item.userId) ?? {value: '', label: ''})}
            onChange={(option) => editProps.setFieldValue(fieldName, option?.value ?? '')}
            isClearable={!!item.userId}
            className={styles['chore-chart-user']}
            styles={{ menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible' }) }}
            menuPortalTarget={document.querySelector('body')}
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
          />
          : showShelterStaffProps ?
            renderChoreParticipant(item)
            : <div style={{display: 'flex', flexWrap: 'wrap', fontWeight: 'bold'}}>{getUserById(item.userId)?.name}</div>
        }
        {showUpdatedAt && renderItemUpdatedAt(item)}
      </Col>
    );
  };

  const renderChoreRow = (row: ChecklistRow, rowIndex: number) => (
    <Row key={row.taskNumber} style={{flexWrap: 'nowrap'}}>
      <>
        <div className={styles['chore-chart-day']} style={{fontSize: '1.4rem'}}>
          {editProps ? <Input name={`${generateRowFieldName(rowIndex)}.${getRowFieldName('task')}`} type={'textarea'}/> : row.task}
        </div>
        {[...row.items]
          .sort((a, b) => a.dayOfTheWeek > b.dayOfTheWeek ? 1 : -1)
          .map((chore) => renderItem(chore, rowIndex, row.items.indexOf(chore)))}
        {editProps ?
          <div className={styles['chore-chart-action']}>
            <IconButton
              icon={'trash-alt'}
              size={'2x'}
              iconToolTipText={'Delete row'}
              color={'red'}
              onClick={() => editProps.helpers.remove(rowIndex)}
            />
          </div>
          : null}
      </>
    </Row>
  );

  const renderBottomRow = () => {
    if (editProps)
      return (
        <Row style={{display: 'flex', flex: '1', justifyContent: 'center', paddingTop: '10px', alignItems: 'center'}}>
          <div style={{marginBottom: '5px', marginRight: '10px'}}>
            Add a task
          </div>
          <IconButton icon={'plus'} size={'2x'} onClick={() =>
            // Get one higher than the max task number, task no. ensures they stay in order for rendering
            editProps.helpers.push(makeChecklistRow(Math.max(...renderedValues.rows.map(r => r.taskNumber), 1) + 1))}
          />
        </Row>
      );
    if (!renderedValues.rows.length)
      return (
        <Row style={{display: 'flex', flex: '1', justifyContent: 'center', paddingTop: '10px', alignItems: 'center'}}>
          No tasks available for this week.
        </Row>
      );
  };

  const renderFooter = () => {
    if (editProps)
      return (
        <Row style={{display: 'flex', flex: '1', justifyContent: 'center', paddingTop: '10px', alignItems: 'center'}}>
          <Input
            name={getWeekFieldName('reminder')}
            type={'textarea'}
            placeholder={'Place a custom message to be shown to staff when viewing this week'}
          />
        </Row>
      );
    return (
      <Row style={{display: 'flex', flex: '1', justifyContent: 'center', paddingTop: '10px', alignItems: 'center', fontWeight: 'bold'}}>
        {renderedValues.reminder}
      </Row>
    );
  };

  // sort row rendering by task number to keep order consistent when response is sent from backend
  return (
      <Col ref={ref} style={{padding: '0'}}>
        {renderDateHeader()}
        {[...renderedValues.rows]
          .sort((a, b) => a.taskNumber > b.taskNumber ? 1 : -1)
          .map((row, rowIndex) => renderChoreRow(row, rowIndex))}
        {renderBottomRow()}
        {renderParticipantConfirmationDialogue()}
        {renderFooter()}
      </Col>
  );
});

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    completeItem: checklistWeekStore.actions.updateChoreCompletion
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state),
  shelterStaff: userStore.selectors.getShelterStaff(state),
  getChoreChartRow: checklistWeekStore.selectors.getWeek(state),
  currentUser: userStore.selectors.getCurrentUser(state),
  isAdministrator: userStore.selectors.isAdministrator(state)
});
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(StaffChecklistCalendar);
