import {mapRoleToProps, mapRoleToPropsType, userStore} from '../../../redux/web/entities/user';
import {useLocation} from 'react-router-dom';
import {Navigate} from 'react-router';
import {RoutePaths} from '../../../router/RoutePaths';
import {connect} from 'react-redux';
import React from 'react';
import {WebState} from '../../../redux/core/types/WebState';
import {participantClassStore} from '../../../redux/web/entities/participantClass';
import {caseFileStore} from '../../../redux/web/entities/caseFile';

const allowedRoles = ['Administrator', 'Staff', 'Shelter Staff'];

type CaseFileRouteProps = ReturnType<typeof mapStateToProps> & {
  children: JSX.Element;
};

const CaseFileRouteComponent = ({children, currentUser, roleName}: CaseFileRouteProps) => {
  const location = useLocation();
  const locationUserId = location.pathname.split('/')[2];
  return (
    allowedRoles.includes(roleName) || locationUserId === currentUser?.id ? children : (
      <Navigate to={RoutePaths.dashboard} state={{ from: location }} replace={true}/>
    )
  );
};

const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  roleName: mapRoleToProps(state).roleName
});

export const CaseFileRoute = connect(mapStateToProps)(CaseFileRouteComponent);
