import {Alert, Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {Form, Formik} from 'formik';
import {DashboardMessage, EDashboardMessageStyle} from '../../../../../types/DashboardTypes';
import React, {useState} from 'react';
import {StandardInputColumn, StandardTopPaddedRow} from '../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import Select from 'react-select';
import {convertToDropDownOptions} from '../../../../../redux/util';
import styles from './DashboardMessageModal.module.scss';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../redux/util/http';
import {makeDashboardMessage} from '../../../../../redux/web/factory';

interface Props {
  messageData?: DashboardMessage;
  onSubmit: (data: DashboardMessage) => void;
  onCancel: () => void;
}

const messageStyleDropDownOptions = convertToDropDownOptions([
  {id: EDashboardMessageStyle.info, name: 'Info'},
  {id: EDashboardMessageStyle.success, name: 'Success'},
  {id: EDashboardMessageStyle.warning, name: 'Warning'},
  {id: EDashboardMessageStyle.danger, name: 'Danger'}
]);

const DashboardMessageModal = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={props.onCancel} variant={'danger'} className={styles['close-button']}>
            {'Cancel'}
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };


  return (
    <Modal show={true} size={'lg'} centered={true}>
      <Modal.Body>
        <Modal.Title>Edit Dashboard Message</Modal.Title>
        <Formik<DashboardMessage>
          initialValues={props.messageData ? {...props.messageData} : makeDashboardMessage()}
          onSubmit={() => undefined}
        >
          {({values, setFieldValue}) => {
            return (
              <Form
                noValidate={false}
                onSubmit={(e) => {
                  e.persist();
                  e.preventDefault();
                  try {
                    props.onSubmit(values);
                  } catch (e: AxiosError | any) {
                    setErrorMessage(getErrorResponseMessage(e));
                  }
                setIsSubmitting(false);
                }}
              >
                <Col>
                  <StandardTopPaddedRow>
                    <StandardInputColumn label={'Message'}>
                      <Input name={'text'} type={'textarea'} rows={8}/>
                    </StandardInputColumn>
                  </StandardTopPaddedRow>
                  <StandardTopPaddedRow>
                    <StandardInputColumn label={'Variant'}>
                      <Select
                        name={'dashboardMessageStyle'}
                        options={messageStyleDropDownOptions}
                        defaultValue={messageStyleDropDownOptions.find(val => val.value === values.dashboardMessageStyle)}
                        onChange={(option) => setFieldValue('dashboardMessageStyle', option?.value ?? '')}
                        isClearable={false}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible' }) }}
                        menuPortalTarget={document.querySelector('body')}
                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        className={styles['select']}
                      />
                    </StandardInputColumn>
                  </StandardTopPaddedRow>
                  {errorMessage !== '' ?
                    <div style={{marginTop: '1rem'}}>
                      <Alert variant='danger'>{errorMessage}</Alert>
                    </div>
                    : null}
                  <Row style={{paddingTop: '10px'}}>
                    <BSForm.Group style={{marginLeft: 'auto', marginRight: '10px', borderRadius: '10px', minWidth: '80px'}}>
                      {renderButtons()}
                    </BSForm.Group>
                  </Row>
                </Col>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default DashboardMessageModal;
