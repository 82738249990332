export enum EDashboardMessageStyle {
  info,
  success,
  warning,
  danger
}

export interface DashboardMessage {
  id: string;
  text: string;
  dashboardMessageStyle: EDashboardMessageStyle;
  roleId: string;
}

export interface DashboardParticipantData {
  participantId: string;
  participantName: string;
  admissionDate: Date;
  archived: boolean;
}

export interface DashboardTask {
  id: string;
  userId?: string;
  assignedBy?: string;
  name: string;
  description: string;
  complete: boolean;
}

export interface DashboardDocumentData {
  id: string;
  name: string;
  caseFileId: string;
  userId: string;
  date: Date | null;
}

export interface DashboardClass {
  id: string;
  name: string;
  description: string;
  date: Date;
}

export interface StaffDashboardState {
  participantsThisWeek: DashboardParticipantData[];
  participantsThisMonth: DashboardParticipantData[];
  participantsThisYear: DashboardParticipantData[];
  participants: DashboardParticipantData[];
  documentsAwaitingSignature: DashboardDocumentData[];
  message?: DashboardMessage;
  tasks: DashboardTask[];
}

export interface ParticipantDashboardState {
  message?: DashboardMessage;
  tasks: DashboardTask[];
  documentsAwaitingSignature: DashboardDocumentData[];
  classes: DashboardClass[];
}
