import {Route, RouteProps, Navigate} from 'react-router';
import React from 'react';
import {
  mapIsAdministratorOrStaffToProps,
  mapIsAdministratorToProps,
  mapIsAdministratorToPropsType,
  mapIsAuthenticatedToProps,
  mapIsAuthenticatedToPropsType
} from '../../../redux/web/entities/user';
import {connect} from 'react-redux';
import { useLocation } from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';

type AdministratorRouteProps = mapIsAdministratorToPropsType & {
  children: JSX.Element;
};


function AdministratorOrStaffComponent({children, administrator}: AdministratorRouteProps) {
  return administrator ? children : null;
}

export default connect(mapIsAdministratorOrStaffToProps)(AdministratorOrStaffComponent);
