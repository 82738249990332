import React, {useState} from 'react';
import {connect} from 'react-redux';
import {WebState} from '../../../redux/core/types/WebState';
import {bindActionCreators, Dispatch} from 'redux';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {handleAxiosError} from '../../../redux/util/http';
import {loadUserManagementData} from '../../../redux/web/stateResponses/stateManagement';
import {AxiosError} from 'axios';
import {useMount} from '../../../hooks/useMount';
import ParticipantGrid from './components/ParticipantGrid';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function CaseFileManagement(props: Props) {
  const {actions: {loadUserData}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useMount(async () => {
    try {
      await loadUserData();
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load users'}));
    }
    setLoading(false);
  });

  return (
      <div>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
            <ParticipantGrid/>
        )}
      </div>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadUserData: loadUserManagementData
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CaseFileManagement);
