import React, {useState} from 'react';
import Input from '../../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {ArrayHelpers, FieldArray, FormikValues, useField} from 'formik';
import {
  StandardCheckboxInput,
  StandardInputColumn,
  StandardInputRow
} from '../../../../../../../../../components/util/form-components/standardLayout';
import {ListTable} from '../../../../../../../../../components/util/lists/ListTable/ListTable';
import {DeletableListRow} from '../../../../../../../../../components/util/lists/ListTable/ListTableRow';
import {CaseManagementNote} from '../../../../../../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import IconButton from '../../../../../../../../../components/util/widgets/IconButton/IconButton';
import {makeCaseManagementNote} from '../../../../../../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import DatepickerInput
  from '../../../../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {Col, Row} from 'react-bootstrap';
import fieldArrayStyles from '../../../../ParticipantFormFieldArrayStyles.module.scss';
import {FieldArrayRow} from '../../../../../../../../../components/util/form-components/fieldArray/DeletableFieldArray/FieldArrayRow';

export type TCaseManagementCaseNoteEntry = Omit<CaseManagementNote, 'id' | 'date'> & {date: Date};

interface Props {
  caseNotes: CaseManagementNote[];
  editable: boolean;
  fieldPrefix: string;
}

const getFieldName = (name: keyof CaseManagementNote) => name;

export const CaseNotesFieldArray = (props: Props) => {
  const {fieldPrefix, caseNotes, editable} = props;
  const generateFieldName = (index: number) => `${fieldPrefix}.${index}`;
  const renderHeader = (helpers: ArrayHelpers) => (
    <Col style={{width: '100%', marginBottom: '10px'}}>
      <Row>
        <h5>Case Management Notes</h5>
        <div style={{marginLeft: '0.5rem', marginTop: '1px', display: 'flex', flex: '1', textAlign: 'right'}}>
          <IconButton
            icon={'plus'}
            styles={{width: '100%', textAlign: 'right'}}
            size={'lg'}
            onClick={() => {
              helpers.insert(0, makeCaseManagementNote());
             //  const elements = document.getElementsByClassName('case-note');
             // if (elements.length === 0) return;
             //  elements[elements.length - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }}
          />
        </div>
      </Row>
    </Col>
  );

  return (
    <FieldArray
      name={fieldPrefix}
      validateOnChange={false}
      render={(helpers) => {
        return (
          <ListTable
            style={{marginBottom: '1rem'}}
            noItemsLabel={'No case note entries added'}
            items={caseNotes}
            className={fieldArrayStyles['list-row']}
            renderHeader={() => renderHeader(helpers)}
            renderItem={(item, index) => (
              <DeletableListRow
                iconSize={'2x'}
                key={generateFieldName(index)}
                className={'case-note'}
                deleteColumnSize={1}
                style={{flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'visible'}}
                render={() => (
                  <CaseNoteRow
                    entryPrefix={generateFieldName(index)}
                    editable={editable}
                    caseNote={item}
                  />
                )}
                editable={editable}
                onDelete={() => helpers.remove(index)}
              />
            )}
          />
        );
      }}
    />
  );
};

type CaseNoteRowProps = {
  editable: boolean;
  caseNote: CaseManagementNote;
  entryPrefix: string;
};
function CaseNoteRow({caseNote, entryPrefix, editable}: CaseNoteRowProps) {
  const [field, {value, error, touched}] = useField(`${entryPrefix}.${getFieldName('createdAt')}`);
  return  (
    <FieldArrayRow style={{flex: 'auto', minWidth: '13rem', marginRight: '1.5rem'}}>
      <StandardInputColumn label={'Date'} columnSize={2} className='pl-2'>
        <DatepickerInput
          name={`${entryPrefix}.${getFieldName('date')}`}
          showYearDropdown={true}
          ignoreOverflow={true}
          allowFuture={true}
          disabled={false}
          onChangeUtcHour={12}
        />
        <StandardInputColumn>
          {value !== undefined ?
            <div style={{display: 'flex', flexDirection: 'column', fontSize: '.75rem', whiteSpace: 'nowrap'}}>
              <span> Created: {new Date(value).toLocaleDateString()}</span>
            </div>
            : null}
        </StandardInputColumn>
      </StandardInputColumn>
      <StandardInputColumn label={'Description'} columnSize={5} className='pl-2'>
        <Input name={`${entryPrefix}.${getFieldName('shortDescription')}`} type={'textarea'} rows={5}/>
      </StandardInputColumn>
      <StandardInputColumn label={'Comments'} columnSize={5} className='pl-2'>
        <Input name={`${entryPrefix}.${getFieldName('longDescription')}`} type={'textarea'} rows={5}/>
      </StandardInputColumn>
    </FieldArrayRow>
  );
}
