import {array, date, mixed, object, string} from 'yup';
import {createAllObjectShapes} from '../../../../../../redux/util/yup';
import {
  makeAssessmentQuestions,
  makeClosingInformation,
  makeHumanTraffickingInformation,
  makeParProgramQuestions,
  makePersonalInformation,
  makePrimaryIntakeForm,
  makeSecondaryIntake,
  makeSecondaryIntakeArrest,
  makeSecondaryIntakeChildNameAndAge,
  makeSecondaryIntakeCriminalAndHousing,
  makeSecondaryIntakeEducationAndEmployment,
  makeSecondaryIntakeFamilyAndMedicalHistory,
  makeSecondaryIntakeHouseholdResident,
  makeSecondaryIntakeMedication,
  makeSecondaryIntakePersonalEmergencyInformationAndLifeIssues,
  makeSecondaryIntakePreviousEmployer,
  makeSecondaryIntakeSubstanceAbuseAndTraffickingInformation
} from '../../../../../../redux/web/factory/forms/intakeForms';
import {
  SecondaryIntake,
  SecondaryIntakeCriminalAndHousing,
  SecondaryIntakeEducationAndEmployment,
  SecondaryIntakeFamilyAndMedicalHistory,
  SecondaryIntakePersonalEmergencyInformationAndLifeIssues,
  SecondaryIntakeSubstanceAbuseAndTraffickingInformation
} from '../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {
  ESecondaryIntakeCurrentHealthStatus
} from '../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntakeHelperModels';

const getFieldName = (name: keyof SecondaryIntake) => name;
const getPersonalEmergencyLifeFieldName = (name: keyof SecondaryIntakePersonalEmergencyInformationAndLifeIssues) => name;
const getFamilyMedicalFieldName = (name: keyof SecondaryIntakeFamilyAndMedicalHistory) => name;
const getEducationEmploymentFieldName = (name: keyof SecondaryIntakeEducationAndEmployment) => name;
const getCriminalHousingFieldName = (name: keyof SecondaryIntakeCriminalAndHousing) => name;
const getSubstanceAbuseFieldName = (name: keyof SecondaryIntakeSubstanceAbuseAndTraffickingInformation) => name;

// @ts-ignore
export const SecondaryIntakeModalSchema = object<SecondaryIntake>(
  createAllObjectShapes(makeSecondaryIntake(''), undefined, {
    [getFieldName('id')]: string().nullable(),
    [getFieldName('userId')]: string().nullable(),
    [getFieldName('personalEmergencyInformationAndLifeIssues')]:
      object({...createAllObjectShapes(makeSecondaryIntakePersonalEmergencyInformationAndLifeIssues(),
          undefined, {



        [getPersonalEmergencyLifeFieldName('middleInitial')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('primaryPhone')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('secondaryPhone')]: string().nullable(),


        [getPersonalEmergencyLifeFieldName('ecFirstName')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecLastName')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecRelationship')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecOtherNames')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecStreetAddress')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecApartmentOrUnit')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecCity')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecState')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecZipCode')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecPrimaryPhone')]: string().nullable(),
        [getPersonalEmergencyLifeFieldName('ecSecondaryPhone')]: string().nullable(),


        [getPersonalEmergencyLifeFieldName('email')]: string().nullable().email('Email must be in a valid format.'),
        [getPersonalEmergencyLifeFieldName('childrenNamesAndAges')]: array().when(
          getPersonalEmergencyLifeFieldName('hasChildren'), {
            is: true,
            then: array().of(
              object({...createAllObjectShapes(makeSecondaryIntakeChildNameAndAge())})
            ).min(1, 'Your children must be listed.'),
            otherwise: array().required()
          }),
        [getPersonalEmergencyLifeFieldName('drugsUsedDate')]: string().when(
          getPersonalEmergencyLifeFieldName('hasUsedDrugsOrAlcohol'), {
            is: true,
            then: string().nullable(),
            otherwise: string().nullable()
          }),
        [getPersonalEmergencyLifeFieldName('arrestDatesAndDescriptions')]: array().when(
          getPersonalEmergencyLifeFieldName('hasBeenArrested'), {
            is: true,
            then: array().of(
              object({...createAllObjectShapes(makeSecondaryIntakeArrest())})
            ).min(1, 'Arrest dates and descriptions must be listed.'),
            otherwise: array().required()
          })}
        )}),
    [getFieldName('substanceAbuseAndTraffickingInformation')]:
      object({...createAllObjectShapes(makeSecondaryIntakeSubstanceAbuseAndTraffickingInformation(),
          undefined, {
            [getSubstanceAbuseFieldName('parentsUsedDrugsDescription')]: string().nullable(),
            [getSubstanceAbuseFieldName('wasInFosterCaseLength')]: string().nullable()
          }
        )}),
    [getFieldName('familyAndMedicalHistory')]:
      object({...createAllObjectShapes(makeSecondaryIntakeFamilyAndMedicalHistory(),
          undefined, {
        [getFamilyMedicalFieldName('medications')]: array().when(
          `${getFamilyMedicalFieldName('isOnMedications')}`, {
            is: true,
            then: array().of(
              object({...createAllObjectShapes(makeSecondaryIntakeMedication())})
            ).min(1, 'Medications you are currently on must be listed.'),
            otherwise: array().required()
          }),
        [getFamilyMedicalFieldName('currentHealthStatus')]: mixed().oneOf(Object.values(ESecondaryIntakeCurrentHealthStatus)),
        [getFamilyMedicalFieldName('isPregnantDueDate')]: string().when(
          getFamilyMedicalFieldName('isPregnant'), {
            is: true,
            then: string().required(`Is Pregnant Due Date is a required field. If no response is given, please put "N/A."`)
              .typeError(`Is Pregnant Due Date is a required field. If no response is given, please put "N/A."`),
            otherwise: string().nullable()
          }),
          [getFamilyMedicalFieldName('areNotBiologicalParentsDescription')]: string().nullable(),
          [getFamilyMedicalFieldName('hasHadTbTestWhenAndWhere')]: string().nullable(),
          [getFamilyMedicalFieldName('hasPhysicalLimitationsDescription')]: string().nullable()
        }
        )}),
    [getFieldName('educationAndEmployment')]:
      object({...createAllObjectShapes(makeSecondaryIntakeEducationAndEmployment(),
          undefined, {
        [getEducationEmploymentFieldName('previousEmployers')]: array().required().of(
          object({...createAllObjectShapes(makeSecondaryIntakePreviousEmployer())})
        ),
        [getEducationEmploymentFieldName('dateGraduatedFromTradeSchool')]: string().when(
          getEducationEmploymentFieldName('hasGraduatedTradeSchool'), {
            is: true,
            then: string().required(`Date Graduated From Trade School is a required field. If no response is given, please put "N/A."`)
              .typeError(`Date Graduated From Trade School is a required field. If no response is given, please put "N/A."`),
            otherwise: string().nullable()
          }),
          [getEducationEmploymentFieldName('highestLevelOfEducationCompleted')]: string().nullable()
          }
        )}),
    [getFieldName('criminalAndHousing')]:
      object({...createAllObjectShapes(makeSecondaryIntakeCriminalAndHousing(),
          undefined, {
            [getCriminalHousingFieldName('householdResidents')]: array().of(
              object({...createAllObjectShapes(makeSecondaryIntakeHouseholdResident())})
            ),
            [getCriminalHousingFieldName('homelessLength')]: string().nullable()
          }
        )})
  })
);
