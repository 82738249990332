import {useMount} from './useMount';

export const useCaseFileCache = (currentUserId: string, userIdToCache?: string) => {
  const cacheName = `CaseFileVisits-${currentUserId}`;

  useMount(() => {
    if (userIdToCache) {
      const existingCache = localStorage.getItem(cacheName);
      // Fresh cache
      if (!existingCache)
        localStorage.setItem(cacheName, JSON.stringify([userIdToCache]));
      else {
        const cache = JSON.parse(existingCache) as string[];
        const foundIndex = cache.indexOf(userIdToCache);
        // if exists, remove index and then append to end
        if (foundIndex >= 0) {
          cache.splice(foundIndex, 1);
          const updatedCache = [...cache, userIdToCache];
          localStorage.setItem(cacheName, JSON.stringify(updatedCache));
        // else if at length cutoff, remove first element of array and append current value
        } else if (cache.length > 3) {
          const updatedCache = [...cache.slice(1, 4), userIdToCache];
          localStorage.setItem(cacheName, JSON.stringify(updatedCache));
        // else, append to end
        } else
          localStorage.setItem(cacheName, JSON.stringify([...cache, userIdToCache]));
      }
    }
  });

  const jsonCache = localStorage.getItem(cacheName);
  if (jsonCache)
    return JSON.parse(jsonCache) as string[];
  return null;
};
