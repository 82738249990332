import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {
  createDictionaryReducerSelectors,
  createStandardSelectors,
  getEntities,
  selector
} from '../selectors';
import {CommonDispatch, CommonState} from '../index';
import {
  deleteParticipantClass,
  getCurrentUserParticipantClasses,
  upsertParticipantClass
} from '../../../api/participantClassApi';
import {createAction, createReducer} from 'typesafe-actions';
import {Dictionary} from '../../util';

import {parseISO} from 'date-fns';
import {now} from '../../../util';

export interface ParticipantClassAssignment {
  participantId: string;
  participantName: string;
  attendance: boolean;
}

export interface ParticipantClass {
  id: string;
  name: string;
  startDateTime: string;
  endDateTime: string;
  description: string;
  participantClassAssignments: ParticipantClassAssignment[];
}

export interface ParticipantClassState {
  currentUserParticipantClasses: Dictionary<ParticipantClass>;
  items: Dictionary<ParticipantClass>;
}

const currentUserParticipantClassActions = {
  setCurrentUserParticipantClasses: createAction('PARTICIPANT_CLASS/SET_CURRENT_USER_PARTICIPANT_CLASSES')<Dictionary<ParticipantClass>>()
};

const deleteParticipantClassAction = createAction('PARTICIPANT_CLASS/DELETE')<string>();
const actions = createStandardActions(placeholder<ParticipantClass>(), 'PARTICIPANT_CLASS/SET', 'PARTICIPANT_CLASS/SAVE');
type CurrentUserParticipantClassActions = GetActions<typeof currentUserParticipantClassActions>;

const selectors = createStandardSelectors(placeholder<ParticipantClass>(), s => getEntities(s).participantClasses);
const currentUserSelectors = createDictionaryReducerSelectors(placeholder<ParticipantClass>(),
    s => getEntities(s).participantClasses.currentUserParticipantClasses);

export type StandardParticipantClassActions = GetActions<typeof actions> | ReturnType<typeof deleteParticipantClassAction>;
export const participantClasses = combineReducers<ParticipantClassState>({
  currentUserParticipantClasses: createReducer<Dictionary<ParticipantClass>, CurrentUserParticipantClassActions>({})
    .handleAction(currentUserParticipantClassActions.setCurrentUserParticipantClasses,
      (state, action) => action.payload),
  items: standardItemsReducer<ParticipantClass, StandardParticipantClassActions>(actions)
    .handleAction(deleteParticipantClassAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload as any];
      return newState;
    })
});


// TODO fix ts ignore, it is saying it expects user type but behaves correctly?
export const participantClassStore = readonly({
  selectors: {
    ...selectors
  },
  currentUserSelectors: {
    ...currentUserSelectors,
    currentUserPastParticipantClasses: selector(s => currentUserSelectors.getAsArray(s)
      .filter((pc: ParticipantClass) => parseISO(pc.startDateTime).getDay() < now.getDay())),
    currentUserFutureParticipantClasses: selector(s => currentUserSelectors.getAsArray(s)
      .filter((pc: ParticipantClass) => parseISO(pc.startDateTime).getDay() >= now.getDay()))
  },
  actions: {
    ...actions,
    ...currentUserParticipantClassActions,
    loadCurrentUser: () => async (dispatch: CommonDispatch) => {
      const response = await getCurrentUserParticipantClasses();
      // @ts-ignore
      await dispatch(participantClassStore.actions.setCurrentUserParticipantClasses(response));
    },
    upsert: (pc: ParticipantClass) => async (dispatch: CommonDispatch) => {
      const response = await upsertParticipantClass(pc);
      dispatch(participantClassStore.actions.save(response));
    },
    delete: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await deleteParticipantClass(id);
      dispatch(deleteParticipantClassAction(response));
    }
  }
});
