import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';
import styles from '../CaseFile.module.scss';
import {Form, Formik, FormikErrors} from 'formik';
import {Alert, Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {AxiosError} from 'axios';

import {bindActionCreators, Dispatch} from 'redux';
import {InputRow} from '../../../components/util/form-components/InputRow';
import Input from '../../../components/util/form-components/formik-inputs/Input/Input';
import {
  CaseFileDocument,
  CaseFileDocumentStatus,
  caseFileDocumentStore
} from '../../../redux/web/entities/caseFileDocument';
import {CaseFileDocumentModalSchema} from './CaseFileDocumentModalSchema';
import {makeCaseFileDocument} from '../../../redux/web/factory';
import {getBase64} from '../../../util';
import {WebState} from '../../../redux/core/types/WebState';
import {roleStore} from '../../../redux/web/entities/role';
import {convertToDropDownOptions} from '../../../redux/util';
import {handleAxiosError} from '../../../redux/util/http';
import {useLocation} from 'react-router-dom';
import {caseFileStore} from '../../../redux/web/entities/caseFile';
import CheckBoxInput from '../../../components/util/form-components/formik-inputs/CheckBoxInput/CheckBoxInput';

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  editable: boolean;
  onTop?: boolean;
  existingCaseFileDocument?: CaseFileDocument;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function CaseFileDocumentModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel, rolesOptions, getCaseFileIdByUserId,
    existingCaseFileDocument, actions: {upsertCaseFileDocument}} = props;
  const getFieldName = (name: keyof CaseFileDocument) => name;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documentFileData, setDocumentFileData] = useState('');
  const [documentFileError, setDocumentFileError] = useState('');
  const location = useLocation();

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: CaseFileDocument,
    validate: (values: CaseFileDocument) => Promise<FormikErrors<CaseFileDocument>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    setDocumentFileError('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    association.data = documentFileData;
    association.caseFileId = getCaseFileIdByUserId(location.pathname.split('/')[2]);
    if(association.id === undefined && association.data === '') {
      setDocumentFileError('You must select a file');
    }
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        await upsertCaseFileDocument(association);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e));
      }
    }
    setIsSubmitting(false);
  };


  const displayWetSignatureProvided = () => {
    if (existingCaseFileDocument?.status === CaseFileDocumentStatus.Signed) {
      return null;
    }

    if(existingCaseFileDocument?.data !== undefined && !existingCaseFileDocument?.data.includes('data:application/pdf')) {
      return null;
    }

    return (
      <InputRow label={''} columnSize={5} labelSize={2} style={{paddingTop: '10px'}} requiredAsterisk={false}>
        <CheckBoxInput label={'Wet Signature Provided?'} type={'checkbox'} name={getFieldName('wetSignatureProvided')}/>
      </InputRow>
    );
  };


  const renderButtons = (values: any, validateForm: any, handleSubmit: any) => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']} style={{marginRight: '1rem'}}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='button'
                                             onClick={(e: any) => processSubmit(e, values, validateForm, handleSubmit)}>Upload</Button> : null}
      </>
    );
  };


  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={existingCaseFileDocument !== null ? existingCaseFileDocument as CaseFileDocument : makeCaseFileDocument(null)}
                onSubmit={() => undefined} validationSchema={CaseFileDocumentModalSchema}>
          {({values, validateForm, handleSubmit, errors}) => {
            return (
              <Form noValidate={false}
                    onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>{existingCaseFileDocument?.id !== '' ? 'Update Document' : 'Add Document'}</Modal.Title>
                <InputRow label={'Name'} columnSize={5} labelSize={2} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('name')}/>
                </InputRow>
                <InputRow label={'Upload file'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input
                    name={'file'}
                    type={'file'}
                    disabled={existingCaseFileDocument && existingCaseFileDocument.status !== CaseFileDocumentStatus.None}
                    setFormDataState={async (file: File) => {
                      if(file) {
                        setDocumentFileData(await getBase64(file));
                        setDocumentFileError('');
                      }
                      else
                        setDocumentFileData('');
                    }}
                  />
                  <div className={styles['form-errors']}>{documentFileError}</div>
                </InputRow>
                {displayWetSignatureProvided()}
                {errorMessage !== '' ?
                  <div style={{marginTop: '1rem'}}>
                    <Alert variant='danger'>{errorMessage}</Alert>
                  </div>
                  : null}
                <Row style={{float: 'right'}}>
                  <BSForm.Group className={styles['form-buttons']}>
                    {renderButtons(values, validateForm, handleSubmit)}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertCaseFileDocument: caseFileDocumentStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  rolesOptions: convertToDropDownOptions(roleStore.selectors.getAsArray(state), 'roleName'),
  getCaseFileIdByUserId: caseFileStore.selectors.getCaseFileIdByUserId(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(CaseFileDocumentModal);
