import React, {useEffect, useMemo, useState} from 'react';
import gridStyles from '../../../../GridStyles.module.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/core/types/WebState';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import {Button, Modal, Row} from 'react-bootstrap';
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.js';
import {makeBtcDocument} from '../../../../../redux/web/factory';
import {Navigate} from 'react-router-dom';
import {BtcDocument, btcDocumentStore} from '../../../../../redux/web/entities/btcDocument';
import DocumentModal from '../UserModal/DocumentModal';
import styles
  from '../../../ParticipantClassManagement/components/ParticipantClassGrid/ParticipantClassGrid.module.scss';
import {mapRoleToProps, User, userStore} from '../../../../../redux/web/entities/user';
import {getDocumentDataByid} from '../../../../../api/documentManagementApi';
import {downloadDocument} from '../../../../../util';
import {CaseFileDocument, CaseFileDocumentStatus} from "../../../../../redux/web/entities/caseFileDocument";
import GcPdfViewerComponent from "../../../../../components/util/gcPdfViewer";
import {AxiosError} from "axios";
import {handleAxiosError} from "../../../../../redux/util/http";
import {loadDocumentManagementData} from "../../../../../redux/web/stateResponses/stateManagement";
import {getCaseFileDocumentDataById} from "../../../../../api/caseFileManagementApi";

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function DocumentGrid(props: Props) {
  const {btcDocuments, getBtcDocumentById, userRole, currentUser, actions: {upsertBtcDocument, deleteBtcDocument, loadDocumentData}} = props;
  const { SearchBar } = Search;
  const [isViewingPdf, setIsViewingPdf] = useState('');
  const [currentDocument, setCurrentDocument] = useState('' as any as BtcDocument);
  const [isViewingImage, setIsViewingImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState('');
  const [viewBtcDocumentModal, setViewBtcDocumentModal] = useState(false);
  const [editingBtcDocument, setEditingBtcDocument] = useState(makeBtcDocument(null));
  const [redirectUrl, setRedirectUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const getFieldName = (name: keyof BtcDocument) => name;

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const isReadOnlyDocument = (document: BtcDocument) => userRole !== 'Administrator';

  const getDocumentType = (document: BtcDocument): 'pdf' | 'image' | 'other' => {
    if (document.data.includes('data:application/pdf')) return 'pdf';
    if (document.data.includes('data:image')) return 'image';
    return 'other';
  };

  const renderDocumentEditButton = (document: BtcDocument) => {
    const documentType = getDocumentType(document);
    console.log(documentType);
    if (documentType === 'pdf') {
      return (
        <div>
          <IconButton
            icon='eye'
            size={'2x'}
            styles={{marginLeft: '.5rem'}}
            onClick={async () => {
              await getDocumentDataByid(document.id).then((result) => {
                setCurrentDocument('' as any as BtcDocument);
                setIsViewingPdf('');
                setIsViewingPdf(result.data);
                setCurrentDocument(document);
              });
            }}
            color={'#005A9C'}
            iconToolTipText={'View / Edit'}
          />
        </div>
      );
    }
    if (documentType === 'image') {
      return (
        <div>
          <IconButton
            icon='eye'
            size={'2x'}
            styles={{marginLeft: '.5rem'}}
            onClick={async () => {
              await getCaseFileDocumentDataById(document.id).then((result) => {
                setCurrentDocument('' as any as BtcDocument);
                setIsViewingImage('');
                setIsViewingImage(result.data);
                setCurrentDocument(document);
              });
            }}
            color={'#005A9C'}
            iconToolTipText={'View'}
          />
        </div>
      );
    }
    return null;
  };

  const actionsFormatter = (cell: any, document: BtcDocument) => {
    return (
      <>
        <div className={gridStyles['table-actions-wrapper']}>
          {renderDocumentEditButton(document)}
          <div>
            <IconButton
              icon='edit'
              iconToolTipText={'Edit'}
              size={'2x'}
              styles={{marginLeft: '.5rem'}}
              onClick={() => {
                setEditingBtcDocument(getBtcDocumentById(document.id));
                setViewBtcDocumentModal(true);
              }}
              color={'#005A9C'}
            />
          </div>
          <div>
            <IconButton
              icon='download'
              iconToolTipText={'Download'}
              size={'2x'}
              styles={{marginLeft: '.5rem'}}
              onClick={ () => {
                getDocumentDataByid(document.id).then((result) => {
                  downloadDocument(result.data, result.name);
                });
              }}
              color={'#005A9C'}
            />
          </div>
          <div>
            <IconButton
              icon='trash-alt'
              size={'2x'}
              styles={{color: 'red', marginLeft: '.5rem'}}
              onClick={() => deleteBtcDocument(document)}
              iconToolTipText={'Delete'}
            />
          </div>
        </div>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      searchable: false,
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      searchable: false,
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '7rem'};
      },
      style: () => {
        return { width: '7rem'};
      }
    }
  ];

  return (
    <ToolkitProvider
      keyField='id'
      data={btcDocuments}
      columns={columns}
      search={{
        searchFormatted: true
      }}
    >
      {(tableProps: {
        baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<User, number>> & Readonly<BootstrapTableProps>;
        searchProps: SearchProps<BtcDocument>;
      }) => (
        <>
          <div className={gridStyles['button-groups-container']}>
            <div className={gridStyles['button-group']}>
              <Button
                onClick={() => {
                  setEditingBtcDocument(makeBtcDocument(null));
                  setViewBtcDocumentModal(true);
                }}
                style={{backgroundColor: '#005A9C'}}
              >
                Add Document
              </Button>
            </div>
          </div>
          <SearchBar {...tableProps.searchProps} placeholder={'Search'} />
          {renderRedirect()}
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={styles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
          {viewBtcDocumentModal ?
            <DocumentModal
              editable={true}
              onSubmit={() => {
                setViewBtcDocumentModal(false);
              }}
              onCancel={() => setViewBtcDocumentModal(false)}
              existingBtcDocument={editingBtcDocument}
            />
            : null}
          {isViewingPdf !== '' && (
            <>
              <Modal style={{zIndex: '1000'}} backdropClassName={''} show={true} size='xl' centered={true} onHide={() => null}>
                <Modal.Body>
                  <Row>
                    <Button onClick={() => {
                      setCurrentDocument('' as any as BtcDocument);
                      setIsViewingPdf('');
                    }} variant={'info'} style={{marginRight: '5px', marginBottom: '10px'}}>Close</Button>
                    <GcPdfViewerComponent
                      document={`${isViewingPdf}`}
                      disableAutomaticSaving={true}
                      apiEndpointName={'BtcDocumentSupportApi'}
                      onSave={async () => {
                        setCurrentDocument('' as any as BtcDocument);
                        setIsViewingPdf('');
                        setLoading(true);
                        try {
                          await loadDocumentData();
                        } catch (e: AxiosError | any) {
                          setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load Documents'}));
                        }
                        setLoading(false);
                      }}
                      currentCaseFile={currentDocument}
                      currentUserId={currentUser?.id}
                      readOnly={isReadOnlyDocument(currentDocument)}
                    />
                  </Row>
                </Modal.Body>
              </Modal>
            </>
          )}
          {isViewingImage !== '' && (
            <>
              <Modal backdropClassName={''} show={true} size='xl' centered={true} onHide={() => null}>
                <Modal.Body>
                  <Row>
                    <Button
                      onClick={() => {
                        setCurrentDocument('' as any as BtcDocument);
                        setIsViewingImage('');}}
                      variant={'info'}
                      style={{margin: '0 1rem 10px 5px'}}
                    >
                      Close
                    </Button>
                  </Row>
                  <Row style={{justifyContent: 'center'}}>
                    <img src={isViewingImage} style={{width: '100%', height: 'auto', padding: '0 5px', borderRadius: '1%'}}/>
                  </Row>
                </Modal.Body>
              </Modal>
            </>
          )}
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertBtcDocument: btcDocumentStore.actions.upsert,
    deleteBtcDocument: btcDocumentStore.actions.delete,
    loadDocumentData: loadDocumentManagementData
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  btcDocuments: btcDocumentStore.selectors.getAsArray(state),
  getBtcDocumentById: btcDocumentStore.selectors.getById(state),
  userRole: mapRoleToProps(state).roleName,
  currentUser: userStore.selectors.getCurrentUser(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentGrid);
