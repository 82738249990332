import {format} from 'date-fns-tz';
import {localTz} from '../../../../util';
import {DashboardParticipantData} from '../../../../types/DashboardTypes';
import InformationLabel from '../../../../components/util/form-components/InformationLabel/InformationLabel';
import DashboardCard from '../DashboardCard/DashboardCard';
import React from 'react';

interface Props {
  participantsThisWeek: DashboardParticipantData[];
  participantsThisMonth: DashboardParticipantData[];
  participantsThisYear: DashboardParticipantData[];
}

const DashboardParticipantAdmissions = (props: Props) => {
  const {participantsThisWeek, participantsThisMonth, participantsThisYear} = props;

  const dateTimeFormatter = (localDate: Date) => {
    const isoTime = format(localDate, 'p', {timeZone: localTz as any as string});
    const isoDate = format(localDate, 'MM/dd/yyyy');
    return `${isoDate} ${isoTime}`;
  };

  const renderParticipantAdmissionSummary = (label: string, records?: DashboardParticipantData[]) => (
    <InformationLabel label={label} labelSize={10} dataSize={2} data={records?.length ?? 0}/>
  );

  const renderParticipantAdmissionDetails = (records?: DashboardParticipantData[]) => (
    records?.map((record: DashboardParticipantData) => (
      <>
        <InformationLabel key={`${record.participantId}Name`} label={'Name'} data={record.participantName}/>
        <InformationLabel key={`${record.participantId}Date`} label={'Date'} data={dateTimeFormatter(record.admissionDate)}/>
      </>
    ))
  );

  return (
    <DashboardCard title={'Participants Created'}>
        {renderParticipantAdmissionSummary('This Week', participantsThisWeek)}
        {renderParticipantAdmissionSummary('This Month', participantsThisMonth)}
        {renderParticipantAdmissionSummary('This Year', participantsThisYear)}
    </DashboardCard>
  );
};

export default DashboardParticipantAdmissions;
