import {User, userStore} from '../../../../../redux/web/entities/user';
import React, {FormEvent, useState} from 'react';
import {Form, Formik, FormikErrors} from 'formik';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../redux/util/http';
import {Alert, Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import styles from '../../../UserManagement/components/UserModal/UserModal.module.scss';
import {makeParticipantClass, makeParticipantClassModal, makeUser} from '../../../../../redux/web/factory';
import {UserModalSchema} from '../../../UserManagement/components/UserModal/UserModalSchema';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {DropdownInput} from '../../../../../components/util/form-components/formik-inputs/DropdownInput/DropdownInput';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/core/types/WebState';
import {convertToDropDownOptions} from '../../../../../redux/util';
import {roleStore} from '../../../../../redux/web/entities/role';
import {connect} from 'react-redux';
import {
  ParticipantClass,
  ParticipantClassAssignment,
  participantClassStore
} from '../../../../../redux/web/entities/participantClass';
import DatepickerInput
  from '../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import {ParticipantClassModalSchema} from './ParticipantClassModalSchema';
import {localTz, now, setDateHoursAndMinutes} from '../../../../../util';
import ParticipantClassUserSelection from './ParticipantClassUserSelection';
import {formatISO} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';

const labelSize = 3;

export interface ParticipantClassModalInterface {
  id?: string;
  name?: string;
  classDate: Date;
  startTime: string;
  endTime: string;
  description: string;
  participantIds: string[];
}

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  editable: boolean;
  onTop?: boolean;
  existingParticipantClass?: ParticipantClass;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function ParticipantClassModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel,
    rolesOptions, users, getUserById, participantClasses, existingParticipantClass, participants, actions: {upsertParticipantClass}} = props;
  const getFieldName = (name: keyof ParticipantClassModalInterface) => name;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setHoursAndMinutes = (date: Date, time: string) => {
    const parts = (time as any).split(':');
    date.setHours(parts[0]);
    date.setMinutes(parts[1]);
    return date;
  };

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: ParticipantClassModalInterface,
    validate: (values: ParticipantClassModalInterface) => Promise<FormikErrors<ParticipantClassModalInterface>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        const {classDate, startTime, endTime, ...rest} = association;
        const request: ParticipantClass = {
          ...rest,
          startDateTime: zonedTimeToUtc(setHoursAndMinutes(classDate as Date, startTime), localTz),
          endDateTime: zonedTimeToUtc(setHoursAndMinutes(classDate as Date, endTime), localTz),
          participantClassAssignments: association.participantIds.map((userId) => {
            const existingPca = participantClasses.find(pc => pc.id === association.id)?.participantClassAssignments.find(pca => pca.participantId === userId);
            return {
              participantId: userId,
              participantName: getUserById(userId).name,
              attendance: existingPca ? existingPca.attendance : false
            } as ParticipantClassAssignment;
          })
        } as any as ParticipantClass;
        upsertParticipantClass(request);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(getErrorResponseMessage(e));
      }
    }
    setIsSubmitting(false);
  };


  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };


  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={makeParticipantClassModal(existingParticipantClass)} validationSchema={ParticipantClassModalSchema} onSubmit={() => undefined}>
          {({values, validateForm, handleSubmit}) => {
            return (
              <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>{existingParticipantClass?.id !== '' ? 'Update Participant Class' : 'Add Participant Class'}</Modal.Title>
                <InputRow label={'Name'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('name')}/>
                </InputRow>
                <InputRow
                  label={'Class Date'}
                  labelSize={labelSize}
                  columnSize={3}
                  style={{paddingTop: '10px'}}
                  requiredAsterisk={true}
                >
                  <DatepickerInput
                    name={getFieldName('classDate')}
                    showTimeSelect={false}
                    placeHolderText={'mm/dd/yyyy'}
                    allowFuture={true}
                  />
                </InputRow>
                <InputRow label={'Class Time'} labelSize={labelSize} columnSize={8} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Row style={{marginLeft: '0'}}>
                    <Col lg={4} style={{paddingLeft: '0', paddingRight: '0'}}>
                      <Input name={getFieldName('startTime')} type={'time'}/>
                    </Col>
                    <div style={{paddingTop: '5px', paddingLeft: '1rem', paddingRight: '1rem'}}>
                      to
                    </div>
                    <Col lg={4} style={{paddingLeft: '0', paddingRight: '0'}}>
                      <Input name={getFieldName('endTime')} type={'time'}/>
                    </Col>
                  </Row>
                </InputRow>
                <InputRow
                  label={'Description'}
                  labelSize={labelSize}
                  columnSize={8}
                  style={{paddingTop: '10px'}}
                >
                  <Input name={getFieldName('description')} type={'textarea'}/>
                </InputRow>
                {errorMessage !== '' ?
                  <div style={{marginTop: '1rem'}}>
                    <Alert variant='danger'>{errorMessage}</Alert>
                  </div>
                  : null}
                <Row style={{paddingTop: '10px'}}>
                  <BSForm.Group style={{marginLeft: 'auto', marginRight: '10px', borderRadius: '10px', minWidth: '80px'}}>
                    {renderButtons()}
                  </BSForm.Group>
                </Row>
                <ParticipantClassUserSelection
                  userDropDownOptions={convertToDropDownOptions(participants)}
                  users={values.participantIds.map((id) => {
                    return getUserById(id);
                  })}
                  editable={true}
                  prefix={getFieldName('participantIds')}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertParticipantClass: participantClassStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  users: userStore.selectors.getAsArray(state),
  participants: userStore.selectors.getParticipants(state),
  getUserById: userStore.selectors.getById(state),
  rolesOptions: convertToDropDownOptions(roleStore.selectors.getAsArray(state), 'roleName'),
  participantClasses: participantClassStore.selectors.getAsArray(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(ParticipantClassModal);
