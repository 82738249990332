import {Col, Row} from 'react-bootstrap';
import {ArrayHelpers, FieldArray, useFormikContext} from 'formik';
import {DashboardTask} from '../../../../../types/DashboardTypes';
import React from 'react';
import {OrderedDraggableList} from '../../../../../components/util/lists/OrderedDraggableList/OrderedDraggableList';
import {DeletableListRow} from '../../../../../components/util/lists/ListTable/ListTableRow';
import {propertyOf} from '../../../../../redux/util/object';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {makeDashboardTask} from '../../../../../redux/web/factory';
import {StandardInputColumn, StandardTopPaddedRow} from '../../../../../components/util/form-components/standardLayout';

const DashboardTaskFieldArray = (props: {}) => {
  const fieldPrefix = 'dashboardTasks';
  const generateFieldName = (index: number) => `${fieldPrefix}.${index}`;

  const {values} = useFormikContext<{dashboardTasks: DashboardTask[]}>();

  const renderHeader = (helpers: ArrayHelpers) => (
    <Col style={{width: '100%', marginBottom: '10px'}}>
      <Row>
        <div style={{marginLeft: 'auto', marginTop: '10px'}}>
          <IconButton
            icon={'plus'}
            styles={{width: '100%', textAlign: 'right'}}
            size={'lg'}
            onClick={() => helpers.push(makeDashboardTask())}
          />
        </div>
      </Row>
    </Col>
  );

  return (
    <FieldArray
      name={fieldPrefix}
      validateOnChange={false}
      render={helpers=> {
        return (
          <>
            {renderHeader(helpers)}
            <OrderedDraggableList
              editable={true}
              prefix={fieldPrefix}
              items={values.dashboardTasks}
              noItemsLabel={'No tasks have been created.'}
              move={(from, to) => helpers.move(from, to)}
              renderItem={(item, index) => (
                <DeletableListRow
                  iconSize={'2x'}
                  editable={true}
                  deleteColumnSize={1}
                  renderContentSize={11}
                  key={generateFieldName(index)}
                  onDelete={() => helpers.remove(index)}
                  render={() => <TaskRow entryPrefix={generateFieldName(index)} index={index}/>}/>
              )}
            />
          </>
        );
      }}
    />
  );
};

type TaskRowProps = {
  entryPrefix: string;
  index: number;
};

function TaskRow({entryPrefix, index}: TaskRowProps) {
  return  (
    <StandardTopPaddedRow style={{flex: 'auto', minWidth: '13rem', marginRight: '1.5rem'}}>
      <StandardInputColumn label={`Task ${index + 1}`}>
        <Input name={`${entryPrefix}.${propertyOf<DashboardTask>('name')}`}/>
      </StandardInputColumn>
    </StandardTopPaddedRow>
  );
}

export default DashboardTaskFieldArray;
