import {Dictionary} from '../../util';
import {User, userStore} from '../entities/user';
import {Dispatch} from 'redux';
import {batchActions} from 'redux-batched-actions';
import {ChoreChartWeek, choreChartWeekStore} from '../entities/choreChartWeek';
import {getChecklistState, getChoreChartState} from '../../../api/choreChartChecklistApi';
import {ChecklistWeek, checklistWeekStore} from '../entities/checklistWeek';

export interface ChecklistState {
  shelterStaff: Dictionary<User>;
  checklistWeeks: Dictionary<ChecklistWeek>;
}

export const loadStaffChecklistData = (isoDate: string) => async (dispatch: Dispatch) => {
  const response: ChecklistState = await getChecklistState(isoDate);
  await dispatch(batchActions([
    await dispatch(userStore.actions.setIncludedData(response.shelterStaff)),
    await dispatch(checklistWeekStore.actions.setIncludedData(response.checklistWeeks))
  ], 'LOAD_CHECKLIST_STATE'));
};
