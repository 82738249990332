import React, {useState} from 'react';
import {useMount} from '../../hooks/useMount';
import {AxiosError} from 'axios';
import {handleAxiosError} from '../../redux/util/http';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../redux/core/types/WebState';
import {connect} from 'react-redux';
import {choreChartWeekStore} from '../../redux/web/entities/choreChartWeek';
import ChoreChartCalendar from './components/ChoreChartCalendarController';
import {loadChoreChartData} from '../../redux/web/stateResponses/choreChartData';
import {now} from '../../util';
import {useParams} from 'react-router-dom';
import {getWeek, getYear, parse} from 'date-fns';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function ChoreChart(props: Props) {
  const {getChoreChartWeekByDate, actions: {loadInitialChoreChartData}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const {date} = useParams();
  const initialDate = date ? parse(date, 'MM-dd-yyyy', now) : now;

  useMount(async () => {
    try {
      if (!getChoreChartWeekByDate(getYear(initialDate), getWeek(initialDate)))
      await loadInitialChoreChartData(now.toISOString());
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load chore chart'}));
    }
    setLoading(false);
  });

  return (
      <>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
            <ChoreChartCalendar initialDate={initialDate}/>
        )}
      </>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadInitialChoreChartData: loadChoreChartData
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getChoreChartWeekByDate: choreChartWeekStore.selectors.getWeek(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(ChoreChart);
