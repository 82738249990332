import {DashboardClass, ParticipantDashboardState} from '../../types/DashboardTypes';
import {now, SetStateFunctional} from '../../util';
import {Col, Row} from 'react-bootstrap';
import {StandardTopPaddedRow, TopPaddedRowChildrenDivider} from '../../components/util/form-components/standardLayout';
import DashboardDateLinePlot from './components/DashboardDateLinePlot/DashboardDateLinePlot';
import {propertyOf} from '../../redux/util/object';
import DashboardMessageCard from './components/DashboardMessage/DashboardMessage';
import DashboardTasks from './components/DashboardTasks/DashboardTasks';
import React, {useState} from 'react';
import DashboardClasses from './components/DashboardClasses/DashboardClasses';
import {addDays, subDays} from 'date-fns';
import {DropdownOption} from '../../components/util/form-components/SearchableDropdown/SearchableDropdown';

interface Props {
  dashboardState: ParticipantDashboardState;
  setDashboardState: SetStateFunctional<ParticipantDashboardState>;
}

const ParticipantDashboard = (props: Props) => {
  const {dashboardState, setDashboardState} = props;
  const [currentPlot, setCurrentPlot] = useState<'week' | 'month' | 'year' | 'all'>('all');

  const plotDropDownOptions: DropdownOption[] = [
    {value: 'week', label: 'Classes this Week'},
    {value: 'month', label: 'Classes this Month'},
    {value: 'year', label: 'Classes this Year'},
    {value: 'all', label: 'All Classes'}
  ];

  const addSubDaysClasses = (days: number) => {
    const max = addDays(now, days);
    const min = subDays(now, days);
    return dashboardState.classes.filter(c => c.date <= max && c.date >= min);
  };

  const renderedPlot = () => {
    if (currentPlot === 'week')
      return addSubDaysClasses(3);
    if (currentPlot === 'month')
      return addSubDaysClasses(15);
    if (currentPlot === 'year')
      return addSubDaysClasses(182);
    return dashboardState.classes;
  };

  return (
    <Row>
      <Col>
        <StandardTopPaddedRow>
          <DashboardDateLinePlot
            data={renderedPlot()}
            dateKey={propertyOf<DashboardClass>('date')}
            hoverKey={propertyOf<DashboardClass>('name')}
            dropDownProps={{
              dropDownOptions: plotDropDownOptions,
              onChange: (option) => option ? setCurrentPlot(option.value) : undefined,
              defaultValue: {value: 'all', label: 'All Classes'}
            }}
            xTitle={'Class Date'}
            yTitle={'Class Count'}
          />
        </StandardTopPaddedRow>
      </Col>
      <Col>
        <TopPaddedRowChildrenDivider>
          <DashboardMessageCard message={dashboardState.message} setDashboardState={setDashboardState}/>
          <DashboardTasks tasks={dashboardState.tasks} setDashboardState={setDashboardState}/>
          <DashboardClasses classes={dashboardState.classes}/>
        </TopPaddedRowChildrenDivider>
      </Col>
    </Row>
  );
};

export default ParticipantDashboard;
