import {api} from './index';
import {ReportConfiguration} from '../redux/web/entities/reportConfiguration';
import {Dictionary} from '../redux/util';

export async function processExport(query: string) {
  return (await api.post('/export', {query: query})).data;
}

export function downloadExport(reportName: string) {
  window.open(`${process.env.REACT_APP_API_URL}export/download/${reportName}`);
}

export async function sendBuilderQuery(query: string) {
  return (await api.post<string[]>('/export/query', {query: query})).data;
}

export async function getExportData(subset: string) {
  return (await api.get<any>(`/export/GetExportData/${subset}`)).data;
}

export async function getConfigurations() {
  return (await api.get<Dictionary<ReportConfiguration>>('/export/GetConfigurations')).data;
}

export async function upsertReportConfiguration(config: ReportConfiguration) {
  return (await api.post<ReportConfiguration>('/export/saveConfiguration', config)).data;
}

export async function deleteConfiguration(configId: string) {
  return (await api.delete<string>(`/export/DeleteConfiguration/${configId}`)).data;
}

export async function copyConfiguration(configId: string) {
  return (await api.get<ReportConfiguration>(`/export/CopyConfiguration/${configId}`)).data;
}

