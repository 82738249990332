import {DashboardParticipantData, StaffDashboardState} from '../../types/DashboardTypes';
import {SetStateFunctional} from '../../util';
import {DropdownOption} from '../../components/util/form-components/SearchableDropdown/SearchableDropdown';
import {Col, Row} from 'react-bootstrap';
import {StandardTopPaddedRow, TopPaddedRowChildrenDivider} from '../../components/util/form-components/standardLayout';
import DashboardDateLinePlot from './components/DashboardDateLinePlot/DashboardDateLinePlot';
import {propertyOf} from '../../redux/util/object';
import DashboardTasks from './components/DashboardTasks/DashboardTasks';
import DashboardParticipantAdmissions from './components/DashboardParticipantAdmissions/DashboardParticipantAdmissions';
import DashboardDocuments from './components/DashboardDocuments/DashboardDocuments';
import React, {useState} from 'react';
import DashboardRecentCaseFiles from './components/DashboardRecentCaseFiles/DashboardRecentCaseFiles';
import {CenteredSpinner} from '../../components/util/widgets/CenteredSpinner/CenteredSpinner';

type Props = {
  dashboardState: StaffDashboardState;
  setDashboardState: SetStateFunctional<StaffDashboardState>;
  loading: boolean;
};

const StaffDashboard = (props: Props) => {
  const {dashboardState, setDashboardState, loading} = props;
  const [currentPlot, setCurrentPlot] = useState<'week' | 'month' | 'year' | 'all'>('all');

  const plotDropDownOptions: DropdownOption[] = [
    {value: 'week', label: 'Participants this Week'},
    {value: 'month', label: 'Participants this Month'},
    {value: 'year', label: 'Participants this Year'},
    {value: 'all', label: 'All Participants'}
  ];

  const renderedPlot = () => {
    if (currentPlot === 'week') return dashboardState.participantsThisWeek;
    if (currentPlot === 'month') return dashboardState.participantsThisMonth;
    if (currentPlot === 'year') return dashboardState.participantsThisYear;
    return dashboardState.participants;
  };

  return (
      <Row>
        <Col>
          <StandardTopPaddedRow>
            {loading ? <CenteredSpinner/> :
              (
                <DashboardDateLinePlot
                  data={renderedPlot()}
                  dateKey={propertyOf<DashboardParticipantData>('admissionDate')}
                  hoverKey={propertyOf<DashboardParticipantData>('participantName')}
                  dropDownProps={{
                    dropDownOptions: plotDropDownOptions,
                    onChange: (option) => option ? setCurrentPlot(option.value) : undefined,
                    defaultValue: {value: 'all', label: 'All Participants'}
                  }}
                  xTitle={'Date of Admission'}
                  yTitle={'Participants Created'}
                />
              )}
          </StandardTopPaddedRow>
        </Col>
        <Col>
          <TopPaddedRowChildrenDivider>
            <DashboardRecentCaseFiles/>
            <DashboardTasks tasks={dashboardState.tasks} setDashboardState={setDashboardState}/>
            <>
              <Col style={{padding: '0'}}>
                <DashboardParticipantAdmissions
                  participantsThisWeek={dashboardState.participantsThisWeek}
                  participantsThisMonth={dashboardState.participantsThisMonth}
                  participantsThisYear={dashboardState.participantsThisYear}
                />
              </Col>
              <Col style={{padding: '0'}}>
                <DashboardDocuments documentsAwaitingSignature={dashboardState.documentsAwaitingSignature}/>
              </Col>
            </>
          </TopPaddedRowChildrenDivider>
        </Col>
      </Row>
    );
};

export default StaffDashboard;
