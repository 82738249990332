import {
  CaseManagementContact, CaseManagementContactDto,
  CaseManagementContactTypeDropDown,
  weeklyCaseManagementNotesStore
} from '../../../../../../../../../redux/web/entities/forms/WeeklyCaseManagementNotes';
import {Form, Formik, FormikErrors} from 'formik';
import {Alert, Button, Col, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import React, {FormEvent, useState} from 'react';
import {InputColumn} from '../../../../../../../../../components/util/form-components/InputColumn';
import {
  StandardCheckboxInput,
  StandardTopPaddedRow
} from '../../../../../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {
  DropdownInput
} from '../../../../../../../../../components/util/form-components/formik-inputs/DropdownInput/DropdownInput';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../../../../../redux/util/http';
import styles from '../../../../../../../../Configuration/UserManagement/components/UserModal/UserModal.module.scss';
import {
  makeCaseManagementContactModal
} from '../../../../../../../../../redux/web/factory/forms/weeklyCaseManagementForms';
import {connect} from 'react-redux';
import {boolean, number, object, string} from 'yup';
import {bindActionCreators, Dispatch} from 'redux';
import PhoneInputField
  from '../../../../../../../../../components/util/form-components/formik-inputs/PhoneNumberInput/PhoneNumberInput';

type Props = {
  contact: CaseManagementContact | undefined;
  editable: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  userId: string;
} & ReturnType<typeof mapDispatchToProps>;


function ContactsFormModal(props: Props) {
  const {userId, contact, onSubmit, editable, onCancel, actions: {upsertContact}} = props;
  const getFieldName = (name: keyof CaseManagementContactDto) => name;

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);



  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: CaseManagementContactDto,
    validate: (values: CaseManagementContactDto) => Promise<FormikErrors<CaseManagementContactDto>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        association.userId = userId;
        upsertContact(association);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(getErrorResponseMessage(e));
      }
    }
    setIsSubmitting(false);
  };




  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };


  return (
    <Modal backdropClassName={''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik
          initialValues={contact ? contact as CaseManagementContactDto : makeCaseManagementContactModal()}
          validationSchema={ContactModalSchema}
          onSubmit={() => undefined}
        >
          {({values, validateForm, handleSubmit}) => {
            return (
              <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>{values.id ? 'Update Contact' : 'Add Contact'}</Modal.Title>

                <Col>
                  <Row style={{gap: '1rem', marginLeft: '1.5rem'}}>
                    <StandardCheckboxInput name={getFieldName('active')} label={'Active'}/>
                    <StandardCheckboxInput name={getFieldName('safeContact')} label={'Safe Contact'}/>
                  </Row>
                  <StandardTopPaddedRow>
                    <Col>
                      <InputColumn label={'Full Name'}>
                        <Input name={getFieldName('fullName')}/>
                      </InputColumn>
                      <InputColumn label={'Nickname'}>
                        <Input name={getFieldName('nickname')}/>
                      </InputColumn>
                      <InputColumn label={'Address'}>
                        <Input name={getFieldName('address')}/>
                      </InputColumn>
                      <InputColumn label={'City'}>
                        <Input name={getFieldName('city')}/>
                      </InputColumn>
                      <InputColumn label={'State'}>
                        <Input name={getFieldName('state')}/>
                      </InputColumn>
                      <InputColumn label={'Zip Code'}>
                        <Input name={getFieldName('zipCode')}/>
                      </InputColumn>
                      <InputColumn label={'Phone Number'}>
                        <PhoneInputField name={getFieldName('phoneNumber')}/>
                      </InputColumn>
                      <InputColumn label={'Mailing Address (leave blank if same as Address)'}>
                        <Input name={getFieldName('mailingAddress')}/>
                      </InputColumn>
                    </Col>
                    <Col>
                      <InputColumn label={'Relationship'}>
                        <DropdownInput name={getFieldName('relationship')}
                                       dropdownData={CaseManagementContactTypeDropDown}
                        />
                      </InputColumn>
                      <InputColumn label={'Email'}>
                        <Input name={getFieldName('email')}/>
                      </InputColumn>
                      <InputColumn label={'Information'}>
                        <Input name={getFieldName('information')} type={'textarea'}/>
                      </InputColumn>
                    </Col>



                  </StandardTopPaddedRow>
                </Col>



                {errorMessage !== '' ?
                  <div style={{marginTop: '1rem'}}>
                    <Alert variant='danger'>{errorMessage}</Alert>
                  </div>
                  : null}
                <Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    {renderButtons()}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}


// @ts-ignore
export const ContactModalSchema = object<CaseManagementContactDto>({
  id: string().nullable(),
  relationship: number().required('Relationship is required'),
  fullName: string().required('Name is required'),
  email: string().nullable().email('Email must be a valid format'),
  userId: string().nullable(),
  safeContact: boolean().required(),
  active: boolean().required(),
  information: string().nullable(),
  zipCode: string().nullable(),
  nickname: string().nullable(),
  phoneNumber: string().nullable(),
  address: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
  mailingAddress: string().nullable()
});

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertContact: weeklyCaseManagementNotesStore.actions.upsertCaseManagementContact
  }, dispatch)});


export default connect(null, mapDispatchToProps)(ContactsFormModal);
