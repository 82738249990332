// @ts-ignore
import Plotly from 'plotly.js-basic-dist-min';
import React, {useMemo, useState} from 'react';
import DashboardCard from '../DashboardCard/DashboardCard';
import IconButton from '../../../../components/util/widgets/IconButton/IconButton';
import {DropdownOption} from '../../../../components/util/form-components/SearchableDropdown/SearchableDropdown';
import Select, {SingleValue} from 'react-select';
import {Row} from 'react-bootstrap';
import {isValid} from 'date-fns';
import createPlotlyComponent from 'react-plotly.js/factory';

const Plot = createPlotlyComponent(Plotly);

interface BaseProps<DataType, DateKey, HoverKey> {
  data: DataType[];
  dateKey: DateKey;
  hoverKey: HoverKey;
  grouping?: 'date' | 'dateTime';
  startAsLineChart?: boolean;
  xTitle: string;
  yTitle: string;
}

interface DropDownProps {
  dropDownProps: {
    dropDownOptions: DropdownOption[];
    onChange: (option: SingleValue<DropdownOption>) => void;
    defaultValue: DropdownOption;
  };
}
interface TitleProps { title: string; }

type Props<DataType, DateKey, HoverKey> = BaseProps<DataType, DateKey, HoverKey> & (DropDownProps | TitleProps);


// I would recommend using utcToZonedTime from date-fns-tz for the specified date field before passing it
const DashboardDateLinePlot = <DataType extends Record<DateKey, unknown>, DateKey extends keyof DataType & string, HoverKey extends keyof DataType & string>
  (props: Props<DataType, DateKey, HoverKey>) => {
    const {data, dateKey, hoverKey, grouping = 'date', startAsLineChart = false, xTitle, yTitle} = props;
    const [asLineChart, setAsLineChart] = useState(startAsLineChart);



    // using useMemo as I'm unsure if plotly will cause re-renders or not... it mutates itself afaik
    const plotlyConfig = useMemo(() => {
      const validData = data.filter(d => d[dateKey] !== null && isValid(d[dateKey] as Date));

      // date records
      // gives output like [{"6/23/2023":3},{"5/5/2023":1},{"4/5/2023":1}]
      const localDateStrings: string[] = [];
      validData.forEach((entry) => grouping === 'date' ?
        localDateStrings.push((entry[dateKey] as Date).toLocaleDateString())
        : localDateStrings.push((entry[dateKey] as Date).toLocaleString()));
      const dateRecords = localDateStrings.reduce<Record<string, number>[]>((acc, date) => {
        if (acc.find(e => e[date]))
          return acc;
        return [...acc, {[date]: localDateStrings.filter(d => d === date).length}];
      }, []);

      // convert [{"6/23/2023":3},{"5/5/2023":1},{"4/5/2023":1}] to ["6/23/2023", "5/5/2023", "4/5/2023"]
      const xCoordinates = dateRecords.reduce<string[]>((acc, record) => [...acc, Object.keys(record)[0]],[]);
      // convert [{"6/23/2023":3},{"5/5/2023":1},{"4/5/2023":1}] to [3, 1, 1]
      const yCoordinates = dateRecords.reduce<number[]>((acc, record) => [...acc, Object.values(record)[0]], []);

      // hover info, get a string for each point on the chart
      const hoverArray = dateRecords.reduce<string[]>((arrayAcc, date) => {
        // build a string by iterating all records in source data and adding each if it matches the current mapped date
        const hoverStringForDate = validData.reduce<string>((stringAcc, e) => {
          return (e[dateKey] as Date).toLocaleDateString() === Object.keys(date)[0] ?
            `${stringAcc}${e[hoverKey]}, ${(e[dateKey] as Date).toLocaleTimeString()}<br>`
            : stringAcc;
        }, '');
        return [...arrayAcc, hoverStringForDate];
      }, []);

      return {xArray: xCoordinates, yArray: yCoordinates, hoverArray: hoverArray, records: dateRecords};
    }, [grouping, data]);

    const renderTitle = () => (
      <>
        {'dropDownProps' in props ?
          <Row>
            <Select
              options={props.dropDownProps.dropDownOptions}
              defaultValue={props.dropDownProps.defaultValue}
              onChange={props.dropDownProps.onChange}
              isClearable={false}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible' }),
                menu: base => ({...base, width: 'max-content', minWidth: '100%'})
              }}
              menuPortalTarget={document.querySelector('body')}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            />
          </Row>
          : <Row>{props.title}</Row>
        }
        <div style={{position: 'absolute', right: '10px'}}>
          <IconButton icon={'chart-line'} size={'lg'} color={asLineChart ? '#005A9C' : 'black'} onClick={() => setAsLineChart(!asLineChart)}/>
        </div>
      </>
    );

    return (
      <DashboardCard title={renderTitle()}>
        <Row style={{justifyContent: 'center'}}>
          <Plot
            data={[
              {
                x: plotlyConfig.xArray,
                y: plotlyConfig.yArray,
                type: 'scatter',
                mode: asLineChart ? 'lines+markers' : 'markers',
                marker: {color: 'red'},
                text: plotlyConfig.hoverArray
              }
            ]}
            layout={{
              title: '',
              margin: {l: 50, r: 50, b: 120, t: 50, pad: 0},
              xaxis: {title: {text: xTitle, font: {size: 16}, }},
              yaxis: {title: {text: yTitle, font: {size: 16}}, dtick: 1}
            }}
            config={{doubleClick: 'reset+autosize', doubleClickDelay: 1000}}
          />
        </Row>
      </DashboardCard>
    );
};

export default DashboardDateLinePlot;
