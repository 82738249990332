import {User} from '../../../redux/web/entities/user';
import {Menu, MenuItem, SubMenu} from 'react-pro-sidebar';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../router/RoutePaths';
import ParticipantComponent from '../../util/AdministratorComponent/ParticipantComponent';
import AdministratorComponent from '../../util/AdministratorComponent/AdministratorComponent';
import NotParticipantComponent from '../../util/AdministratorComponent/NotParticipantComponent';
import AdministratorOrShelterStaffComponent
  from '../../util/AdministratorComponent/AdministratorOrShelterStaffComponent';
import React from 'react';
import AdministratorOrStaffComponent from '../../util/AdministratorComponent/AdministratorOrStaffComponent';
import StaffAndShelterStaffComponent from "../../util/AdministratorComponent/StaffAndShelterStaffComponent";

export const GetMenuItemsForRole = (currentUser: User | null) => {
  return (
    <Menu menuItemStyles={{button: {color: '#FFFFFF', backgroundColor: '#005A9C', '&:hover': {backgroundColor: '#00AFFF'}}}}>
      <MenuItem component={<Link to={RoutePaths.dashboard}/>}> Home </MenuItem>
      <ParticipantComponent>
        <MenuItem component={<Link to={RoutePaths.participantClasses}/>}> Classes </MenuItem>
      </ParticipantComponent>
      <AdministratorComponent>
        <SubMenu label='User'>
            <MenuItem component={<Link to={RoutePaths.userManagement}/>}> Users </MenuItem>
            <MenuItem component={<Link to={RoutePaths.caseFileManagement}/>}> Case Files </MenuItem>
        </SubMenu>
      </AdministratorComponent>
      <StaffAndShelterStaffComponent>
        <MenuItem component={<Link to={RoutePaths.caseFileManagement}/>}> Case Files </MenuItem>
      </StaffAndShelterStaffComponent>
      <ParticipantComponent>
        <MenuItem component={<Link to={RoutePaths.caseFilePaths.CaseFile.replace(':id', currentUser?.id ?? '')}/>}> Case File </MenuItem>
      </ParticipantComponent>
      <NotParticipantComponent>
        <MenuItem component={<Link to={RoutePaths.documentManagement}/>}> Document Library </MenuItem>
      </NotParticipantComponent>
      <NotParticipantComponent>
        <MenuItem component={<Link to={RoutePaths.participantClassManagement}/>}> Class Management </MenuItem>
      </NotParticipantComponent>
      <MenuItem component={<Link to={RoutePaths.choreChartPaths.View}/>}> Chore Chart </MenuItem>
      <AdministratorOrShelterStaffComponent>
        <MenuItem component={<Link to={RoutePaths.checklistPaths.View}/>}> Staff Checklist </MenuItem>
      </AdministratorOrShelterStaffComponent>
      <AdministratorOrStaffComponent>
        <MenuItem component={<Link to={RoutePaths.exportPath}/>}> Export </MenuItem>
      </AdministratorOrStaffComponent>
    </Menu>
  );
};
