import {
  TWeeklyCaseNotesForm
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/WeeklyCaseManagementNotesModal';
import {
  CaseManagementContact, CaseManagementContactDto,
  CaseManagementContactType,
  CaseManagementNote
} from '../../entities/forms/WeeklyCaseManagementNotes';
import {
  TCaseManagementCaseNoteEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/CaseNotesForm/CaseNotesFieldArray';
import {
  TCaseManagementDrugTestEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/DrugTestsForm/DrugTestsFieldArray';
import {
  TCaseManagementStepEvaluationEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/StepEvaluationsForm/StepEvaluationsFieldArray';
import {
  TCaseManagementMedicationEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/MedicationForm/MedicationsFieldArray';
import {
  TCaseManagementFinancialAssistanceEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/FinancialAssistanceForm/FinancialAssistancesFieldArray';
import {
  TCaseManagementCounselingEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/CounselingForm/CounselingsFieldArray';
import {
  TCaseManagementContactEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/ContactsForm/ContactsForm';
import {now, nowUtc} from '../../../../util';
import {parse} from 'date-fns';
import {format} from 'date-fns-tz';
import {
  TCaseManagementServicesProvidedEntry
} from '../../../../pages/CaseFile/components/CaseFilePreMadeFormsGrid/forms/WeeklyCaseManagementNotesModal/components/forms/ServicesProvidedForm/ServicesProvidedFieldArray';

export function makeWeeklyCaseManagementNotes(userId: string, current?: TWeeklyCaseNotesForm): TWeeklyCaseNotesForm {
  return current ? {...current} : {
    userId: userId,
    caseNotes: [],
    servicesProvided: [],
    stepEvaluations: [],
    medications: [],
    drugTests: [],
    financialAssistances: [],
    counselings: [],
    contacts: [],
    updatedOn: ''
  };
}

export function makeCaseManagementNote(): TCaseManagementCaseNoteEntry {
  return {
    date: nowUtc(),
    createdAt: undefined,
    updatedAt: undefined,
    shortDescription: '',
    longDescription: ''
  };
}

export function makeServicesProvided(): TCaseManagementServicesProvidedEntry {
  return {
    date: nowUtc(),
    oesOutreach: false,
    oesFood: false,
    oesTemporarySafeShelter: false,
    oesInPersonCounseling: false,
    oesGroupCounselingOrDiscussion: false,
    oesReferralService: false,
    oesOther: false,
    oesOtherName: '',

    oesCrisisCounselingUnits: 0,
    oesInPersonCounselingUnits: 0,
    oesGroupCounselingOrDiscussionUnits: 0,
    oesCaseManagementUnits: 0,
    oesShelterServicesUnits: 0,
    oesTransportationUnits: 0,
    oesLegalAssistanceUnits: 0,
    oesReferralsUnits: 0,

    dssCrisisIntervention: false,
    dssAdvocacy: false,
    dssEducationalSupport: false,
    dssLifeSkillsTraining: false,
    dssHtWorkShops: false,
    dssForensicSpecialtyServices: false,
    dssSupportiveCounseling: false,
    dssEmergencyServices: false,
    referralResponseImmediateResponse: false,
    referralResponseThreeDayResponse: false,
    referralResponseTenDayResponse: false,
    referralResponseReReferralToService: false,
    referralResponseYouthAcceptedServices: false,
    referralResponseSuccessfulCompletion: false,
    referralResponseProgressNoCompletion: false,
    referralResponseNoProgressNoCompletion: false,
    ovcChildCare: 0,
    ovcDentalErLongTerm: 0,
    ovcEducation : 0,
    ovcFinancialAssistance: 0,
    ovcHousingRentalAssistance: 0,
    ovcInterpreterTranslator: 0,
    ovcMedicalErLongTerm: 0,
    ovcMentalHealthTreatmentErLongTerm: 0,
    ovcPersonalItems: 0,
    ovcSubstanceAbuseTreatment: 0,
    ovcTransportation: 0,
    ovcOther: 0,
    ovcOtherName: '',
    clientCoordination: 0,
    clientOrientation: 0,
    criminalJusticeBasedAdvocacy: 0,
    crisisIntervention24HrHotline: 0,
    emotionalMoralSupport: 0,
    employmentAssistance: 0,
    familyReunification: 0,
    housingShelterAdvocacy: 0,
    ongoingCaseManagement: 0,
    protectionSafetyPlanning: 0,
    repatriation: 0,
    socialServiceAdvocacyExplanationBenefitsEntitlementsAvailability: 0
  };
}

export function makeCaseManagementStepEvaluation(): TCaseManagementStepEvaluationEntry {
  return {
    timeFrame: '',
    description: '',
    comment: ''
  };
}

export function makeCaseManagementMedication(): TCaseManagementMedicationEntry {
  return {
    name: '',
    doctor: '',
    strength: '',
    dosage: '',
    datePrescribed: '',
    expirationDate: '',
    use: '',
    amount: '',
    refillDate: ''
  };
}

export function makeCaseManagementDrugTest(): TCaseManagementDrugTestEntry {
  return {
    date: nowUtc(),
    drugTest: '',
    amp: false,
    bar: false,
    bzo: false,
    coc: false,
    met: false,
    mtd: false,
    opi: false,
    mdma: false,
    pcp: false,
    thc: false,
    tobacco: false
  };
}

export function makeCaseManagementFinancialAssistance(): TCaseManagementFinancialAssistanceEntry {
  return {
    date: nowUtc(),
    amount: '',
    descriptionOfExpense: '',
    voucher: ''
  };
}

export function makeCaseManagementCounseling(): TCaseManagementCounselingEntry {
  return {
    date: nowUtc(),
    provider: '',
    lengthOfVisit: '',
    notes: ''
  };
}

export function makeCaseManagementContact(): TCaseManagementContactEntry {
  return {
    fullName: '',
    nickname: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    mailingAddress :'',
    phoneNumber: '',
    relationship: null,
    active: true,
    safeContact: false,
    email: '',
    information: ''
  };
}

export function makeCaseManagementContactModal(safeContact: boolean = false): CaseManagementContactDto {
  return {
    userId: '',
    id: '',
    fullName: '',
    nickname: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    mailingAddress :'',
    phoneNumber: '',
    relationship: null,
    active: true,
    safeContact: safeContact,
    email: '',
    information: ''
  };
}
