import {
  number,
  object, string, mixed,
} from 'yup';
import {ReportConfiguration} from '../../../../../redux/web/entities/reportConfiguration';



// @ts-ignore
export const SaveExportModalSchema = object<ReportConfiguration>({
  id: string().nullable(),
  name: string().required('Name is required'),
  subset: string().required('You must select a dataset')
});
