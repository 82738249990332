import React, {FormEvent, useEffect, useState} from 'react';
import styles from './Login.module.scss';
import {Alert, Col, Container, Form, Image, Row} from 'react-bootstrap';
import {Link, Navigate} from 'react-router-dom';
import {isEmailValid} from '../../util';
import {isAuthenticated, userStore} from '../../redux/web/entities/user';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {RoutePaths} from '../../router/RoutePaths';
import {WebState} from '../../redux/core/types/WebState';
import {LoadingButton} from '../../components/util/widgets/LoadingButton/LoadingButton';
import {
  ErrorResponse,
  getErrorResponseMessage,
  getResponseData, isBadRequest,
  isServerError,
  isUnknownError
} from '../../redux/util/http';
import {getWhiteLogoLink} from '../../appTheme';
import {login} from '../../api/authApi';
import {OnChangeFormControlType} from '../../redux/core/types/util';
import {AuthenticationBody} from '../../components/layout/AuthenticationBody/AuthenticationBody';

interface LoginForm {
  email: string;
  password: string;
}

export type UserErrorResponse = ErrorResponse<LoginForm>;

interface LoginPageState {
  redirectUrl: string;
  errorMessage: string;
  submitted: boolean;
  emailTouched: boolean;
  submitting: boolean;
  form: LoginForm;
  errors?: UserErrorResponse;
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function Login(props: Props) {
  const {authenticated, actions: {setCurrentUser}} = props;

  const [redirectUrl, setRedirectUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState<LoginForm>({email: '', password: ''});
  const [errors, setErrors] = useState<UserErrorResponse>();


  useEffect(() => {
    if (authenticated) {
      setRedirectUrl(RoutePaths.dashboard);
    }
  });

  // Promise<AxiosResponse<UserWithToken>>
  const makeRequest = async () => {
      return await login(form);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
    /*if (!isEmailValid(form.email)) {
      setErrorMessage('Email address not valid.');
      return;
    }*/
    if (form.email.length === 0) {
      setErrorMessage('You must enter an email address.');
      return;
    }
    await trySubmit();
  };

  const trySubmit = async () => {
    try {
      setSubmitting(true);
      const resp = await makeRequest();
      setCurrentUser(resp.data);
      setRedirectUrl(RoutePaths.dashboard);
    } catch (e) {
      if (isBadRequest(e)) {
        setSubmitting(false);
        setErrorMessage(getErrorResponseMessage(e));
        setErrors(getResponseData(e));
      } else if (isUnknownError(e) || isServerError(e)) {
        setSubmitting(false);
        setErrorMessage(isUnknownError(e) ? 'Could not make a connection!' : 'A server error has occurred');
      }
    }
  };

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

    return (
      <AuthenticationBody>
        {renderRedirect()}
          <Form onSubmit={onSubmit}>
            <Container fluid={true}>
              <Col lg={12}>
                <Row>
                  <Form.Control
                    required={true}
                    type='text'
                    name='email'
                    placeholder='Email or Participant ID'
                    onChange={(value: OnChangeFormControlType) => setForm({email: value.target.value, password: form.password})}
                    value={form.email}
                  />
                </Row>
                <br/>
                <Row>
                  <Form.Control
                    required={true}
                    type='password'
                    name='password'
                    placeholder='Password'
                    onChange={(value: OnChangeFormControlType) => setForm({email: form.email, password: value.target.value})}
                    value={form.password}
                  />
                </Row>
                <Row className={styles['login-button']}>
                  <LoadingButton type='submit' loading={submitting} label='Login'/>
                </Row>
              </Col>
            </Container>
          </Form>
        {errorMessage !== '' ?
          <Row style={{marginTop: '1rem', justifyContent: 'center', flexWrap: 'wrap'}}>
            <Alert variant='danger'>{errorMessage}</Alert>
          </Row>
          : null}
        <div className={styles['forgot-password']}>
          <Link to={RoutePaths.forgotPassword}>Forgot Password</Link>
        </div>
      </AuthenticationBody>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({setCurrentUser: userStore.actions.setCurrentUser}, dispatch)});
const mapStateToProps = (state: WebState) => ({ authenticated: isAuthenticated(state)});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
