import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {combineReducers} from 'redux';
import {CommonDispatch} from '../index';
import {createAction} from 'typesafe-actions';
import {Dictionary} from '../../util';
import {updateItemCompletion, upsertChecklistWeek} from '../../../api/choreChartChecklistApi';

export interface ChecklistItem {
  id: string;
  checklistRowId: string;
  dayOfTheWeek: number;
  userId: string;
  done: boolean;
  updatedAt: string;
  updatedBy: string;
}

// renderId only used for map key, can be ignored
export interface ChecklistRow {
  id: string;
  taskNumber: number;
  checklistWeekId: string;
  task: string;
  items: ChecklistItem[];
}

export interface ChecklistWeek {
  id: string;
  weekOfTheYear: number;
  year: number;
  rows: ChecklistRow[];
  reminder: string;
}

const partialDataActions = {
  setIncludedData: createAction('CHECKLIST/SET_INCLUDED_DATA')<Dictionary<ChecklistWeek>>()
};

const actions = createStandardActions(placeholder<ChecklistWeek>(), 'CHECKLIST_WEEK/SET', 'CHECKLIST_WEEK/SAVE');
const selectors = createStandardSelectors(placeholder<ChecklistWeek>(), s => getEntities(s).checklists);
export type ChecklistWeekActions = GetActions<typeof actions> | GetActions<typeof partialDataActions>;
export const checklistWeeks = combineReducers({
  items: standardItemsReducer<ChecklistWeek, ChecklistWeekActions>(actions)
    .handleAction(partialDataActions.setIncludedData, (state, action) => {
      let returnedState = {...state};
      Object.entries(action.payload).forEach(([key, value]) => {
        const previousEntryState = returnedState[key as any];
        returnedState =  {...returnedState, [key as any]: {...previousEntryState, ...value}};
      });
      return returnedState;
    })
});
export const checklistWeekStore = readonly({
  selectors: {
    ...selectors,
    getWeek: selector(s => (year: number, weekOfTheYear: number) => selectors.getAsArray(s)
      .find(week => week.year === year && week.weekOfTheYear === weekOfTheYear))
  },
  actions: {
    ...actions,
    ...partialDataActions,
    upsertWeek: (request: ChecklistWeek) => async (dispatch: CommonDispatch) => {
      const response = await upsertChecklistWeek(request);
      dispatch(actions.save(response));
    },
    updateChoreCompletion: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await updateItemCompletion(id);
      dispatch(actions.save(response));
    }
  }
});
