import React, {FormEvent, useState} from 'react';
import {
  PrimaryIntake,
  primaryIntakeStore
} from '../../../../../../redux/web/entities/forms/intake/primaryIntake/PrimaryIntake';
import {Alert, Button, Col, Modal, Row, Spinner, Tab, Tabs} from 'react-bootstrap';
import {makePrefilledIntakeForm, makePrimaryIntakeForm} from '../../../../../../redux/web/factory/forms/intakeForms';
import {Form, Formik, FormikErrors, FormikValues} from 'formik';
import Input from '../../../../../../components/util/form-components/formik-inputs/Input/Input';
import styles from './PrimaryIntakeModal.module.scss';
import {Form as BSForm} from 'react-bootstrap';
import {PersonalInformationForm, TPersonalInformationForm} from './components/forms/PersonalInformationForm';
import {AssessmentQuestionsForm, TAssessmentQuestionsForm} from './components/forms/AssessmentQuestionsForm';
import {
  StandardTopPaddedRow,
  StandardCheckboxInput, StandardInputRow
} from '../../../../../../components/util/form-components/standardLayout';
import {
  HumanTraffickingInformationForm,
  THumanTraffickingInformationForm
} from './components/forms/HumanTraffickingInformationForm';
import {
  HumanTraffickingIdentificationAndParQuestionsForm,
  THumanTraffickingIdentificationQuestionsForm, TParProgramQuestionsForm
} from './components/forms/HumanTraffickingIdentificationAndParQuestionsForm';
import {ClosingInformationForm, TClosingInformationForm} from './components/forms/ClosingInformationForm';
import {ConfirmationDialog} from '../../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import IconButton from '../../../../../../components/util/widgets/IconButton/IconButton';
import {PrimaryIntakeInformationModal} from './components/PrimaryIntakeInformationModal';
import {PrimaryIntakeModalSchema} from './PrimaryIntakeModalSchema';
import {AxiosError} from 'axios';
import {
  getErrorResponseMessage,
  getResponseData,
  isBadRequest, isServerError,
  isUnknownError
} from '../../../../../../redux/util/http';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../../redux/core/types/WebState';
import {userStore} from '../../../../../../redux/web/entities/user';
import {connect} from 'react-redux';
import {
  CompletionStateIndicator
} from '../../../../../../components/util/widgets/CompletionStateIndicator/CompletionStateIndicator';
import {upsertPrimaryIntake} from '../../../../../../api/forms/formApi';
import DatepickerInput
  from "../../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput";

export type TPrimaryIntakeUpsertForm = Omit<PrimaryIntake, 'id' | 'userId' | 'personalInformation' | 'humanTraffickingIdentificationQuestions' |
  'parProgramQuestions' | 'assessmentQuestions' | 'humanTraffickingInformation' | 'closingInformation'> & {
    id?: string;
    userId?: string;
    personalInformation: TPersonalInformationForm;
    humanTraffickingIdentificationQuestions: THumanTraffickingIdentificationQuestionsForm;
    parProgramQuestions: TParProgramQuestionsForm;
    assessmentQuestions: TAssessmentQuestionsForm;
    humanTraffickingInformation: THumanTraffickingInformationForm;
    closingInformation: TClosingInformationForm;
  };

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
  existingIntake?: PrimaryIntake;
  disabled?: boolean;
  userId?: string;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function PrimaryIntakeModal(props: Props) {
  const {onSubmit, onCancel, existingIntake, disabled = true, userId, actions: {saveIntake, saveUser}} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentModal, setCurrentModal] = useState<'info' | 'intake' | 'cancel'>('info');
  const [errorMessage, setErrorMessage] = useState('');
  const [validateOnChangeAfterSubmit, setValidateOnChangeAfterSubmit] = useState(false);
  const [tabKey, setTabKey] = useState<any>('initialInfo');
  const getFieldName = (name: keyof TPrimaryIntakeUpsertForm) => name;


  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    values: TPrimaryIntakeUpsertForm,
    validate: (values: TPrimaryIntakeUpsertForm) => Promise<FormikErrors<TPrimaryIntakeUpsertForm>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    setValidateOnChangeAfterSubmit(true);
    e.persist();
    e.preventDefault();
    const errors = await validate(values);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
      setErrorMessage('Errors were found in the form.');
    } else {
      try {
        const {
          humanTraffickingIdentificationQuestions,
          parProgramQuestions,
          assessmentQuestions,
          humanTraffickingInformation,
          closingInformation,
          ...rest
        } = values;
        let request: TPrimaryIntakeUpsertForm;
        // When human trafficking has been identified, the PAR assessment is to be skipped
        if (humanTraffickingIdentificationQuestions.experiencedHumanTrafficking || humanTraffickingIdentificationQuestions.tradedSexualFavors)
          request = {
            ...rest,
            humanTraffickingIdentificationQuestions,
            assessmentQuestions,
            humanTraffickingInformation,
            closingInformation
          } as TPrimaryIntakeUpsertForm;
        // When the PAR assessment tests positive, the form is to be turned into Jennifer Duarte
        else if (parProgramQuestions.arrestedForSex)
          request = {...rest, humanTraffickingIdentificationQuestions, parProgramQuestions} as TPrimaryIntakeUpsertForm;
        // If neither above case is met, submit all values
        else
          request = values as TPrimaryIntakeUpsertForm;
        // batch actions would not return
        const response = await upsertPrimaryIntake(request);
        saveIntake(response.primaryIntake);
        if (response.user)
          saveUser(response.user);
        onSubmit();
      } catch (e: AxiosError | any) {
        if (isBadRequest(e)) {
          setErrorMessage(getErrorResponseMessage(e));
        } else if (isUnknownError(e) || isServerError(e)) {
          setErrorMessage(isUnknownError(e) ? 'Could not make a connection!' : 'A server error has occurred');
        }
      }
      setIsSubmitting(false);
    }
  };

  const renderCancelConfirmationDialog = () => (
    <ConfirmationDialog
      open={currentModal === 'cancel'}
      onAccept={async () => onCancel()}
      onDecline={async () => setCurrentModal('intake')}
      positiveVariant={'success'}
      negativeVariant={'danger'}
      positiveText={'Yes'}
      negativeText={'No'}
      prompt={'Are you sure you would like to cancel your participant registration?'}
    />
  );

  const renderButtons = (setValues: (val: TPrimaryIntakeUpsertForm) => any) => {
    return (
      <>
        {process.env.NODE_ENV === 'development' &&
          <Button className={styles['close-button']} onClick={() => setValues(makePrefilledIntakeForm(userId))}>Autofill values (dev only)</Button>}
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={() => disabled ? onCancel() : setCurrentModal('cancel')} variant={'danger'} className={styles['close-button']}>
            {disabled ? 'Close' : 'Cancel'}
          </Button>
        }
        {!disabled && !isSubmitting ? <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };

  const disableTabsCondition = (values: TPrimaryIntakeUpsertForm): boolean => values.parProgramQuestions.arrestedForSex &&
    !(values.humanTraffickingIdentificationQuestions.experiencedHumanTrafficking || values.humanTraffickingIdentificationQuestions.tradedSexualFavors);

  const tabTitleFormatter = (title: string, form: (keyof TPrimaryIntakeUpsertForm)[], errors: FormikErrors<TPrimaryIntakeUpsertForm>) => {
    const originalFormat = {tabClassName: undefined, title: title};
    const errorFormat = {tabClassName: styles['tab'], title: `${title}*`};
    return Object.values(form).find(k => k in errors) && validateOnChangeAfterSubmit ? errorFormat : originalFormat;
  };


  return (
    <>
      <Formik
        initialValues={makePrimaryIntakeForm(existingIntake, userId)}
        validationSchema={PrimaryIntakeModalSchema}
        onSubmit={() => undefined}>
        {({values, validateForm, handleSubmit, errors, setValues}) => (
          <Modal show={currentModal === 'intake'} centered={true} size={'xl'}>
            <Modal.Body>
              <Modal.Title>
                <Row>
                  Participant Intake Form
                  <IconButton
                    icon={'question-circle'}
                    onClick={() => setCurrentModal('info')}
                    color={'#005A9C'}
                    iconToolTipText={'Show guidelines'}
                    styles={{marginLeft: '15px'}}
                  />
                  <CompletionStateIndicator
                    existing={!!existingIntake}
                    existingText={'Editing Completed Form'}
                    styles={{alignSelf: 'flex-end', marginLeft: 'auto', marginRight: '15px'}}
                  />
                </Row>
              </Modal.Title>
              <Col style={{paddingTop: '10px'}}>
                <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                  <Tabs activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                    <Tab
                      eventKey={'initialInfo'}
                      {...tabTitleFormatter(
                        'Initial info', [
                        getFieldName('employeeName'),
                        getFieldName('referredBy'),
                        getFieldName('reasonForCall'),
                        getFieldName('fpdReferral'),
                        getFieldName('date')],
                        errors)}
                      className={styles['form-tab']}>
                      <fieldset disabled={disabled}>
                        <Col style={{paddingTop: '10px'}}>
                          <h3>Initial Information</h3>
                        </Col>
                        <StandardInputRow label={'Employee Name'} columnSize={5}>
                          <Input name={getFieldName('employeeName')}/>
                        </StandardInputRow>
                        <StandardInputRow label={'Date'} columnSize={5}>
                          <DatepickerInput name={getFieldName('date')} allowFuture={false}/>
                        </StandardInputRow>
                        <StandardInputRow label={'Referred by'} columnSize={5}>
                          <Input name={getFieldName('referredBy')}/>
                        </StandardInputRow>
                        <StandardInputRow label={'Reason for call'}>
                          <Input name={getFieldName('reasonForCall')} type={'textarea'}/>
                        </StandardInputRow>
                        <StandardInputRow label={'FPD Referral'}>
                          <StandardCheckboxInput label={'Yes'} name={getFieldName('fpdReferral')}/>
                        </StandardInputRow>
                      </fieldset>
                    </Tab>
                    <Tab eventKey={
                      getFieldName('personalInformation')}
                         {...tabTitleFormatter('Personal info', [getFieldName('personalInformation')], errors)}
                         className={styles['form-tab']}
                         >
                      <fieldset disabled={disabled}>
                        <PersonalInformationForm fieldPrefix={getFieldName('personalInformation')}/>
                      </fieldset>
                    </Tab>
                    <Tab eventKey={`${getFieldName('humanTraffickingIdentificationQuestions')}-${getFieldName('parProgramQuestions')}`}
                         {...tabTitleFormatter(
                           'Trafficking & PAR',
                           [getFieldName('humanTraffickingIdentificationQuestions'), getFieldName('parProgramQuestions')],
                           errors
                         )}
                         className={styles['form-tab']}
                    >
                      <fieldset disabled={disabled}>
                        <HumanTraffickingIdentificationAndParQuestionsForm
                          humanTraffickingPrefix={getFieldName('humanTraffickingIdentificationQuestions')}
                          parProgramPrefix={getFieldName('parProgramQuestions')}
                        />
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('assessmentQuestions')}
                      {...tabTitleFormatter('Assessment', [getFieldName('assessmentQuestions')], errors)}
                      className={styles['form-tab']}
                      disabled={disableTabsCondition(values)}
                    >
                      <fieldset disabled={disabled}>
                        <AssessmentQuestionsForm fieldPrefix={getFieldName('assessmentQuestions')}/>
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('humanTraffickingInformation')}
                      {...tabTitleFormatter('Trafficking info', [getFieldName('humanTraffickingInformation')], errors)}
                      className={styles['form-tab']}
                      disabled={disableTabsCondition(values)}
                    >
                      <fieldset disabled={disabled}>
                        <HumanTraffickingInformationForm fieldPrefix={getFieldName('humanTraffickingInformation')}/>
                      </fieldset>
                    </Tab>
                    <Tab
                      eventKey={getFieldName('closingInformation')}
                      {...tabTitleFormatter('Closing info', [getFieldName('closingInformation')], errors)}
                      className={styles['form-tab']}
                      disabled={disableTabsCondition(values)}
                    >
                      <fieldset disabled={disabled}>
                        <ClosingInformationForm fieldPrefix={getFieldName('closingInformation')}/>
                      </fieldset>
                    </Tab>
                  </Tabs>
                  {errorMessage !== '' ?
                    <div style={{marginTop: '1rem'}}>
                      <Alert variant='danger'>{errorMessage}</Alert>
                    </div>
                    : null}
                  <StandardTopPaddedRow style={{paddingTop: '10px'}}>
                    <BSForm.Group className={styles['form-buttons']}>
                      {renderButtons(setValues)}
                    </BSForm.Group>
                  </StandardTopPaddedRow>
                </Form>
              </Col>
            </Modal.Body>
          </Modal>
        )}
      </Formik>
      <PrimaryIntakeInformationModal show={currentModal === 'info'} onSubmit={() => setCurrentModal('intake')}/>
      {renderCancelConfirmationDialog()}
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    saveUser: userStore.actions.save,
    saveIntake: primaryIntakeStore.actions.save
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(PrimaryIntakeModal);
