import {api} from './index';
import {
  DashboardMessage,
  DashboardParticipantData,
  StaffDashboardState,
  DashboardTask,
  ParticipantDashboardState, DashboardClass
} from '../types/DashboardTypes';
import {localTz} from '../util';
import {utcToZonedTime} from 'date-fns-tz';
import {User} from '../redux/web/entities/user';

export async function getDashboardState() {
  const localizeAdmissionDate = (data: DashboardParticipantData[]): DashboardParticipantData[] =>
    data.map((r) => ({...r, admissionDate: utcToZonedTime(r.admissionDate, localTz as any as string)}));

  const state = (await api.get<StaffDashboardState>(`/Dashboard/staff`)).data;
  const localizedState: StaffDashboardState = {
    ...state,
    participantsThisWeek: localizeAdmissionDate(state.participantsThisWeek),
    participantsThisMonth: localizeAdmissionDate(state.participantsThisMonth),
    participantsThisYear: localizeAdmissionDate(state.participantsThisYear),
    participants: localizeAdmissionDate(state.participants)
  };
  return localizedState;
}

export async function getParticipantDashboardState() {
  const localizeClassDates = (data: DashboardClass[]): DashboardClass[] =>
    data.map((r) => ({...r, date: utcToZonedTime(r.date, localTz as any as string)}));

  const state = (await api.get<ParticipantDashboardState>('/Dashboard/participant')).data;
  const localizedState: ParticipantDashboardState = {
    ...state,
    classes: localizeClassDates(state.classes)
  };
  return localizedState;
}

export async function getDashboardParticipantData() {
  return (await api.get<User[]>(`/Dashboard/participants`)).data;
}

export async function upsertDashboardMessage(message: DashboardMessage) {
  return (await api.post<DashboardMessage>('/Dashboard/message', message)).data;
}

export async function upsertDashboardTasks(tasks: DashboardTask[]) {
  return (await api.post<DashboardTask[]>('/Dashboard/tasks', tasks)).data;
}

export async function assignDashboardTask(task: DashboardTask) {
  return (await api.post<DashboardTask[]>('/Dashboard/task', task)).data;
}

export async function completeDashboardTask(id: string) {
  return (await api.post(`/Dashboard/task/${id}`)).data;
}
