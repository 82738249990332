import {Col, Row} from 'react-bootstrap';
import React, {CSSProperties, ReactNode} from 'react';

export interface InformationLabelProps {
  label: number | string;
  data: string | number | ReactNode;
  linkProps?: {label: boolean; data: boolean; onClick: (data: any) => void};
  labelSize?: number;
  dataSize?: number;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  dataStyle?: CSSProperties;
  rows?: number;
}

const InformationLabel = (props: InformationLabelProps) => (
  <Row rows={props.rows} style={props.style}>
    <Col lg={props.labelSize ?? (props.dataSize ? 12 - props.dataSize : 5)} style={{paddingTop: '1rem', ...props.labelStyle}}>
      {props.linkProps?.label ?
        <a onClick={props.linkProps.onClick} style={{cursor: 'pointer', color: 'blue', fontWeight: 'bold', textDecoration: 'underline'}}>{props.label}</a>
        : <span style={{fontWeight: 'bold'}}>{props.label}</span>}
    </Col>
    <Col lg={props.dataSize ?? (props.labelSize ? 12 - props.labelSize : 7)} style={{paddingTop: '1rem', ...props.dataStyle}}>
      {props.linkProps?.data ?
        <a onClick={props.linkProps.onClick} style={{cursor: 'pointer', color: 'blue', fontWeight: 'bold', textDecoration: 'underline'}}>{props.label}</a>
        : <span>{props.data}</span>}
    </Col>
  </Row>
);

export default InformationLabel;
