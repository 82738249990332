import React from 'react';
import {connect} from 'react-redux';
import {Button, Form as BSForm, Modal, Row} from 'react-bootstrap';
import styles from './OpenReportConfiguration.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/core/types/WebState';
import {ReportConfiguration} from '../../../../../redux/web/entities/reportConfiguration';
import gridStyles from '../../../../GridStyles.module.scss';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.js';
import {userStore} from '../../../../../redux/web/entities/user';

type Props = {
  onCancel: () => void;
  onDelete: (confidId: string) => void;
  onSubmit: (config: ReportConfiguration) => void;
  onCopy: (configId: string) => void;
  reportConfigurations: ReportConfiguration[];
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function OpenReportConfigurationModal(props: Props) {
  const {onSubmit, onCancel, onDelete, onCopy, reportConfigurations, currentUser} = props;
  const { SearchBar } = Search;






  const actionsFormatter = (cell: any, document: ReportConfiguration) => {
    return (
      <>
        <div className={gridStyles['table-actions-wrapper']}>
          <div>
            <IconButton
              icon='edit'
              size={'2x'}
              styles={{marginLeft: '.5rem'}}
              onClick={async () => {
                await onSubmit(document);
              }}
              color={'#005A9C'}
              iconToolTipText={'Open'}
            />
          </div>
          {currentUser?.role.roleName === 'Administrator' ?
            <>
              <div>
                <IconButton
                  icon='copy'
                  size={'2x'}
                  styles={{marginLeft: '.5rem'}}
                  onClick={async () => {
                    await onCopy(document.id);
                  }}
                  color={'#005A9C'}
                  iconToolTipText={'Copy'}
                />
              </div>
              <div>
                <IconButton
                  icon='trash-alt'
                  size={'2x'}
                  styles={{color: 'red', marginLeft: '.5rem'}}
                  onClick={() => onDelete(document.id)}
                  iconToolTipText={'Delete'}
                />
              </div>
            </>
            : null}
        </div>

      </>
    );
  };


  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '8.5rem'};
      },
      style: () => {
        return { width: '8.5rem'};
      }
    }
  ];




  const renderTemplateTable = () => (
    <ToolkitProvider
      keyField='id'
      data={reportConfigurations}
      columns={columns}
      search={{
        searchFormatted: true
      }}
    >
      {(tableProps: {
        baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<ReportConfiguration, number>> & Readonly<BootstrapTableProps>;
        searchProps: SearchProps<ReportConfiguration>;
      }) => (
        <>
          <SearchBar {...tableProps.searchProps} placeholder={'Search'} />
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={gridStyles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
        </>
      )}
    </ToolkitProvider>
  );









  return (
    <Modal show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Row>
          <Modal.Title style={{marginBottom: '1rem'}}>Open Report Template</Modal.Title>
          <BSForm.Group className={styles['form-buttons']}>
            <Button onClick={onCancel} variant={'info'} className={styles['close-button']}>
              {'Close'}
            </Button>
          </BSForm.Group>
        </Row>
        {renderTemplateTable()}
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({currentUser: userStore.selectors.getCurrentUser(state)});
export default connect(mapStateToProps, mapDispatchToProps)(OpenReportConfigurationModal);
