import {Dictionary} from '../redux/util';
import {api} from './index';
import {ParticipantClass} from '../redux/web/entities/participantClass';
import {ParticipantClassManagementState} from '../redux/web/stateResponses/participantClassManagement';

export async function getCurrentUserParticipantClasses() {
  return (await api.get<Dictionary<ParticipantClass>>('/ParticipantClass')).data;
}

export async function getParticipantClassManagementState() {
  return (await api.get<ParticipantClassManagementState>('/ParticipantClass/management')).data;
}

export async function upsertParticipantClass(pc: ParticipantClass) {
  return (await api.post<ParticipantClass>('/ParticipantClass', pc)).data;
}

export async function deleteParticipantClass(id: string) {
  return (await api.delete<string>(`/ParticipantClass/${id}`)).data;
}
