import styles from './DashboardTasksAssignedToUsersGrid.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import React, {useState} from 'react';
import {ParticipantClass, participantClassStore} from '../../../../../../redux/web/entities/participantClass';
import {format} from 'date-fns-tz';
import {DashboardTask} from "../../../../../../types/DashboardTypes";
import {makeParticipantClass} from "../../../../../../redux/web/factory";
import {Navigate, useSearchParams} from "react-router-dom";
import {utcToZonedTime} from "date-fns-tz";
import {localTz, localTzFriendlyFormat, now} from "../../../../../../util";
import gridStyles from "../../../../../GridStyles.module.scss";
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from "react-bootstrap-table-next";
// @ts-ignore
import ToolkitProvider, {ColumnToggle, Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import {parseISO} from "date-fns";
import {userStore} from "../../../../../../redux/web/entities/user";

type Props = {
  tasksAssignedToUsers: DashboardTask[];
} & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function DashboardTasksAssignedToUsersGrid(props: Props) {
  const {getUserById, tasksAssignedToUsers, actions: {deleteParticipantClass}} = props;
  const [isDeleting, setIsDeleting] = useState('');
  const [currentModal, setCurrentModal] = useState<'none' | 'upsert' | 'attendance'>('none');
  const [editingParticipantClass, setEditingParticipantClass] = useState(makeParticipantClass());
  const [showColumnToggleFilters, setShowColumnToggleFilters] = useState(false);
  const [searchParams] = useSearchParams();
  const {SearchBar} = Search;
  const [redirectUrl, setRedirectUrl] = useState('');
  const getFieldName = (name: keyof ParticipantClass) => name;

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl}/>;
    }
    return null;
  };

  const dateTimeFormatter = (cell: any, participantClass: ParticipantClass) => {
    const formatTime = (isoDate: string) => format(utcToZonedTime(parseISO(isoDate), localTz as any as string), 'p', {timeZone: localTz as any as string});
    const formatDate = (isoDate: string) => format(parseISO(isoDate), 'MM/dd/yyyy');
    return `${formatDate(participantClass.startDateTime)} ${formatTime(participantClass.startDateTime)} - ${formatTime(participantClass.endDateTime)}`;
  };

  const participantsFormatter = (cell: any, participantClass: ParticipantClass) => {
    const classAssignments = participantClass.participantClassAssignments;
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        {`${classAssignments.length} ${classAssignments.length === 1 ? 'participant' : 'participants'}`}
        {parseISO(participantClass.endDateTime) < now ? `, ${classAssignments.filter(assignment => assignment.attendance).length} attended` : ''}
      </div>
    );
  };

  const [columns, setColumns] = useState<ColumnDescription[]>([
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
      searchable: false
    },
    {
      dataField: 'userId',
      text: 'Assignee',
      sort: true,
      formatter: (cell, row) => getUserById(row.userId)?.name
    },
    {
      dataField: 'name',
      text: 'Task Name',
      sort: true
    },
    {
      dataField: 'description',
      text: `Description`,
      sort: true
    },
    {
      dataField: 'complete',
      text: 'Completed',
      sort: true,
      formatter: (cell, row) => row.complete ? 'Yes' : 'No'
    }
  ]);

  return (
    <ToolkitProvider
      keyField='id'
      data={tasksAssignedToUsers}
      columns={columns}
      search={{
        searchFormatted: true
      }}
      columnToggle={true}
    >
      {(tableProps:
          { baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<DashboardTask, number>> & Readonly<BootstrapTableProps>;
            searchProps: SearchProps<DashboardTask>; }) => (
        <>
          <SearchBar {...tableProps.searchProps}/>
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={styles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    deleteParticipantClass: participantClassStore.actions.delete
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTasksAssignedToUsersGrid);
