import {DashboardDocumentData} from '../../../../types/DashboardTypes';
import DashboardCard from '../DashboardCard/DashboardCard';
import React, {useState} from 'react';
import InformationLabel from '../../../../components/util/form-components/InformationLabel/InformationLabel';
import {Button, Form as BSForm, Modal, Row} from 'react-bootstrap';
import {AppTheme} from '../../../../appTheme';
import {ListTable} from '../../../../components/util/lists/ListTable/ListTable';
import {bindActionCreators, Dispatch} from 'redux';
import {loadUserManagementData} from '../../../../redux/web/stateResponses/stateManagement';
import {WebState} from '../../../../redux/core/types/WebState';
import {userStore} from '../../../../redux/web/entities/user';
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {RoutePaths} from '../../../../router/RoutePaths';

type Props = {
  documentsAwaitingSignature: DashboardDocumentData[];
} & ReturnType<typeof mapStateToProps>;

const DashboardDocuments = (props: Props) => {
  const {documentsAwaitingSignature, getUserById, isAdministrator} = props;
  const [showAwaitingSignatureModal, setShowAwaitingSignatureModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const renderButtons = () => {
    return (
      <>
        <Button onClick={() => setShowAwaitingSignatureModal(false)} color={AppTheme.colors.primary}>
            {'Close'}
          </Button>
      </>
    );
  };

  const renderAwaitingSignatureModal = () => (
    <Modal show={showAwaitingSignatureModal} centered={true}>
      <Modal.Body>
        <Modal.Title>Documents awaiting signature</Modal.Title>
        <ListTable
          items={documentsAwaitingSignature}
          style={{marginTop: '10px'}}
          renderItem={(item, index) => (
            <InformationLabel
              label={item.name}
              data={getUserById(item.userId)?.name}
              labelSize={8}
              dataSize={4}
              style={{boxShadow: index !== 0 ? 'rgb(0 0 0 / 50%) 0px -3px 3px -3px' : undefined, margin: '10px 0 15px 0'}}
              linkProps={isAdministrator ?
                {label: true, data: false, onClick: () => setRedirectUrl(RoutePaths.caseFilePaths.CaseFile.replace(':id', item.userId))}
                : undefined
              }
            />
          )}
          noItemsLabel={'No documents awaiting signature.'}
        />
          <Row style={{paddingTop: '10px'}}>
            <BSForm.Group style={{marginLeft: 'auto', marginRight: '10px', borderRadius: '10px', minWidth: '80px'}}>
              {renderButtons()}
            </BSForm.Group>
          </Row>
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      <DashboardCard title={'Documents'}>
        <InformationLabel
          label={'Documents awaiting signature'}
          data={documentsAwaitingSignature.length}
          labelSize={10}
          dataSize={2}
          linkProps={{label: true, data: false, onClick: () => setShowAwaitingSignatureModal(true)}}
        />
      </DashboardCard>
      {renderAwaitingSignatureModal()}
      {renderRedirect()}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    loadUsers: loadUserManagementData
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  isAdministrator: userStore.selectors.isAdministrator(state),
  participants: userStore.selectors.getParticipants(state),
  getUserById: userStore.selectors.getById(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDocuments);
