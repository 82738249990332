import {mapRoleToProps, mapRoleToPropsType, userStore} from '../../../redux/web/entities/user';
import {Navigate} from 'react-router';
import {RoutePaths} from '../../../router/RoutePaths';
import {connect} from 'react-redux';
import React from 'react';
import {WebState} from '../../../redux/core/types/WebState';
import {useLocation} from "react-router-dom";

type StaffAndShelterStaffComponentProps = ReturnType<typeof mapStateToProps> & {
  children: JSX.Element;
};

const StaffAndShelterStaffComponent = ({children, roleName}: StaffAndShelterStaffComponentProps) => {
  return (roleName === 'Staff' || roleName === 'Shelter Staff') ? children : null;
};

const mapStateToProps = (state: WebState) => ({
  roleName: mapRoleToProps(state).roleName
});

export default connect(mapStateToProps)(StaffAndShelterStaffComponent);
