import React, {useEffect, useMemo, useRef, useState} from 'react';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import styles from './ChoreChartCalendar.module.scss';
import {choreChartWeekStore, ChoreChartWeek} from '../../../redux/web/entities/choreChartWeek';
import {
  addDays,
  addWeeks,
  format,
  getDate,
  getDay,
  getWeek,
  getYear,
  startOfWeek,
  subWeeks
} from 'date-fns';
import {Alert, Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {AppTheme} from '../../../appTheme';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import {useStandardEditor} from '../../../components/util/form-components/EditorForm/hooks';
import {
  makeChoreChartWeek
} from '../../../redux/web/factory';
import {RoutePaths} from '../../../router/RoutePaths';
import {EditorForm} from '../../../components/util/form-components/EditorForm/EditorForm';
import ChoreChartCalendarEditableFields from './ChoreChartCalendar';
import {userStore} from '../../../redux/web/entities/user';
import {FieldArray} from 'formik';
import {loadChoreChartData} from '../../../redux/web/stateResponses/choreChartData';
import ReactDatePicker from 'react-datepicker';
import {Form as BSForm} from 'react-bootstrap';
import {StandardInputRow, StandardTopPaddedRow} from '../../../components/util/form-components/standardLayout';
import ChoreChartCalendar from './ChoreChartCalendar';
import {useReactToPrint} from 'react-to-print';

export interface ChoreChartDay {
  date: Date;
  formattedDate: string;
  dayOfTheWeek: number;
  dayOfTheMonth: number;
}

type Props = {  initialDate: Date } & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function ChoreChartCalendarController(props: Props) {
  const {choreChartWeeks, getChoreChartWeekByDate, isAdministrator, initialDate, actions: {upsertWeek, loadAdditionalChoreChartData}} = props;

  const [currentDate, setCurrentDate] = useState(initialDate);
  const currentWeek = () => getWeek(currentDate);
  const currentYear = () => getYear(currentDate);
  const getWeekFieldName = (name: keyof ChoreChartWeek) => name;
  const makeCurrentChoreChartWeek = () => makeChoreChartWeek(currentYear(), currentWeek(), getChoreChartWeekByDate(currentYear(), currentWeek()));
  const [loadedYears, setLoadedYears] = useState<number[]>([getYear(initialDate)]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [copyDate, setCopyDate] = useState(currentDate);
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: '@page { size: auto;  margin: 4rem 2rem; } }'
  });

  useEffect(() => {
    const loadAdditional = async () => {
      if (!loadedYears.includes(currentYear())) {
        await loadAdditionalChoreChartData(currentDate.toISOString());
        setLoadedYears((prevState) => [...prevState, getYear(currentDate)]);
      }
    };

    loadAdditional();
  }, [loadedYears, currentYear()]);

  const getViewParamsUrl = () => RoutePaths.choreChartPaths.ViewParams
    .replace(':date', format(currentDate, 'MM-dd-yyyy'));

  const getEditParamsUrl = () => RoutePaths.choreChartPaths.EditParams
    .replace(':date', format(currentDate, 'MM-dd-yyyy'));

  const standardEditor = useStandardEditor<ChoreChartWeek>(
    'Chore Chart',
    makeCurrentChoreChartWeek(),
    props,
    s => ({
      getEditUrl: () => getEditParamsUrl(),
      getCancelUrl: () => getViewParamsUrl(),
      onLoadForm: async () => makeCurrentChoreChartWeek(),
      onNewForm: async () => makeCurrentChoreChartWeek(),
      saveAndRedirect: async (form) => {
        await upsertWeek(form);
        return getViewParamsUrl();
      },
      header: true,
      editButtonText: 'Edit',
      saveButtonText: 'Submit',
      submitButtonColor: AppTheme.colors.primary,
      buttonWrapperStyle: {flexWrap: 'nowrap'},
      additionalButtonsProps: {
        buttons: [
          {style: {backgroundColor: AppTheme.colors.primary}, showView: true, onClick: (async () => setShowCopyModal(true)), children: 'Copy'},
          {style: {backgroundColor: AppTheme.colors.primary}, showView: true, children: 'Print', onClick: handlePrint}
        ],
        position: 'left'
      }
    }));
  const {editable} = standardEditor;

  const handleDateChange = (action: 'back' | 'forward') => {
    if (action === 'back') {
      setCurrentDate(() => subWeeks(currentDate, 1));
    } else {
      setCurrentDate(() => addWeeks(currentDate, 1));
    }
  };

  const getDayColor = (day: number) => {
    switch (day) {
      case 0: return AppTheme.colors.sunday;
      case 1: return AppTheme.colors.monday;
      case 2: return AppTheme.colors.tuesday;
      case 3: return AppTheme.colors.wednesday;
      case 4: return AppTheme.colors.thursday;
      case 5: return AppTheme.colors.friday;
      case 6: return AppTheme.colors.saturday;
      default: return AppTheme.colors.background;
    }
  };

  const renderCopyModal =
    (currentWeekId: string, setValues: (values: React.SetStateAction<ChoreChartWeek>, shouldValidate?: (boolean | undefined)) => void) => (
      <Modal show={showCopyModal} centered={true}>
        <Modal.Body>
          <Modal.Title>Copy a Week</Modal.Title>
          <BSForm>
            <StandardTopPaddedRow>
              <StandardInputRow label={'Date:'} labelSize={2} columnSize={8}>
                <ReactDatePicker
                  customInput={<Form.Control/>}
                  value={format(copyDate, 'MM/dd/yyyy')}
                  onChange={(date: Date | null) => date && setCopyDate(date)}
                />
              </StandardInputRow>
            </StandardTopPaddedRow>
            <StandardTopPaddedRow>
              <BSForm.Group className={styles['form-buttons']}>
                <Button className={styles['close-button']} variant={'danger'} onClick={() => setShowCopyModal(false)}>
                  Cancel
                </Button>
                <Button
                  variant={'success'}
                  onClick={() => {
                    const copyingWeek = choreChartWeeks.find(w => (w.year === getYear(copyDate) && w.weekOfTheYear === getWeek(copyDate)));
                    if (copyingWeek) {
                      const newWeek: ChoreChartWeek = {
                        id: '',
                        reminder: copyingWeek.reminder,
                        year: getYear(currentDate),
                        weekOfTheYear: getWeek(currentDate),
                        rows: copyingWeek.rows.map((row) => {
                          return {...row, id: '', chores: row.chores.map((chore) => ({...chore, id: '', done: false}))};
                        })};
                      setValues(newWeek);
                      upsertWeek(newWeek);
                      setErrorMessage('');
                    }
                    else setErrorMessage('No values could be copied from the specified date.');
                    setShowCopyModal(false);
                    setCopyDate(currentDate);
                  }}
                >
                  Copy
                </Button>
              </BSForm.Group>
            </StandardTopPaddedRow>
          </BSForm>
        </Modal.Body>
      </Modal>
  );

  const renderCalendar = () => (
    <Col style={{width: '100%', overflowX: 'auto', padding: '0'}}>
      {isAdministrator ?
        <EditorForm standardEditor={standardEditor}>
          {({values, setFieldValue, setValues}) =>
            <>
              <FieldArray
                name={getWeekFieldName('rows')}
                validateOnChange={false}
                render={(helpers) =>
                  <ChoreChartCalendar
                    ref={printRef}
                    currentDate={currentDate}
                    editProps={editable ? {setFieldValue: setFieldValue, helpers: helpers} : undefined}
                    renderedValues={editable ? values : makeCurrentChoreChartWeek()}
                    setErrorMessage={setErrorMessage}
                  />}
              />
              {renderCopyModal(values.id, setValues)}
            </>
          }
        </EditorForm> :
        <>
          <Row style={{marginBottom: '1rem', justifyContent: 'flex-end'}}>
            <Button style={{backgroundColor: AppTheme.colors.primary, marginRight: '30px', borderRadius: '10px', minWidth: '80px'}} onClick={handlePrint}>
              Print
            </Button>
          </Row>
          <ChoreChartCalendar
            ref={printRef}
            currentDate={currentDate}
            showParticipantProps={true}
            renderedValues={makeCurrentChoreChartWeek()}
            setErrorMessage={setErrorMessage}
          />
        </>
      }
    </Col>
  );

  return (
    <Col style={{padding: '0'}}>
      <Row style={{ flexWrap: 'nowrap'}}>
        {!editable ?
          <Col style={{maxWidth: '2rem', justifyContent: 'center', alignItems: 'center', padding: '0'}}>
            <IconButton icon={'caret-left'} size={'3x'} onClick={() => handleDateChange('back')}/>
          </Col>
          : null}
        {renderCalendar()}
        {!editable ?
          <Col style={{maxWidth: '2rem', justifyContent: 'center', alignItems: 'center', padding: '0'}}>
            <IconButton icon={'caret-right'} size={'3x'} onClick={() => handleDateChange('forward')}/>
          </Col>
          : null}
      </Row>
      {errorMessage !== '' ?
        <div style={{marginTop: '1rem'}}>
          <Alert variant='danger'>{errorMessage}</Alert>
        </div>
        : null}
    </Col>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertWeek: choreChartWeekStore.actions.upsertWeek,
    loadAdditionalChoreChartData: loadChoreChartData
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  isAdministrator: userStore.selectors.isAdministrator(state),
  choreChartWeeks: choreChartWeekStore.selectors.getAsArray(state),
  getChoreChartWeekByDate: choreChartWeekStore.selectors.getWeek(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(ChoreChartCalendarController);
