import React, {useState} from 'react';
import {useCaseFileCache} from '../../../../hooks/useCaseFileCache';
import DashboardCard from '../DashboardCard/DashboardCard';
import {Col, Row} from 'react-bootstrap';
import {WebState} from '../../../../redux/core/types/WebState';
import {userStore} from '../../../../redux/web/entities/user';
import {connect} from 'react-redux';
import ProfilePicture from '../../../../components/util/ProfilePicture/ProfilePicture';
import InformationLabel from '../../../../components/util/form-components/InformationLabel/InformationLabel';
import {RoutePaths} from '../../../../router/RoutePaths';
import {Navigate} from 'react-router-dom';

const DashboardRecentCaseFiles = (props: ReturnType<typeof mapStateToProps>) => {
  const recentCaseFileUserIds = useCaseFileCache(props.currentUser?.id ?? '');
  const [redirectUrl, setRedirectUrl] = useState('');

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const renderBody = () => {
    if(!recentCaseFileUserIds) {
      return (
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 0'
          }}
        >
          <div>No recently visited case files.</div>
        </Row>
      );
    }
    const reversed = [...recentCaseFileUserIds!].reverse();
    return reversed.map((userId, index) => {
      const user = props.getUserById(userId);
      return (
        <Row
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 0',
            boxShadow: index !== 0 ? 'rgb(0 0 0 / 50%) 0px -3px 3px -3px' : undefined
          }}
        >
          <div style={{display: 'flex', flexDirection: 'column', flex: '1', paddingRight: '1rem'}}>
            <a
              onClick={() => setRedirectUrl(RoutePaths.caseFilePaths.CaseFile.replace(':id', userId))}
              style={{cursor: 'pointer', color: 'blue', fontWeight: 'bold', textDecoration: 'underline'}}
            >
              {user?.name}
            </a>
          </div>
          <div style={{display: 'inline-flex', alignItems: 'center'}}>
            <ProfilePicture userId={userId} editable={false} style={{height: '2rem', width: '2rem'}}/>
          </div>
        </Row>
      );
    });
  };

  return (
    <DashboardCard title={'Recently Visited Case Files'} bodyStyle={{paddingBottom: '0'}}>
      <Col>
      {renderBody()}
      </Col>
      {renderRedirect()}
    </DashboardCard>
  );
};

const mapStateToProps = (state: WebState) => ({
  getUserById: userStore.selectors.getById(state),
  currentUser: userStore.selectors.getCurrentUser(state)
});

export default connect(mapStateToProps)(DashboardRecentCaseFiles);
