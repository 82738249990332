import {array, date, object, string} from 'yup';
import {TPrimaryIntakeUpsertForm} from './PrimaryIntakeModal';
import {createAllObjectShapes} from '../../../../../../redux/util/yup';
import {
  THumanTraffickingIdentificationQuestionsForm, TParProgramQuestionsForm
} from './components/forms/HumanTraffickingIdentificationAndParQuestionsForm';
import {
  makeAssessmentQuestions, makeChildInformation, makeClosingInformation, makeHumanTraffickingInformation,
  makeParProgramQuestions, makePersonalInformation,
  makePrimaryIntakeForm
} from '../../../../../../redux/web/factory/forms/intakeForms';

const getPrimaryIntakeFieldName = (name: keyof TPrimaryIntakeUpsertForm) => name;
const getTraffickingIdFieldName = (name: keyof THumanTraffickingIdentificationQuestionsForm) => name;
const getParProgramFieldName = (name: keyof TParProgramQuestionsForm) => name;

// When human trafficking has been identified, the PAR assessment is to be skipped
const disableParProgramFields = [
  `${getPrimaryIntakeFieldName('humanTraffickingIdentificationQuestions')}.${getTraffickingIdFieldName('experiencedHumanTrafficking')}`,
  `${getPrimaryIntakeFieldName('humanTraffickingIdentificationQuestions')}.${getTraffickingIdFieldName('tradedSexualFavors')}`
];

// When the PAR assessment tests positive, the form is to be turned into Jennifer Duarte
const disableSeveralFormsFields = [
  `${getPrimaryIntakeFieldName('parProgramQuestions')}.${getParProgramFieldName('arrestedForSex')}`, ...disableParProgramFields
];

const disabledWhenTrueConfig = <T extends object>(factoryFn: () => T) => {
  return {
    then: object(createAllObjectShapes(factoryFn(), {defaultRequired: false, strictTypes: false})),
    otherwise: object(createAllObjectShapes(factoryFn(), {defaultRequired: true, strictTypes: false}))
  };
};

// @ts-ignore
export const PrimaryIntakeModalSchema = object<TPrimaryIntakeUpsertForm>(
  createAllObjectShapes(makePrimaryIntakeForm(), undefined, {
    [getPrimaryIntakeFieldName('id')]: string().nullable(),
    [getPrimaryIntakeFieldName('userId')]: string().nullable(),
    [getPrimaryIntakeFieldName('date')]: date().nullable(),
    [getPrimaryIntakeFieldName('personalInformation')]: object({...createAllObjectShapes(makePersonalInformation(),
        undefined, {
      ['middleInitial']: string().nullable(),
      ['primaryPhone']: string().nullable(),
      ['secondaryPhone']: string().nullable(),
      ['email']: string().nullable().email('Email must be in a valid format.'),
      ['childrenInformation']: array().required().of(
        object({...createAllObjectShapes(makeChildInformation())})
      )
    })}),
    [getPrimaryIntakeFieldName('parProgramQuestions')]: object().required().when(
      disableParProgramFields, {
        is: (experiencedHT: boolean, tradedSF: boolean) => experiencedHT || tradedSF,
        then: object(createAllObjectShapes(makeParProgramQuestions(), {defaultRequired: false, strictTypes: false})),
        otherwise: object(createAllObjectShapes(makeParProgramQuestions(), {defaultRequired: true, strictTypes: false}))
      }),
    [getPrimaryIntakeFieldName('assessmentQuestions')]: object().required().when(
      disableSeveralFormsFields, {
        is: (arrestedForSex: boolean, experiencedHT: boolean, tradedSexualFavors: boolean) => arrestedForSex && !(experiencedHT || tradedSexualFavors),
        then: object(createAllObjectShapes(makeAssessmentQuestions(), {defaultRequired: false, strictTypes: false})),
        otherwise: object(createAllObjectShapes(makeAssessmentQuestions(), {defaultRequired: true, strictTypes: false}))
      }),
    [getPrimaryIntakeFieldName('humanTraffickingInformation')]: object().required().when(
      disableSeveralFormsFields, {
        is: (arrestedForSex: boolean, experiencedHT: boolean, tradedSexualFavors: boolean) => arrestedForSex && !(experiencedHT || tradedSexualFavors),
        ...disabledWhenTrueConfig(makeHumanTraffickingInformation)
      }),
    [getPrimaryIntakeFieldName('closingInformation')]: object().required().when(
      disableSeveralFormsFields, {
        is: (arrestedForSex: boolean, experiencedHT: boolean, tradedSexualFavors: boolean) => arrestedForSex && !(experiencedHT || tradedSexualFavors),
        ...disabledWhenTrueConfig(makeClosingInformation)
      })
  })
);
