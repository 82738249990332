import {Dispatch} from 'redux';
import {UserFormsState} from './userFormsData';
import {ReportConfiguration, reportConfigurationStore} from '../entities/reportConfiguration';
import {getConfigurations} from '../../../api/exportApi';
import {Dictionary} from '../../util';

export const loadReportConfigurations = () => async (dispatch: Dispatch) => {
  const response: Dictionary<ReportConfiguration> = await getConfigurations();
    dispatch(reportConfigurationStore.actions.set(response));
};
