import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';
import {Form, Formik, FormikErrors} from 'formik';
import {Alert, Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {AxiosError} from 'axios';
import styles from './SaveReportModal.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import { handleAxiosError } from '../../../../../redux/util/http';
import {SaveExportModalSchema} from './SaveExportModalSchema';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {WebState} from '../../../../../redux/core/types/WebState';
import {ReportConfiguration, reportConfigurationStore} from '../../../../../redux/web/entities/reportConfiguration';
import {makeReportConfiguration} from '../../../../../redux/web/factory';
import {Report} from 'flexmonster';

type Props = {
  onCancel: () => void;
  onSubmit: (response: ReportConfiguration) => void;
  existingConfiguration?: ReportConfiguration;
  currentData: Report;
  currentSubset: string;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function SaveExportModal(props: Props) {
  const {onSubmit, onCancel, existingConfiguration, currentData, currentSubset, actions: {upsertReportRedux}} = props;
  const getFieldName = (name: keyof ReportConfiguration) => name;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: ReportConfiguration,
    validate: (values: ReportConfiguration) => Promise<FormikErrors<ReportConfiguration>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        association.data = JSON.stringify(currentData);
        association.subset = currentSubset;
        const response = upsertReportRedux(association);
        onSubmit(response as any);
      } catch (e: AxiosError | any) {
        setErrorMessage(handleAxiosError(e));
      }
    }
    setIsSubmitting(false);
  };


  const renderButtons = (values: any, validateForm: any, handleSubmit: any) => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={'info'} className={styles['close-button']} style={{marginRight: '1rem'}}>
            Cancel
          </Button>
        }

        <Button
          variant={'success'} type='button'
          onClick={async (e: any) => await processSubmit(e, values, validateForm, handleSubmit)}
        >
          Save
        </Button>
      </>
    );
  };

  return (
    <Modal show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={existingConfiguration !== undefined ? existingConfiguration as ReportConfiguration : makeReportConfiguration(undefined)}
                onSubmit={() => undefined} validationSchema={SaveExportModalSchema}>
          {({values, validateForm, handleSubmit, errors, setFieldValue}) => {
            return (
              <Form noValidate={false}
                    onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>{existingConfiguration?.id !== '' ? 'Update Report Template' : 'Add Report Template'}</Modal.Title>
                <InputRow label={'Name'} columnSize={5} labelSize={2} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('name')}/>
                </InputRow>
                {/*<InputRow label={'Data Set'} columnSize={5} labelSize={2} style={{paddingTop: '10px'}} requiredAsterisk={true}>*/}
                {/*  <CheckBoxInput*/}
                {/*    label={'User Case File Data'}*/}
                {/*    name={getFieldName('subset')}*/}
                {/*    type={'radio'}*/}
                {/*    checked={values.subset === 'CaseFile'}*/}
                {/*    onChange={() => setFieldValue('subset', 'CaseFile')}*/}
                {/*  />*/}
                {/*  <CheckBoxInput*/}
                {/*    label={'Services Provided Data'}*/}
                {/*    name={getFieldName('subset')}*/}
                {/*    type={'radio'}*/}
                {/*    checked={values.subset === 'ServicesProvided'}*/}
                {/*    onChange={() => setFieldValue('subset', 'ServicesProvided')}*/}
                {/*  />*/}
                {/*</InputRow>*/}
                {errorMessage !== '' ?
                  <div style={{marginTop: '1rem'}}>
                    <Alert variant='danger'>{errorMessage}</Alert>
                  </div>
                  : null}
                <Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    {renderButtons(values, validateForm, handleSubmit)}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertReportRedux: reportConfigurationStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getConfigById: reportConfigurationStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(SaveExportModal);
