import React, {useState} from 'react';
import gridStyles from '../../../GridStyles.module.scss';
import {makeCaseFileDocument} from '../../../../redux/web/factory';
import {Navigate, useLocation} from 'react-router-dom';
import IconButton from '../../../../components/util/widgets/IconButton/IconButton';
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
import {Button, Modal, Row} from 'react-bootstrap';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../redux/core/types/WebState';
import {connect} from 'react-redux';
// @ts-ignore
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.js';
import {
  CaseFileDocument,
  CaseFileDocumentStatus,
  caseFileDocumentStore
} from '../../../../redux/web/entities/caseFileDocument';
import CaseFileDocumentModal from '../../Modals/CaseFileDocumentModal';
import CaseFileDocumentAssignmentModal from '../../Modals/CaseFileDocumentAssignmentModal/CaseFileDocumentAssignmentModal';
import {loadCaseFileDocumentState} from '../../../../redux/web/stateResponses/stateManagement';
import {useMount} from '../../../../hooks/useMount';
import {AxiosError} from 'axios';
import {getErrorResponseMessage, handleAxiosError} from '../../../../redux/util/http';
import {CenteredSpinner} from '../../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {ConfirmationDialog} from '../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {camelCaseToTitle, downloadDocument} from '../../../../util';
import CaseFileDocumentMergeModal from '../../Modals/CaseFileDocumentMergeModal/CaseFileDocumentMergeModal';
import {mapRoleToProps, User, userStore} from '../../../../redux/web/entities/user';
import AdministratorComponent from '../../../../components/util/AdministratorComponent/AdministratorComponent';
import GcPdfViewerComponent from '../../../../components/util/gcPdfViewer';
import {getCaseFileDocumentDataById} from '../../../../api/caseFileManagementApi';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
  reloadCaseFileHistory: () => void;
};

function CaseFileDocumentsGrid(props: Props) {
  const {caseFileDocuments, getUserById, getCaseFileDocumentById, userRole, currentUser,
    actions: {disableCaseFileDocument, loadDocuments, signingEmail, voidSigningEmail, getDocumentData}} = props;
  const { SearchBar } = Search;
  const [isViewingPdf, setIsViewingPdf] = useState('');
  const [isViewingImage, setIsViewingImage] = useState('');
  const [currentDocument, setCurrentDocument] = useState('' as any as CaseFileDocument);
  const [isSendingSigningEmail, setIsSendingSigningEmail] = useState('');
  const [isVoidingSigningEmail, setIsVoidingSigningEmail] = useState('');
  const [viewCaseFileDocumentModal, setViewCaseFileDocumentModal] = useState(false);
  const [viewCaseFileDocumentAssignmentModal, setViewCaseFileDocumentAssignmentModal] = useState(false);
  const [disablingDocumentId, setDisablingDocumentId] = useState('');
  const [showPlsNoDeleteSignedDoc, setShowPlsNoDeleteSignedDoc] = useState(false);
  const [viewMergeCaseFileDocumentsModal, setViewMergeCaseFileDocumentsModal] = useState(false);
  const [editingCaseFileDocument, setEditingCaseFileDocument] = useState(makeCaseFileDocument(null));
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const userId = location.pathname.split('/')[2];

  useMount(async () => {
    try {
      await loadDocuments(userId);
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load Documents'}));
    }
    setLoading(false);
  });


  const isReadOnlyDocument = (document: CaseFileDocument) => userRole !== 'Administrator' || document.status !== CaseFileDocumentStatus.None;

  const processDisable = async (caseFileDocumentId: string) => {
    setErrorMessage('');
    try {
      await disableCaseFileDocument(caseFileDocumentId);
      setDisablingDocumentId('');
      setShowPlsNoDeleteSignedDoc(false);
    } catch (e: AxiosError | any) {
      setErrorMessage(handleAxiosError(e));
    }
    props.reloadCaseFileHistory();
  };

  const renderDisableConfirmationDialog = (documentId: string) => {
    const document = getCaseFileDocumentById(documentId);
    return (
      <>
        <ConfirmationDialog
          open={!!disablingDocumentId && !showPlsNoDeleteSignedDoc}
          positiveText={'Delete'}
          positiveVariant={'success'}
          onAccept={async () => {
            if (document.status !== CaseFileDocumentStatus.None) setShowPlsNoDeleteSignedDoc(true);
            else await processDisable(documentId);
          }}
          negativeText={'Cancel'}
          negativeVariant={'danger'}
          onDecline={async () => setDisablingDocumentId('')}
          prompt={`Are you sure you want to delete the document "${document?.name}" from ${getUserById(userId)?.name}'s case file?`}
        />
        <ConfirmationDialog
          open={showPlsNoDeleteSignedDoc}
          positiveText={'Confirm Delete'}
          positiveVariant={'success'}
          onAccept={async () => processDisable(documentId)}
          negativeText={'Cancel'}
          negativeVariant={'danger'}
          onDecline={async () => {
            setShowPlsNoDeleteSignedDoc(false);
            setDisablingDocumentId('');
          }}
          prompt={
            <>
              {document?.name} {document?.status === CaseFileDocumentStatus.Signed ?
              <span style={{fontWeight: 'bolder'}}>is a signed document</span> :
              <span style={{fontWeight: 'bolder'}}>has an active DocuSign request</span>}. Are you CERTAIN you would like to delete this document?
            </>
          }
        />
      </>
    );
  };


  const getDocumentType = (document: CaseFileDocument): 'pdf' | 'image' | 'other' => {
    if (document.data.includes('data:application/pdf')) return 'pdf';
    if (document.data.includes('data:image')) return 'image';
    return 'other';
  };

  const renderSingingVoidingButtons = (document: CaseFileDocument) => {
    const documentType = getDocumentType(document);
    if(documentType === 'other' || documentType === 'image') {
      return null;
    }
    if(document.status === CaseFileDocumentStatus.Signed) {
      return null;
    }
    if(document.status === CaseFileDocumentStatus.AwaitingSignature) {
      return (
        <div>
          <IconButton
            icon='cancel'
            size={'2x'}
            styles={{marginLeft: '.5rem'}}
            onClick={() => {
              setIsVoidingSigningEmail(document.envelopeId);
            }}
            color={'#005A9C'}
            iconToolTipText={'Void Signing Email'}
          />
        </div>
      );
    } else {
      return (
        <div>
          <IconButton
            icon='pen'
            size={'2x'}
            styles={{marginLeft: '.5rem'}}
            onClick={() => {
              setIsSendingSigningEmail(document.id);
            }}
            color={'#005A9C'}
            iconToolTipText={'Send Signing Email'}
          />
        </div>
      );
    }
  };

  const renderDocumentEditButton = (document: CaseFileDocument) => {
    const documentType = getDocumentType(document);
    if (documentType === 'pdf') {
      return (
        <div>
          <IconButton
            icon='eye'
            size={'2x'}
            styles={{marginLeft: '.5rem'}}
            onClick={async () => {
              await getCaseFileDocumentDataById(document.id).then((result) => {
                setCurrentDocument('' as any as CaseFileDocument);
                setIsViewingPdf('');
                setIsViewingPdf(result.data);
                setCurrentDocument(document);
              });
            }}
            color={'#005A9C'}
            iconToolTipText={isReadOnlyDocument(document) ? 'View' : 'Edit'}
          />
        </div>
      );
    }
    if (documentType === 'image') {
      return (
        <div>
          <IconButton
            icon='eye'
            size={'2x'}
            styles={{marginLeft: '.5rem'}}
            onClick={async () => {
              await getCaseFileDocumentDataById(document.id).then((result) => {
                setCurrentDocument('' as any as CaseFileDocument);
                setIsViewingImage('');
                setIsViewingImage(result.data);
                setCurrentDocument(document);
              });
            }}
            color={'#005A9C'}
            iconToolTipText={'View'}
          />
        </div>
      );
    }
    return null;
  };

  const renderImageModal = () => (
    <Modal show={!!isViewingImage} size={'lg'}>
      <Modal.Body>
        <Modal.Title>View Case File Image</Modal.Title>
        <img src={isViewingImage}/>
      </Modal.Body>
    </Modal>
  );


  const actionsFormatter = (cell: any, document: CaseFileDocument) => {
    return (
      <>
        <div className={gridStyles['table-actions-wrapper']}>
          <AdministratorComponent>
            <>
              {renderSingingVoidingButtons(document)}
            </>
          </AdministratorComponent>
          {renderDocumentEditButton(document)}
          <AdministratorComponent>
            <div>
              <IconButton
                icon='edit'
                size={'2x'}
                styles={{marginLeft: '.5rem'}}
                onClick={() => {
                  setEditingCaseFileDocument(document);
                  setViewCaseFileDocumentModal(true);
                }}
                color={'#005A9C'}
                iconToolTipText={'Update'}
              />
            </div>
          </AdministratorComponent>
          <div>
            <IconButton
              icon='download'
              size={'2x'}
              styles={{marginLeft: '.5rem'}}
              onClick={async () => {
                await getCaseFileDocumentDataById(document.id).then((result) => {
                  downloadDocument(result.data, result.name);
                });
              }}
              color={'#005A9C'}
              iconToolTipText={'Download'}
            />
          </div>
          <div>
            <IconButton
              icon='trash-alt'
              size={'2x'}
              styles={{color: 'red', marginLeft: '.5rem'}}
              onClick={() => setDisablingDocumentId(document.id)}
              iconToolTipText={'Delete'}
            />
          </div>
        </div>

      </>
    );
  };

  const statusFormatter = (cell: any, document: CaseFileDocument) => camelCaseToTitle(CaseFileDocumentStatus[document.status]);

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: statusFormatter,
      sort: true
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '8.5rem'};
      },
      style: () => {
        return { width: '8.5rem'};
      }
    }
  ];


  const renderContent = () => (
    <ToolkitProvider
      keyField='id'
      data={caseFileDocuments}
      columns={columns}
      search={{
        searchFormatted: true
      }}
    >
      {(tableProps: {
        baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<User, number>> & Readonly<BootstrapTableProps>;
        searchProps: SearchProps<User>;}) => (
        <>
          <div className={gridStyles['button-groups-container']}>
            <div className={gridStyles['button-group']}>
              <AdministratorComponent>
                <>
                  <Button
                    onClick={() => {
                      setEditingCaseFileDocument(makeCaseFileDocument(null));
                      setViewCaseFileDocumentModal(true);
                    }}
                    style={{backgroundColor: '#005A9C'}}
                  >
                    Add
                  </Button>
                  <Button
                    onClick={() => {
                      setViewCaseFileDocumentAssignmentModal(true);
                    }}
                    style={{backgroundColor: '#005A9C'}}
                  >
                    Assign From Library
                  </Button>
                  <Button
                    onClick={() => {
                      setViewMergeCaseFileDocumentsModal(true);
                    }}
                    style={{backgroundColor: '#005A9C'}}
                    disabled={caseFileDocuments.length < 2}
                  >
                    Merge
                  </Button>
                </>
              </AdministratorComponent>
            </div>
          </div>
          <SearchBar {...tableProps.searchProps} placeholder={'Search'} />
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={gridStyles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
          {viewCaseFileDocumentModal ?
            <CaseFileDocumentModal
              editable={true}
              onSubmit={() => {
                setViewCaseFileDocumentModal(false);
                props.reloadCaseFileHistory();
              }}
              onCancel={() => setViewCaseFileDocumentModal(false)}
              existingCaseFileDocument={editingCaseFileDocument}
            />
            : null}
          {viewCaseFileDocumentAssignmentModal ?
            <CaseFileDocumentAssignmentModal
              editable={true}
              onSubmit={() => {
                setViewCaseFileDocumentAssignmentModal(false);
                props.reloadCaseFileHistory();
              }}
              onCancel={() => setViewCaseFileDocumentAssignmentModal(false)}
            />
            : null}
          {viewMergeCaseFileDocumentsModal ?
            <CaseFileDocumentMergeModal
              userId={userId}
              existingCaseFileDocuments={caseFileDocuments}
              editable={true}
              onSubmit={() => {
                setViewMergeCaseFileDocumentsModal(false);
                props.reloadCaseFileHistory();
              }}
              onCancel={() => setViewMergeCaseFileDocumentsModal(false)}
            />
            : null}
          {isSendingSigningEmail !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
                try{
                  await signingEmail(location.pathname.split('/')[2], isSendingSigningEmail);
                } catch(exception: any) {
                  setErrorMessage(getErrorResponseMessage(exception));
                }
                setIsSendingSigningEmail('');
                props.reloadCaseFileHistory();
              }}
              onDecline={async () => { setIsSendingSigningEmail(''); }}
              open={isSendingSigningEmail !== ''}
              prompt='Send DocuSign Signing Email to this Participant?'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='success'
              negativeVariant='danger'
            />
          )}
          {isVoidingSigningEmail !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
                await voidSigningEmail(isVoidingSigningEmail);
                setIsVoidingSigningEmail('');
                props.reloadCaseFileHistory();
              }}
              onDecline={async () => { setIsVoidingSigningEmail(''); }}
              open={isVoidingSigningEmail !== ''}
              prompt='Void the signature request?'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='success'
              negativeVariant='danger'
            />
          )}
          {isViewingPdf !== '' && (
            <>
              <Modal style={{zIndex: '1000'}} backdropClassName={''} show={true} size='xl' centered={true} onHide={() => null}>
                <Modal.Body>
                  <Row>
                    <Button onClick={() => {
                      setCurrentDocument('' as any as CaseFileDocument);
                      setIsViewingPdf('');
                    }} variant={'info'} style={{marginRight: '5px', marginBottom: '10px'}}>Close</Button>
                    <GcPdfViewerComponent
                      document={`${isViewingPdf}`}
                      disableAutomaticSaving={true}
                      apiEndpointName={'SupportApi'}
                      onSave={async () => {
                        setCurrentDocument('' as any as CaseFileDocument);
                        setIsViewingPdf('');
                        setLoading(true);
                        try {
                          await loadDocuments(userId);
                        } catch (e: AxiosError | any) {
                          setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load Documents'}));
                        }
                        setLoading(false);
                        props.reloadCaseFileHistory();
                      }}
                      currentCaseFile={currentDocument}
                      currentUserId={currentUser?.id}
                      readOnly={isReadOnlyDocument(currentDocument)}
                    />
                  </Row>
                </Modal.Body>
              </Modal>
            </>
          )}
          {isViewingImage !== '' && (
            <>
              <Modal backdropClassName={''} show={true} size='xl' centered={true} onHide={() => null}>
                <Modal.Body>
                  <Row>
                    <Button
                      onClick={() => {
                        setCurrentDocument('' as any as CaseFileDocument);
                        setIsViewingImage('');}}
                      variant={'info'}
                      style={{margin: '0 1rem 10px 5px'}}
                    >
                      Close
                    </Button>
                  </Row>
                  <Row style={{justifyContent: 'center'}}>
                    <img src={isViewingImage} style={{width: '100%', height: 'auto', padding: '0 5px', borderRadius: '1%'}}/>
                  </Row>
                </Modal.Body>
              </Modal>
            </>
          )}
          {renderDisableConfirmationDialog(disablingDocumentId)}
        </>
      )}
    </ToolkitProvider>
  );

  return (
    <>
      {loading ?  <CenteredSpinner/> : (
        errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
          renderContent()
      )}
    </>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    disableCaseFileDocument: caseFileDocumentStore.actions.disableCaseFileDocument,
    upsertDocument: caseFileDocumentStore.actions.upsert,
    assignDocument: caseFileDocumentStore.actions.assignDocument,
    loadDocuments: loadCaseFileDocumentState,
    signingEmail: caseFileDocumentStore.actions.sendSigningEmail,
    voidSigningEmail: caseFileDocumentStore.actions.voidSigningEmail,
    getDocumentData: caseFileDocumentStore.actions.getDocumentDataById
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  getUserById: userStore.selectors.getById(state),
  userRole: mapRoleToProps(state).roleName,
  caseFileDocuments: caseFileDocumentStore.selectors.getAsArray(state),
  getCaseFileDocumentById: caseFileDocumentStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(CaseFileDocumentsGrid);
