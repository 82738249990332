import React from 'react';
import {RoutePaths} from '../../../router/RoutePaths';
import {AppTheme, getBlackLogoLink, getWhiteLogoLink} from '../../../appTheme';
import {userStore} from '../../../redux/web/entities/user';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../redux/core/types/WebState';
import {connect} from 'react-redux';
import {roleStore} from '../../../redux/web/entities/role';
import {Sidebar} from 'react-pro-sidebar';
import styles from '../PageHeader/PageHeader.module.scss';
import {GetMenuItemsForRole} from './MenuItemsForRole';

type Props = & ReturnType<typeof mapStateToProps>;


const SidebarPanel = (props: Props) => {
  const {currentUser} = props;
  return (
    <Sidebar customBreakPoint='1024px' backgroundColor={AppTheme.colors.primary} color={'#ffffff'}>
      <a href={RoutePaths.dashboard} style={{display: 'flex', margin: '10px'}}>
        <img src={getBlackLogoLink()} alt='BTC Portal' className={styles['logo']}/>
      </a>
      {GetMenuItemsForRole(currentUser)}
    </Sidebar>
  );

};

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({}, dispatch)});
const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  roleName: roleStore.selectors.getById(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarPanel);
