import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';
import styles from './UserModal.module.scss';
import {Form, Formik, FormikErrors} from 'formik';
import {Alert, Button, Form as BSForm, Modal, Row, Spinner} from 'react-bootstrap';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {WebState} from '../../../../../redux/core/types/WebState';
import {UserModalSchema} from './UserModalSchema';
import {User, userStore} from '../../../../../redux/web/entities/user';
import {DropdownInput} from '../../../../../components/util/form-components/formik-inputs/DropdownInput/DropdownInput';
import {Role, roleStore} from '../../../../../redux/web/entities/role';
import {convertToDropDownOptions} from '../../../../../redux/util';
import {makeUserRegistration} from '../../../../../redux/web/factory';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../redux/util/http';
import {bindActionCreators, Dispatch} from 'redux';
import ProfilePicture from '../../../../../components/util/ProfilePicture/ProfilePicture';
import DatepickerInput
  from '../../../../../components/util/form-components/formik-inputs/DatepickerInput/DatepickerInput';
import PhoneInputField
  from '../../../../../components/util/form-components/formik-inputs/PhoneNumberInput/PhoneNumberInput';

export interface UserUpsert {
  id?: string;
  email: string;
  roleId: string;
  name: string;
  primaryPhone: string;
  secondaryPhone: string;
  address: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: string;
  birthDate: string;
  standardId: string;
}

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  editable: boolean;
  onTop?: boolean;
  existingUser?: User;
  role?: Role;
} & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function UserModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel, rolesOptions, existingUser, role, getRoleByName, actions: {upsertUser}} = props;
  const getFieldName = (name: keyof User) => name;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: UserUpsert,
    validate: (values: UserUpsert) => Promise<FormikErrors<UserUpsert>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    setIsSubmitting(true);
    setErrorMessage('');
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setIsSubmitting(false);
    } else {
      try {
        await upsertUser(association);
        onSubmit();
      } catch (e: AxiosError | any) {
        setErrorMessage(getErrorResponseMessage(e));
      }
    }
    setIsSubmitting(false);
  };

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']}>
            {editable ? 'Cancel' : 'Close'}
          </Button>
        }
        {!isSubmitting ? editable && <Button variant={'success'} type='submit'>Submit</Button> : null}
      </>
    );
  };


  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Row>
          <ProfilePicture userId={existingUser?.id ?? ''}/>
        </Row>
        <Formik
          initialValues={existingUser ? existingUser as UserUpsert : makeUserRegistration(role)}
          validationSchema={UserModalSchema}
          onSubmit={() => undefined}
        >
          {({values, validateForm, handleSubmit}) => {
            return (
              <Form noValidate={false} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>{values.id ? 'Update User' : `Add ${role?.roleName ? `${role.roleName} ` : ''}User`}</Modal.Title>
                <InputRow label={'Email'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('email')}/>
                </InputRow>
                <InputRow label={'Name'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                  <Input name={getFieldName('name')}/>
                </InputRow>
                <InputRow label={'Primary Phone'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                  <PhoneInputField name={getFieldName('primaryPhone')}/>
                </InputRow>
                <InputRow label={'Secondary Phone'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                  <PhoneInputField name={getFieldName('secondaryPhone')}/>
                </InputRow>
                <InputRow label={'Address'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('address')}/>
                </InputRow>
                <InputRow label={'Address Line Two'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('addressLineTwo')}/>
                </InputRow>
                <InputRow label={'City'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('city')}/>
                </InputRow>
                <InputRow label={'State'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('state')}/>
                </InputRow>
                <InputRow label={'Zip Code'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('zip')}/>
                </InputRow>
                <InputRow label={'DOB'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                  <DatepickerInput
                    name={getFieldName('birthDate')}
                    showYearDropdown={true}
                    onChangeUtcHour={12}
                  />
                </InputRow>
                { !role ?
                  <InputRow label={'Role'} columnSize={4} labelSize={3} style={{paddingTop: '10px'}} requiredAsterisk={true}>
                    <DropdownInput dropdownData={rolesOptions}  name={getFieldName('roleId')} />
                  </InputRow> : null}
                {values.roleId === getRoleByName('Participant')?.id ?
                  <InputRow label={'Participant ID'} columnSize={5} labelSize={3} style={{paddingTop: '10px'}}>
                    <Input name={getFieldName('standardId')}/>
                  </InputRow> : null}
                {errorMessage !== '' ?
                  <div style={{marginTop: '1rem'}}>
                    <Alert variant='danger'>{errorMessage}</Alert>
                  </div>
                  : null}
                <Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    {renderButtons()}
                  </BSForm.Group>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertUser: userStore.actions.upsert
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  getRoleByName: roleStore.selectors.getRoleByName(state),
  rolesOptions: convertToDropDownOptions(roleStore.selectors.getAsArray(state), 'roleName')
});
export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
