import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faPlusCircle,
  faSearch,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faEye,
  faTrashAlt,
  faEdit,
  faLongArrowAltLeft,
  faArrowLeft,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faTable,
  faSignOutAlt,
  faFileUpload,
  faCheck,
  faBars,
  faMinusSquare,
  faTimesCircle,
  faCopy,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSpinner, faUserPlus, faUserCog, faArchive, faUserMinus, faUsers,
  faMinus, faPlus, faArrowUp, faArrowDown, faBox, faBoxOpen, faPrint, faPen, faAngleLeft, faTimes, faPhoneAlt, faCircle,
  faVideoCamera, faCalendarDays, faUtensils, faChalkboardUser, faChalkboardTeacher, faUser, faUserEdit, faHouseUser,
  faUserLock, faQuestionCircle, faFile, faFileLines, faDownload, faArrowRightLong, faArrowLeftLong, faTasks, faCancel,
  faChartLine
} from '@fortawesome/free-solid-svg-icons';

export function configureFontAwesome(): void {
  library.add(
    faPlusCircle,
    faSearch,
    faCaretDown,
    faCaretRight,
    faEye,
    faTrashAlt,
    faEdit,
    faLongArrowAltLeft,
    faArrowLeft,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faTable,
    faSignOutAlt,
    faFileUpload,
    faCheck,
    faBars,
    faMinusSquare,
    faTimesCircle,
    faCopy,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faSpinner,
    faUserPlus,
    faUserMinus,
    faUserCog,
    faArchive,
    faUsers,
    faMinus,
    faPlus,
    faArrowUp,
    faArrowDown,
    faBox,
    faBoxOpen,
    faPrint,
    faPen,
    faAngleLeft,
    faTimes,
    faPhoneAlt,
    faCircle,
    faVideoCamera,
    faCalendarDays,
    faUtensils,
    faDownload,
    faChalkboardUser,
    faChalkboardTeacher,
    faUser,
    faUserEdit,
    faHouseUser,
    faUserLock,
    faQuestionCircle,
    faFile,
    faFileLines,
    faArrowRightLong,
    faArrowLeftLong,
    faTasks,
    faCaretLeft,
    faCaretRight,
    faCancel,
    faChartLine
  );
}
