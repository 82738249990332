import React, {CSSProperties} from 'react';
import {ArrayHelpers, FieldArray, FieldArrayRenderProps} from 'formik';
import {Col, Row} from 'react-bootstrap';
import IconButton from '../../../widgets/IconButton/IconButton';
import {DeletableListRow} from '../../../lists/ListTable/ListTableRow';
import {ListTable} from '../../../lists/ListTable/ListTable';

// Probably more generics than needed here lol
interface Props<T, Name extends string> {
  name: Name;
  values: T[];
  factoryFn: () => T;
  renderRow: (item: T, rowName: `${Name}[${number}]`, getItemName: (key: keyof T) => keyof T, disabled?: boolean) => JSX.Element;
  noItemsLabel: string;
  renderFooter?: () => JSX.Element;
  headerLabel?: string | JSX.Element;
  disabled?: boolean;
  tableStyle?: CSSProperties;
  rowWrapperStyle?: CSSProperties;
}

export function DeletableFieldArray<T, Name extends string>(props: Props<T, Name>) {
  const {disabled, name, values, renderRow, renderFooter, factoryFn, noItemsLabel, headerLabel, rowWrapperStyle, tableStyle} = props;
  const generateRowName = (index: number): `${Name}[${number}]` => `${name}[${index}]`;
  const getItemFieldName = (keyName: keyof T) => keyName;

  const renderHeader = (helpers: ArrayHelpers) => (
    <Col style={{width: '100%', marginBottom: '10px'}}>
      <Row>
        {headerLabel}
        <div style={{marginLeft: '0.5rem', marginTop: '1px', display: 'flex', flex: '1', textAlign: 'right'}}>
          {!disabled ?
            <IconButton
              icon={'plus'}
              styles={{width: '100%', textAlign: 'right'}}
              size={'lg'}
              onClick={() => helpers.push(factoryFn())}
            /> : null}
        </div>
      </Row>
    </Col>
  );

  return (
    <FieldArray
      name={name}
      render={(helpers: FieldArrayRenderProps) => (
        <ListTable
          items={values}
          style={tableStyle}
          renderHeader={() => renderHeader(helpers)}
          renderItem={(item: T, index: number) => (
            <DeletableListRow
              iconSize={'2x'}
              key={generateRowName(index)}
              deleteColumnSize={1}
              style={{flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'visible', ...rowWrapperStyle}}
              render={() => renderRow(item, generateRowName(index), (key) => getItemFieldName(key), disabled)}
              editable={!disabled}
              onDelete={() => helpers.remove(index)}
            />
          )}
          noItemsLabel={noItemsLabel}
          renderFooter={renderFooter}
        />
      )}
    />
  );
}

// Example row
/*
interface RowProps {
  item: itemType;
  rowName: string;
  disabled: boolean;
}

renderRow={(item, rowName, disabled, getItemFieldName) => (
  <Row>
    <InputColumn label={'Name'} columnSize={6} className='pl-2'>
      <Input name={`${rowName}.${getItemFieldName('name')`} disabled={disabled}/>
    </InputColumn>
  </Row>
);
*/
