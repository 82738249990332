import {Col} from 'react-bootstrap';
import {ColProps} from 'react-bootstrap/Col';
import React from 'react';

export default function UnpaddedCol(props: ColProps) {
  return (
    <Col style={{padding: 0}}>
      {props.children}
    </Col>
  );
}
