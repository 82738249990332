import React from 'react';
import {Row} from 'react-bootstrap';
import {
  SecondaryIntake as SI,
  SecondaryIntakeSubstanceAbuseAndTraffickingInformation as SISubstanceTrafficking
} from '../../../../../../../redux/web/entities/forms/intake/secondaryIntake/secondaryIntake';
import {useFormikContext} from 'formik';
import Input from '../../../../../../../components/util/form-components/formik-inputs/Input/Input';
import {
  StandardCheckboxInput,
  StandardInputColumn,
  StandardParentInputRow,
  StandardRadioInput,
  StandardTopPaddedRow
} from '../../../../../../../components/util/form-components/standardLayout';

// TODO add ethnicity
export const SubstanceAbuseAndTraffickingInformationForm = () => {
  const {values, setFieldValue} = useFormikContext<SI>();
  const formValues = values.substanceAbuseAndTraffickingInformation;
  // return type not really needed, but it's cool you can do this
  const getFieldName = (name: keyof SISubstanceTrafficking): `${keyof SI}.${keyof SISubstanceTrafficking}` =>
    `substanceAbuseAndTraffickingInformation.${name}`;
  return (
    <>
      <StandardParentInputRow label={'Substance Abuse Issues'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'How often did you use drugs or alcohol?'} columnSize={12}>
            <Input name={getFieldName('drugsAndAlcoholFrequency')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever blacked out or had a seizure while under the influence?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('blackoutOrSeizure')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, what were you using at the time?'} columnSize={8}>
            <Input
              name={getFieldName('blackoutOrSeizureSubstance')}
              type={'textarea'}
              disabled={!formValues.blackoutOrSeizure}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Please describe what happened:'} columnSize={12}>
            <Input
              name={getFieldName('blackoutOrSeizureDescription')}
              type={'textarea'}
              disabled={!formValues.blackoutOrSeizure}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Did your parents use drugs?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('parentsUsedDrugs')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, what:'} columnSize={8}>
            <Input name={getFieldName('parentsUsedDrugsDescription')}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Did you ever use substances during acts of prostitution?'} columnSize={6}>
            <StandardCheckboxInput name={getFieldName('usedSubstancesDuringProstitution')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Have you ever received any type of substance abuse treatment?'} columnSize={6}>
            <StandardCheckboxInput name={getFieldName('receivedSubstanceAbuseTreatment')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If yes, please describe:'}>
            <Input
              name={getFieldName('receivedSubstanceAbuseTreatmentDescription')}
              type={'textarea'}
              disabled={!formValues.receivedSubstanceAbuseTreatment}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
        <StandardInputColumn label={'Did you use substances prior to the sex trafficking?'} columnSize={4}>
          <StandardCheckboxInput name={getFieldName('usedSubstancesPriorToSexTrafficking')} label={'Yes'}/>
        </StandardInputColumn>
          <StandardInputColumn label={'How old the first time?'} columnSize={8}>
            <Input
              name={getFieldName('ageOfFirstSubstanceUse')}
              disabled={!formValues.usedSubstancesPriorToSexTrafficking}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
      <StandardParentInputRow label={'Human Trafficking'}>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever engaged in the sex / labor trade?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasEngagedInSexOrLaborTrade')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'How old the first time?'} columnSize={8}>
            <Input
              name={getFieldName('ageOfFirstTrafficking')}
              disabled={!formValues.hasEngagedInSexOrLaborTrade}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Were you in a relationship at the time?'} columnSize={4}>
            <StandardCheckboxInput
              name={getFieldName('wasInRelationshipDuringTrafficking')}
              label={'Yes'}
              disabled={!formValues.hasEngagedInSexOrLaborTrade}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'Have you ever been arrested?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasBeenArrested')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Have you ever had a trafficker, "pimp?"'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hadTrafficker')} label={'Yes'}/>
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Were you ever in foster care?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('wasInFosterCare')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'If yes, how long?'} columnSize={8}>
            <Input
              name={getFieldName('wasInFosterCaseLength')}
              disabled={!formValues.wasInFosterCare}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'Have you ever had police contact??'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasHadPoliceContact')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Did you ever runaway as a child?'} columnSize={4}>
            <StandardCheckboxInput name={getFieldName('hasRunawayAsChild')} label={'Yes'}/>
          </StandardInputColumn>
          <StandardInputColumn label={'Have you ever felt your freedom was in jeopardy?'} columnSize={4}>
            <StandardCheckboxInput
              name={getFieldName('freedomWasInJeopardy')}
              label={'Yes'}
              onChange={(val: boolean) => {
                setFieldValue(getFieldName('freedomWasInJeopardy'), val);
                if (!val) {
                  setFieldValue(getFieldName('freedomWasInJeopardyDueToLaborTrafficking'), val);
                  setFieldValue(getFieldName('freedomWasInJeopardyDueToSexTrafficking'), val);
                }
              }}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If your freedom was in jeopardy, was it due to:'} columnSize={4}>
            <Row>
              <StandardCheckboxInput
                name={getFieldName('freedomWasInJeopardyDueToSexTrafficking')}
                label={'Sex trafficking'}
                disabled={!formValues.freedomWasInJeopardy}
              />
              <div style={{paddingLeft: '1rem', paddingRight: '1rem'}}>or</div>
              <StandardCheckboxInput
                name={getFieldName('freedomWasInJeopardyDueToLaborTrafficking')}
                label={'Labor trafficking'}
                disabled={!formValues.freedomWasInJeopardy}
              />
            </Row>
          </StandardInputColumn>
          <StandardInputColumn label={'If neither, please explain:'} columnSize={8}>
            <Input
              name={getFieldName('freedomWasInJeopardyOtherDescription')}
              type={'textarea'}
              disabled={!formValues.freedomWasInJeopardy ||
                formValues.freedomWasInJeopardyDueToSexTrafficking || formValues.freedomWasInJeopardyDueToLaborTrafficking}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
        <StandardTopPaddedRow>
          <StandardInputColumn label={'If your freedom was in jeopardy, how old were you?:'} columnSize={4}>
            <Input
              name={getFieldName('freedomWasInJeopardySexOrLaborAge')}
              disabled={!formValues.freedomWasInJeopardy}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
          <StandardInputColumn label={'Please describe what happened:'} columnSize={8}>
            <Input
              name={getFieldName('freedomWasInJeopardySexOrLaborDescription')}
              type={'textarea'}
              disabled={!(formValues.freedomWasInJeopardy)}
              valueOnDisabled={'N/A'}
            />
          </StandardInputColumn>
        </StandardTopPaddedRow>
      </StandardParentInputRow>
    </>
  );
};
