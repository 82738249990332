import {api} from './index';
import {BtcDocument} from '../redux/web/entities/btcDocument';
import {Dictionary} from '../redux/util';
import {BtcHistoryEvent} from '../types/BtcHistoryEvent';

export async function getDocumentManagementState() {
  return (await api.get<Dictionary<BtcDocument>>('/document/management')).data;
}

export async function getDocumentDataByid(id: string) {
  return (await api.get<BtcDocument>(`/document/${id}`)).data;
}

export async function upsertDocument(document: BtcDocument) {
  return (await api.post<BtcDocument>('/document', document)).data;
}

export async function getDocumentHistoryById(id: string) {
  return (await api.get<BtcHistoryEvent[] | null>(`/document/history/${id}`)).data;
}

export async function deleteDocument(document: BtcDocument) {
  return (await api.delete<string>(`/document/${document.id}`)).data;
}
