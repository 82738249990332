import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import styles from './ParticipantGrid.module.scss';
import gridStyles from '../../../GridStyles.module.scss';
import {bindActionCreators, Dispatch} from 'redux';
import {Button, Col, Row} from 'react-bootstrap';
import BootstrapTable, {BootstrapTableProps, ColumnDescription, SearchProps} from 'react-bootstrap-table-next';
// @ts-ignore
import ToolkitProvider, {ColumnToggle, Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import IconButton, {
  LabeledIconButton,
  LabeledIconButtonProps
} from '../../../../components/util/widgets/IconButton/IconButton';
import {User, userStore} from '../../../../redux/web/entities/user';
import {WebState} from '../../../../redux/core/types/WebState';
import {ToggleListProps} from 'react-bootstrap-table2-toolkit';
import {Role, roleStore} from '../../../../redux/web/entities/role';
import {Navigate, useSearchParams} from 'react-router-dom';
import {makeUser} from '../../../../redux/web/factory';
import {RoutePaths} from '../../../../router/RoutePaths';
import {format, utcToZonedTime} from 'date-fns-tz';
import {parseISO} from 'date-fns';
import {localTz, localTzFriendlyFormat} from '../../../../util';
import {noProfilePicture} from '../../../../appTheme';
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'react-phone-number-input';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function StyledLabeledIconButton (buttonProps: LabeledIconButtonProps) {
  return (
    <Col style={{justifyContent: 'center', alignItems: 'center', wordBreak: 'initial'}}>
      <LabeledIconButton
        {...buttonProps}
        size={'3x'}
        labelStyle={{marginBottom: '10px', flexWrap: 'nowrap', whiteSpace: 'nowrap', ...buttonProps.labelStyle}}
      />
    </Col>
  );
}

function ParticipantGrid(props: Props) {
  const {archivedUsers, nonArchivedUsers} = props;
  const [viewingArchived, setViewingArchived] = useState(false);
  const [showColumnToggleFilters, setShowColumnToggleFilters] = useState(false);
  const {SearchBar} = Search;
  const [redirectUrl, setRedirectUrl] = useState('');
  const [pfpSize, setPfpSize] = useState(2);
  const increasePfpSize = useCallback(() => setPfpSize((prev) => prev + 1), []);
  const decreasePfpSize = () => setPfpSize((prev) => prev - 1);

  const getFieldName = (name: keyof User) => name;

  const renderRedirect = () => {
    if (redirectUrl.length !== 0) {
      return <Navigate to={redirectUrl} />;
    }
    return null;
  };

  const setViewingArchivedAndColumnView = (state: boolean) => {
    const updatedColumns = columns.map((value) => {
      if (value.dataField === getFieldName('archivedAt'))
        return {...value, hidden: !state};
      return value;
    });
    setColumns(() => updatedColumns);
    setViewingArchived(state);
  };

  const onColumnToggle = (col: ColumnDescription<any>) => {
    const updatedTableColumns = columns.map((value) => value.dataField === col.dataField ? {...value, hidden: !col.hidden} : value);
    const visibleRecordInfo = updatedTableColumns.filter((value) => (!value.hidden || false) && !col.isDummyField).length > 1;
    setColumns(visibleRecordInfo ? updatedTableColumns : columns);
  };

  const FilteredToggleList = ({toggles}: {toggles: boolean[]}) => (
    <div className={gridStyles['filters-button-group']}>
      {
        columns
          .filter(col => !(col.isDummyField || col.dataField === (getFieldName('id')) ||
            (!viewingArchived && col.dataField === getFieldName('archivedAt'))))
          .map(column => ({...column, toggle: toggles[column.dataField]}))
          .map(column => (
            <Button
              variant={column.toggle ? 'success' : 'secondary'}
              key={ column.dataField }
              onClick={ () => onColumnToggle(column) }
            >
              {
                column.dataField === getFieldName('archivedAt') ? 'Archived At' :
                  column.dataField === getFieldName('createdAt') ? 'Created On' :
                    column.dataField === getFieldName('profilePicturePath') ? 'Picture' :
                      column.text }
            </Button>
          ))
      }
    </div>
  );

  const dateTimeFormatter = (isoDateTime: string) => {
    const formatDate = (isoDate: string) =>
      format(utcToZonedTime(parseISO(isoDate), localTz as any as string), 'MM/dd/yyyy hh:mm:ss a', {timeZone: localTz as any as string});
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        {`${formatDate(isoDateTime)}`}
      </div>
    );
  };


  const profilePictureFormatter = (cell: any, user: User) => {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        <img
          style={{height: `${pfpSize}rem`, width: `${pfpSize}rem`, borderRadius: '50%'}}
          src={user.profilePicturePath !== '' ?
            `${process.env.REACT_APP_STORAGE_URL}Storage/Public/ProfilePictures/${user.id}/${user.profilePicturePath}` : noProfilePicture()}
          alt={'Profile picture'}
        />
      </div>
    );
  };

  const phoneNumberFormatter = (phoneNumber: string) => {
    if (typeof phoneNumber !== 'string') return phoneNumber;
    if(phoneNumber === '+1') return '';
    if (isValidPhoneNumber(phoneNumber)) return formatPhoneNumber(phoneNumber);
    if (isPossiblePhoneNumber(phoneNumber)) return formatPhoneNumberIntl(phoneNumber);
    return phoneNumber;
  };


  const nameFormatter = (cell: any, user: User) => {
    return (
      <div
        style={{display: 'flex', justifyContent: 'flex-start', textDecoration: 'underline', cursor: 'pointer'}}
        onClick={() => setRedirectUrl(RoutePaths.caseFilePaths.CaseFile.replace(':id', user.id))}
      >
        {user.name}
      </div>
    );
  };

  const actionsFormatter = (cell: any, user: User) => {
    return (
      <div className={gridStyles['table-actions-wrapper']}>
        <div>
          <IconButton
          icon='file'
          size={'2x'}
          styles={{marginLeft: '.5rem'}}
          iconToolTipText={'View'}
          onClick={() => {
            setRedirectUrl(RoutePaths.caseFilePaths.CaseFile.replace(':id', user.id));
          }}
          color={'#005A9C'}
          />
        </div>
      </div>
    );
  };

  const [columns, setColumns] = useState<ColumnDescription<User>[]>([
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      searchable: false,
      hidden: true
    },
    {
      dataField: 'profilePicturePath',
      text: '',
      sort: false,
      searchable: false,
      formatter: profilePictureFormatter,
      style: {width: '1px'}
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: nameFormatter
    },
    {
      dataField: '',
      text: 'Searchable Name',
      isDummyField: true,
      hidden: true,
      formatter: (cell, user) => user.name
    },
    {
      dataField: 'standardId',
      text: 'Participant ID',
      sort: true
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true
    },
    {
      dataField: 'primaryPhone',
      text: 'Primary Phone',
      formatter: (cell, user) => phoneNumberFormatter(user.primaryPhone)
    },
    {
      dataField: 'secondaryPhone',
      text: 'Secondary Phone',
      hidden: true,
      formatter: (cell, user) => phoneNumberFormatter(user.secondaryPhone)
    },
    {
      dataField: 'address',
      text: 'Address',
      formatter: (cell: any, user: User) => user.address || user.addressLineTwo ? `${user.address}${user.addressLineTwo ? ` ${user.addressLineTwo}` : ''}` : '',
      hidden: true
    },
    {
      dataField: 'state',
      text: 'City & State',
      sort: true,
      formatter: (cell: any, user: User) => user.city || user.state ? `${user.city ? `${user.city}, ` : ''}${user.state}` : '',
      hidden: true
    },
    {
      dataField: 'zip',
      text: 'ZIP',
      sort: true,
      hidden: true
    },
    {
      dataField: 'role.roleName',
      formatter: (cell: any, user: User) => (user.role.roleName),
      searchable: false,
      text: 'Role',
      sort: true
    },
    {
      dataField: 'birthDate',
      text: 'DOB',
      formatter: (cell: any, user: User) => user.birthDate ? format(parseISO(user.birthDate), 'MM/dd/yyyy') : '',
      sort: true
    },
    {
      dataField: 'createdAt',
      hidden: viewingArchived,
      text: `Created On (${localTzFriendlyFormat})`,
      formatter: (cell: any, user: User) => dateTimeFormatter(user.createdAt)
    },
    {
      dataField: 'archivedAt',
      hidden: !viewingArchived,
      text: `Deactivated On (${localTzFriendlyFormat})`,
      formatter: (cell: any, user: User) => user.archivedAt ? dateTimeFormatter(user.archivedAt) : ''
    },
    {
      dataField: 'actionsColumn',
      text: '',
      isDummyField: true,
      searchable: false,
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '3rem'};
      },
      style: () => {
        return { width: '3rem'};
      }
    }
  ]);

  return (
    <ToolkitProvider
      keyField='id'
      data={viewingArchived ? archivedUsers : nonArchivedUsers}
      columns={columns}
      search={{
        searchFormatted: true
      }}
      columnToggle={true}
    >
      {(tableProps:
          { baseProps: JSX.IntrinsicAttributes & JSX.IntrinsicClassAttributes<BootstrapTable<User, number>> & Readonly<BootstrapTableProps>;
            searchProps: SearchProps<User>;
            columnToggleProps: ToggleListProps;}) => (
        <>
          <div className={gridStyles['button-groups-container']}>
            <div className={gridStyles['button-group']}>
              <div>
                <Button
                  onClick={() => setViewingArchivedAndColumnView(!viewingArchived)}
                  style={{backgroundColor: '#005A9C'}}
                >
                  {viewingArchived ? 'View Active Participants' : 'View Archived Participants'}
                </Button>
              </div>
              <Button style={{backgroundColor: '#005A9C'}} onClick={() => setShowColumnToggleFilters(!showColumnToggleFilters)}>
                {showColumnToggleFilters ? 'Hide' : 'Show'} Column Filters
              </Button>
            </div>
            {showColumnToggleFilters ?
              <div className={gridStyles['button-group']}>
                <FilteredToggleList {...tableProps.columnToggleProps}/>
              </div>
              : null
            }
          </div>
          {renderRedirect()}
          <SearchBar {...tableProps.searchProps}/>
          <BootstrapTable
            wrapperClasses={gridStyles['responsive-table-wrapper']}
            classes={gridStyles['table-auto']}
            rowStyle={{height: '100%'}}
            {...tableProps.baseProps}
          />
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  nonArchivedUsers: userStore.selectors.getParticipants(state),
  archivedUsers: userStore.selectors.getArchivedParticipants(state),
  getUserById: userStore.selectors.getById(state),
  getRoleByName: roleStore.selectors.getRoleByName(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(ParticipantGrid);
