import React, {useState} from 'react';
import {Alert, Button, Col, Form as BSForm, Modal, Row, Spinner, Tab, Tabs} from 'react-bootstrap';
import DashboardTaskFieldArray from './DashboardTaskFieldArray';
import {DashboardTask, ParticipantDashboardState, StaffDashboardState} from '../../../../../types/DashboardTypes';
import {Form, Formik} from 'formik';
import {AxiosError} from 'axios';
import {getErrorResponseMessage} from '../../../../../redux/util/http';
import styles from './DashboardTaskModal.module.scss';
import {WebState} from '../../../../../redux/core/types/WebState';
import {userStore} from '../../../../../redux/web/entities/user';
import {connect} from 'react-redux';
import {makeDashboardTask} from '../../../../../redux/web/factory';
import {assignDashboardTask} from '../../../../../api/dashboardApi';
import {
  StandardInputColumn,
  StandardParentInputRow,
  StandardTopPaddedRow
} from '../../../../../components/util/form-components/standardLayout';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {propertyOf} from '../../../../../redux/util/object';
import {convertObjectToOption, convertToDropDownOptions} from '../../../../../redux/util';
import Select from 'react-select';
import InformationLabel from "../../../../../components/util/form-components/InformationLabel/InformationLabel";
import DashboardTasksAssignedToUsersGrid from "./DashboardTasksAssignedToUsersGrid/DashboardTasksAssignedToUsersGrid";
import {SetStateFunctional} from "../../../../../util";

type Props = {
  myTasks: DashboardTask[];
  tasksAssignedToOtherUsers: DashboardTask[];
  onSubmit: (data?: DashboardTask[]) => void;
  onCancel: () => void;
  setDashboardState?: SetStateFunctional<StaffDashboardState> | SetStateFunctional<ParticipantDashboardState>;
} & ReturnType<typeof mapStateToProps>;

const DashboardTaskModal = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const renderButtons = () => {
    return (
      <>
        {isSubmitting ?
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          :
          <Button onClick={props.onCancel} variant={'danger'} className={styles['close-button']}>
            {'Cancel'}
          </Button>
        }
        {!isSubmitting ? <Button variant={'success'} type={'submit'}>Submit</Button> : null}
      </>
    );
  };

  const renderPersonalTasksForm = () => (
    <Formik<{dashboardTasks: DashboardTask[]}> initialValues={{dashboardTasks: props.myTasks}} key={'personal'} onSubmit={() => undefined}>
      {({values}) => {
        return (
          <Form
            key={'personal'}
            noValidate={false}
            onSubmit={async (e) => {
              setIsSubmitting(true);
              e.persist();
              e.preventDefault();
              try {
                await props.onSubmit(values.dashboardTasks);
              } catch (e: AxiosError | any) {
                setErrorMessage(getErrorResponseMessage(e));
              }
              setIsSubmitting(false);
            }}
          >
            <DashboardTaskFieldArray/>
            {errorMessage !== '' ?
              <div style={{marginTop: '1rem'}}>
                <Alert variant='danger'>{errorMessage}</Alert>
              </div>
              : null}
            <Row style={{paddingTop: '10px'}}>
              <BSForm.Group style={{marginLeft: 'auto', marginRight: '10px', borderRadius: '10px', minWidth: '80px'}}>
                {renderButtons()}
              </BSForm.Group>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );

  const renderAssignTaskForm = () => (
    <Formik<DashboardTask> initialValues={makeDashboardTask()} key={'assign'} onSubmit={() => undefined}>
      {({values, setFieldValue}) => {
        return (
          <Form
            key={'assign'}
            noValidate={false}
            onSubmit={async (e) => {
              setIsSubmitting(true);
              e.persist();
              e.preventDefault();
              try {
                const response = await assignDashboardTask(values);
                props.setDashboardState && props.setDashboardState((prev: any) => ({...prev, tasks: response}));
                await props.onSubmit();
              } catch (e: AxiosError | any) {
                setErrorMessage(getErrorResponseMessage(e));
              }
              setIsSubmitting(false);
            }}
          >
            <StandardParentInputRow label={'Assign a Task'} style={{marginTop: '10px'}}>
              <StandardTopPaddedRow>
                <StandardInputColumn label={'Name'} columnSize={3}>
                  <Input name={propertyOf<DashboardTask>('name')}/>
                </StandardInputColumn>
                <StandardInputColumn label={'Description'} columnSize={6}>
                  <Input name={propertyOf<DashboardTask>('description')} type={'textarea'} rows={1}/>
                </StandardInputColumn>
                <StandardInputColumn label={'User'} columnSize={3}>
                  <Select
                    name={propertyOf<DashboardTask>('userId')}
                    options={convertToDropDownOptions(props.users.filter(u => u.id !== props.currentUser?.id))}
                    defaultValue={convertObjectToOption({value: '', label: ''})}
                    onChange={(option) => setFieldValue(propertyOf<DashboardTask>('userId'), option?.value ?? '')}
                    isClearable={true}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 3000, overflowY: 'visible' }) }}
                    menuPortalTarget={document.querySelector('body')}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  />
                </StandardInputColumn>
              </StandardTopPaddedRow>
              {errorMessage !== '' ?
                <div style={{marginTop: '1rem'}}>
                  <Alert variant='danger'>{errorMessage}</Alert>
                </div>
                : null}
              <Row style={{paddingTop: '10px'}}>
                <BSForm.Group style={{marginLeft: 'auto', marginRight: '10px', borderRadius: '10px', minWidth: '80px'}}>
                  {renderButtons()}
                </BSForm.Group>
              </Row>
            </StandardParentInputRow>
          </Form>
        );
      }}
    </Formik>
  );

  return (
    <Modal show={true} centered={true} size={'lg'} style={{maxHeight: '98vh'}}>
      <Modal.Body>
        <Modal.Title>Edit Tasks</Modal.Title>
        <Col style={{marginTop: '10px'}}>
          {props.isAdministrator ?
            <Tabs defaultActiveKey={'personal'}>
              <Tab eventKey={'personal'} title={'Personal'} key={'personal'}>
                {renderPersonalTasksForm()}
              </Tab>
              <Tab eventKey={'assign'} title={'Assign'} key={'assign'}>
                {renderAssignTaskForm()}
                <StandardTopPaddedRow style={{display: 'flex', overflow: 'auto'}}>
                  <StandardParentInputRow label={'Assigned Task Completion Status'} style={{overflow: 'auto'}}>
                    <DashboardTasksAssignedToUsersGrid
                      tasksAssignedToUsers={props.tasksAssignedToOtherUsers}
                    />
                  </StandardParentInputRow>
                </StandardTopPaddedRow>
              </Tab>
            </Tabs>
            : renderPersonalTasksForm()
          }
        </Col>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state: WebState) => ({
  currentUser: userStore.selectors.getCurrentUser(state),
  isAdministrator: userStore.selectors.isAdministrator(state),
  users: userStore.selectors.getAsArray(state),
  getUserById: userStore.selectors.getById(state)
});

export default connect(mapStateToProps)(DashboardTaskModal);
